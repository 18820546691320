export interface OperationAccessList {
  name: string;
  operationNo: string;
  allow: boolean;
  operationID: number;
  applicationID: number;
  orgLevelDisplay: string;
}

export interface CompanyAccessList {
  companyID: number;
  name: string;
  allow: boolean;
  operationAccessList: OperationAccessList[];
  applicationID: number;
}

export interface OrgAccess {
  companyAccessList: CompanyAccessList[];
}

export interface DataObject {
  authenticated: boolean;
  message: string;
  userID: number;
  userName: string;
  firstName: string;
  lastName: string;
  securityToken: string;
  passwordValid: boolean;
  functionList: string[];
  orgAccess: OrgAccess;
  active: boolean;
}

export interface AuthClaims {
  success: boolean;
  message: string;
  dataObject: DataObject;
  sessionError: boolean;
}

export interface AuthToken{
  jwtToken: string;
  jwtRefreshToken: string;
  oneFoundationData: AuthClaims;
}

export class UnAuthorizationReason {
  static readonly ApplicationAccess = "applicationaccess";
  static readonly CompanyAccess = "companyaccess";
  static readonly FunctionPermission = "functionpermission";
  static readonly InvalidOmsUser = "invalidomsuser";
  static readonly OperationAccess = "operationaccess";
  static readonly ServiceError = "serviceerror";
  static readonly UserInactive = "userinactive";
}
