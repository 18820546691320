import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../core/common-service/common.service';
import { SecurityService } from '../core/security/security.service';
import { AppsettingsProvider } from '../core/appsettings.provider';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SmartServiceService } from '../smart-service/smart-service-service/smart-service.service';
import { ISubscription } from 'rxjs/Subscription';
import { SmartServiceStatusListItem } from '../smart-service/smart-service-service/smart-service.model';
import * as moment from 'moment';
import { Router, NavigationEnd } from '@angular/router';
import { RoleGuardService } from '../core/role-guard/role-guard.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  securityService = null;
  isIEOrEdge = true;
  appSettings: any;
  operationGuideURL: string;
  guide: string;
  navigationSubscription: ISubscription;

  canViewDashboard = false;
  hasLoaded = false;
  isSeedData = false;
  dashboardData: any[];
  errorData: any[];

  // pie chart options
  dimensions = [800, 500];
  colorScheme = {
    domain: ['#4286f4', '#C7B42C', '#c98e36', '#5AA454', '#A10A28']
  };

  constructor(
    private _securityService: SecurityService,
    private _common: CommonService,
    private _http: HttpClient,
    private _deviceService: DeviceDetectorService,
    private _appSettings: AppsettingsProvider,
    private _smart: SmartServiceService,
    private _router: Router,
    private _roleGuard: RoleGuardService) {
    this.securityService = _securityService;
    this._common.setCurrentPage('Home');
    this._common.operationGuideSubject.next('Operations Guide - One Vend - Application Design and Features.pdf');
  }

  ngOnInit() {
    this.appSettings = this._appSettings.getSettings();
    this.operationGuideURL = this.appSettings.urlLocations.operationGuidesURL;
    this.guide = 'Release Notes - OneVend.docx';

    this.isIEOrEdge = this._deviceService.browser === 'ie';

    this.getDashboardData();

    this.navigationSubscription = this._router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        if (e.url.toLowerCase() === '/home') {
          this.getDashboardData();
        }
      }
    });
  }

  ngOnDestroy() {
    this.navigationSubscription.unsubscribe();
  }

  onSelect(event) {
    console.log(event);
    const date = this._common.convertDateToString(moment().add(1, 'days').toDate());
    const operationID = Number(this._common.operation.operationID);
    this._router.navigate(['/smartservice/route-status/' + date + '/' + operationID]);
  }

  errorTotal() {
    let total = 0;
    this.errorData.forEach((error) => {
      total += error.value;
    });
    return total;
  }

  getDashboardData() {
    this.canViewDashboard = this._common.operation.isOnSeed;
    console.log('Can view dashboard: ' + this.canViewDashboard);
    if (this.canViewDashboard === true) {
      console.log(this._common.convertDateToString(moment().add(1, 'days').toDate()));
      const date = this._common.convertDateToString(moment().add(1, 'days').toDate());
      const operation = Number(this._common.operation.operationNo);
      this._smart.GetDashboard(date, operation).subscribe((data: SmartServiceDashboard) => {
        console.log(data);
        this.dashboardData = [{
          'name': 'Vending Orders Created - ' + data.ordersCreated,
          'value': data.ordersCreated
        }, {
          'name': 'Orders Ready in OneWarehouse - ' + data.oW_ReleasedOrderCount,
          'value': data.oW_ReleasedOrderCount
        }, {
          'name': 'Picking in LightSpeed - ' + (data.oW_LS_PIPOrderCount + data.oW_FulfilledOrderCount),
          'value': data.oW_LS_PIPOrderCount + data.oW_FulfilledOrderCount
        }, {
          'name': 'Complete - ' + (data.oW_CompleteOrderCount + data.onRoute + data.deliveredOrderCount),
          'value': data.oW_CompleteOrderCount + data.onRoute + data.deliveredOrderCount
        }, {
          'name': 'Errors - ' + (data.seedErrors + data.owErrors),
          'value': data.seedErrors + data.owErrors
        }];
        this.errorData = [
          {
            'name': 'Seed Errors',
            'value': data.seedErrors
          }, {
            'name': 'OW Errors',
            'value': data.owErrors
          }
        ];
        this.dashboardData.forEach((d) => {
          if (d.value && d.value > 0) {
            this.isSeedData = true;
          }
        });
        this.hasLoaded = true;
      }, (error) => {
        this.canViewDashboard = false;
        console.log(error);
      });
    }
  }

}

export interface SmartServiceDashboard {
  businessDate: string;
  costCenterID: number;
  operationID: number;
  operationName: string;
  seedOrders: number;
  ordersCreated: number;
  oW_ReleasedOrderCount: number;
  oW_LS_PIPOrderCount: number;
  oW_FulfilledOrderCount: number;
  oW_CompleteOrderCount: number;
  onRoute: number;
  deliveredOrderCount: number;
  orderComplete: number;
  owErrors: number;
  seedErrors: number;
}
