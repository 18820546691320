import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ISubscription } from 'rxjs/Subscription';

import { CommonService } from '../../../core/common-service/common.service';
import { SecurityService } from '../../../core/security/security.service';
import { ReportsService } from '../../reports-service/reports.service';
import { Report_Param_DateRange_Responses, Report_Param_DateRange_Response, Parameter } from '../../reports-service/reports.model';
import { Switch } from '../../../shared/switch/switch.model';
import * as moment from 'moment';

@Component({
  selector: 'app-rpt-date-range',
  templateUrl: './rpt-date-range.component.html',
  styleUrls: ['./rpt-date-range.component.scss']
})
export class RptDateRangeComponent implements OnInit {

  @Input()
  parameter: Parameter

  @Output()
  onError = new EventEmitter<boolean>();

  @Input()
  fromDate: Date;
  @Output()
  onFromDate = new EventEmitter<Date>();

  @Input()
  toDate: Date;
  @Output()
  onToDate = new EventEmitter<Date>();

  showFiscalWeeks: Switch = new Switch("Filter By Fiscal Date", true, false);

  error: boolean;
  fromError: string = "";
  toError: string = "";
  dayRange: number = 0;

  @Input()
  dateRanges: Report_Param_DateRange_Response[];

  opeationSubscription: ISubscription;

  constructor(private _common: CommonService,
    private _security: SecurityService,
    private _reports: ReportsService) { }

  ngOnInit() {
    // Parse validation(s) that are implemented
    let csv = this.parameter.validations.split(',')
    for (let index in csv) {
      let item = csv[index]
      if (item.match('DAYRANGE')) {
        let dayRangeValues=item.split(':')
        if (dayRangeValues.length==2) {
          this.dayRange = parseInt(dayRangeValues[1])
        }
      }
    }
  }

  selectFromDate(fiscalWeek: Report_Param_DateRange_Response) {
    this.update(new Date(fiscalWeek.weekStartDateString), true);
  }

  selectToDate(fiscalWeek: Report_Param_DateRange_Response) {
    this.update(new Date(fiscalWeek.weekEndDateString), false);
  }

  showFromError() {
    return this.fromError;
  }

  showToError() {
    return this.toError;
  }

  toggleDatePickers(element, other) {
    element.toggle();
    other.close();
  }

  update(date, isFrom: boolean) {
    this.fromError = "";
    this.toError = "";
    this.error = false;

    var result = moment(date); //If not valid date, will return NaN
    if (result.isValid()) 
    {
      //check that from date is not after to date
      if (isFrom) {
        if (moment(date).toDate() > moment(this.toDate).toDate()) {
          this.error = true;
          this.fromError = "From cannot be after To";
        }
      } else {
        if (moment(this.fromDate).toDate() > moment(date).toDate()) {
          this.error = true;
          this.toError = "From cannot be after To";
        }
      }

      // Emit the date that has just changed since it is valid date
      if (isFrom) {
        this.onFromDate.emit(date);
        this.fromDate = date;
      } else {
        this.onToDate.emit(date);
        this.toDate = date;
      }

      // No error yet - keep validating
      if (!this.error)
      {
        if (this.dayRange && this.dayRange>0)
        {
          let from = moment(this.fromDate)
          let to   = moment(this.toDate).add(1, 'days'); // Get to end of the 'to' date
    
          let duration = moment.duration(to.diff(from));
          let duration_in_days = duration.asDays();
          if (duration_in_days > this.dayRange) {
            this.error = true;
            this.fromError = `Days cannot be more than ${this.dayRange} days apart`;
          }
        }
      }
    }
    else
    {
      this.error = true;
      if (isFrom) this.fromError = "This field is required.";
      else        this.toError   = "This field is required.";
    }

    this.onError.emit(this.error);
  }
}
