import { Component, OnInit, Input, Output, OnChanges, OnDestroy } from '@angular/core';
import { EventEmitter } from 'events';
import { ISubscription } from 'rxjs/Subscription';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';
import * as moment from 'moment';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit, OnDestroy {

  date: Date;
  dateSubscription: ISubscription;
  label: string;
  minDate = {year: 2015, month: 1, day: 1};

  constructor(private _filter: FilterBarService) {
  }

  ngOnInit() {
    this.dateSubscription = this._filter.date.subscribe((data: Date) => {
      this.date = data;
    });
    this.label = this._filter.dateLabel;
  }

  ngOnDestroy() {
    this.dateSubscription.unsubscribe();
  }

  showError() {
    return this._filter.dateError;
  }

  updateFilter(newDate) {
    const result = Date.parse(newDate); // If not valid date, will return NaN
    if (typeof newDate !== 'object' || newDate == null || isNaN(result)) {
      this._filter.dateError = 'Date is Required';
      return;
    }
    this._filter.dateError = '';
    this._filter.tempDate = newDate;
  }
}
