import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../common-service/common.service';
import { SecurityService } from '../security/security.service';
import { ISubscription } from 'rxjs/Subscription';
import { Operation } from './operation.model';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss']
})
export class OperationComponent implements OnInit, OnDestroy {

  operations: Operation[];
  filteredOperations: Operation[];
  private operationSubscription: ISubscription;

  constructor(private _common: CommonService,
    private _security: SecurityService) {

  }

  ngOnInit() {
    this.operationSubscription = this._security.operations.subscribe((data) => {
      this.operations = data;
      this.filteredOperations = this.operations;
    });
    this.operations = this._security.availableOperations;
    this.filteredOperations = this.operations;
  }

  ngOnDestroy() {
    this.operationSubscription.unsubscribe();
  }

  getOperationDisplay(): string {
    const op = this._common.getOperation();
    if (op) {
      return op.name;
    } else {
      return '';
    }
  }

  setOperation(operation: Operation) {
    this._common.setOperation(operation, true);
  }

  filterOperations() {
    const filterText = (<HTMLInputElement>document.getElementById('operationFilter')).value.toLowerCase();
    this.filteredOperations =
        this.operations.filter(item => item.operationNo.startsWith(filterText) || item.name.toLowerCase().startsWith(filterText));
  }
}
