export class FiscalCloseSummary {
    targetCloseDate: Date | string;
    validationStateLookupID: Number;
    validationStateOriginal: string;
    validationState: string;
    dashboardStateError: boolean;
    costCenterID: Number;
    operationID: Number;
    fiscalCalendarID: Number;
    onOneWarehouse: boolean;
    lastValidationEndDateTime: Date | string | null;
    validationErrorsOnLastValidation: string;
    closeDate: Date | string | null;
    sapExtractDate: Date | string | null;
    sapCloseDate: Date | string | null;
    warehousesClosed: boolean;
}

export class FiscalCloseDetail {
    targetCloseDate: Date | string;
    startDate: Date | string;
    endDate: Date | string;
    validationStateLookupID: Number;
    validationState: string;
    costCenterID: Number;
    operationID: Number;
    fiscalCalendarID: Number;
    onOneWarehouse: boolean;
    lastValidationStartDateTime: Date | string | null;
    lastValidationEndDateTime: Date | string | null;
    validationErrorsOnLastValidation: boolean;
    closeDate: Date | string | null;
    sapExtractDate: Date | string | null;
    sapCloseDate: Date | string | null;
    cashlessInProcessInd: boolean;
    warehousesClosed: boolean;
    warehouses: FiscalCloseWarehouses[];
}

export class FiscalCloseWarehouses {
    name: string;
    status: string;
    warehouseID: Number;
}

export class FiscalCloseValidationResult {
    errors: FiscalClosingValidationItem[];
    warnings: FiscalClosingValidationItem[];
    hasErrors: boolean;
    hasCashlessErrors: boolean;
    cashlessRanSuccessfully: boolean | null;
}

export class FiscalCloseValidationStatus {
    costCenterID: number;
    fiscalCalendarID: number;
    statusCode: number;
    statusMessage: string;
    validationStartDateTime: Date;
    validationEndDateTime: Date;
    validationByUserID: number;
    totalValidations: number;
    validationErrors: FiscalCloseValidationResult;
}

export class FiscalClosingValidationItem {
    severity: string;
    fiscalDate: Date;
    description: string;
    identifyingInformation: string;
    storedProc: string;
    isCashlessError: boolean;
}

export class FiscalCloseConfigs {
    validationSprocs: FiscalCloseValidations[];
    transactionTypes: CashlessTransactionTypes[];
}

export class FiscalCloseValidations {
    spName: string;
    warehouse: string;
    cashless: boolean;
    index: number;
}

export class CashlessTransactionTypes {
    transactionType: string;
    settlementType: string;
}
