import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../core/common-service/common.service';
import { RemotePriceChangeService } from '../remote-price-change-service/remote-price-change.service';
import { MachineDeviceSearchRequest, PogDexDiscrepancyDisplayItem, PogDexDiscrepancySearchDetail, PogDexDiscrepancySearchItem, PogDexDiscrepancySearchMetadata, PogDexDiscrepancySearchRequest } from '../remote-price-change-service/remote-price-change.model';
import { ISubscription } from 'rxjs-compat/Subscription';
import { HttpErrorResponse, HttpUrlEncodingCodec } from '@angular/common/http';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';
import { filter } from 'rxjs-compat/operator/filter';
import * as moment from 'moment';

@Component({
  selector: 'dex-price-discrepancies',
  templateUrl: './dex-price-discrepancies.component.html',
  styleUrls: ['./dex-price-discrepancies.component.scss']
})
export class DexPriceDiscrepanciesComponent implements OnInit {

  generating: boolean;
  isRefreshingPage: boolean;
  responseMessage: string;
  errorMessage: string;
  majorAccountId: number;
  accLocList: number[];
  pogDexDiscrepancyInfos:  PogDexDiscrepancySearchItem[];
  pogDexDiscrepancySearchItemSubject: ISubscription;
  pogDexDiscrepancySearchMetadataSubject: ISubscription;
  gridDisplayLines: PogDexDiscrepancyDisplayItem[];
  httpErrorResponseSubject: ISubscription;
  params = ['routes', 'accounts','machine-search', 'status', 'buttons', 'dexPriceDiscrepancy'];
  pageIsDirty: boolean;
  freshPage = true;
  filterPosition = 'in';
  view = 'list';
  isMobile = false;

  page = 1;
  pageSize = 100;
  totalNumberOfItems = 0;
  sortDirection: string;
  sortColumn: string;
  routeIDList: number[];

  @ViewChild('operationFilter') operationFilter;

  constructor(private _common: CommonService,
              private _rpcService: RemotePriceChangeService,
              private _filterBarService: FilterBarService) 
  {
    this._common.setCurrentPage('RPC DEX Price Discrepancies');            
  }

  ngOnInit() {
    this.generating = false;
    this.pogDexDiscrepancyInfos = new Array();
    this.gridDisplayLines = new Array();
    this.resetPage();
    this.setupSubjects();
    this._rpcService.onRPCPriceDiscrepanciesDataReadyEventEmitter.subscribe(data => {
      this.pogDexDiscrepancyInfos.forEach(x => x.isCollapsed = true);
      this.buildDisplayLines();
    });
    this._filterBarService.submitRPCEvent.subscribe(data => {
      this.submitRPC();
    });
    this._filterBarService.selectedMajorAcct.subscribe(data => {
      this.majorAccountId = data;
    });
    this.pageIsDirty = false;
  }

  setupSubjects() {

    this.pogDexDiscrepancySearchItemSubject = this._rpcService.pogDexDiscrepancySearchItemSubject.subscribe((data: PogDexDiscrepancySearchItem[]) => {
      console.log("SEARCH REVIEW Result::",data);
      this.pogDexDiscrepancyInfos = data; 
      let totalCount = this.pogDexDiscrepancyInfos == null || this.pogDexDiscrepancyInfos.length == 0 ? 0 : -999;
      this.totalNumberOfItems = totalCount;                
      this.isRefreshingPage = false;
    });

    this.pogDexDiscrepancySearchMetadataSubject = this._rpcService.pogDexDiscrepancySearchMetadataSubject.subscribe((data:PogDexDiscrepancySearchMetadata) =>
    {
      console.log("SEARCH REVIEW Result Metadata", data);
      this.totalNumberOfItems = data.totalItemCount;
    });

    this.httpErrorResponseSubject = this._rpcService.httpErrorResponseSubject.subscribe((httpErrorResponse:HttpErrorResponse)=> {
      this.isRefreshingPage = false;
      let error = httpErrorResponse.error;
      if (error == null || error == undefined) {
        this.errorMessage = `Error. Failure: ${httpErrorResponse.statusText} (Details: ${httpErrorResponse.message})`;
      } else {
        this.errorMessage = `Error:${error.title} Failure: ${httpErrorResponse.statusText} (Details: ${httpErrorResponse.message} ${error.detail})`;
      }
    });

    this._rpcService.onRPCRequestBeginEventEmitter.subscribe(()=> {
      this._filterBarService.setSubmitRPCEnabled(false);
    });
    this._rpcService.onRPCRequestEndFailureEventEmitter.subscribe(()=> {
      this._filterBarService.setSubmitRPCEnabled(true);
    });
    this._rpcService.onRPCRequestEndSuccessEventEmitter.subscribe(()=> {
      this._filterBarService.setSubmitRPCEnabled(true);
    });
    this._rpcService.onRPCRequestChangeEventEmitter.subscribe((updateItem:PogDexDiscrepancySearchItem)=> {
      //let updateItem = data as PogDexDiscrepancySearchItem;
      if (updateItem != null && updateItem != undefined) {
        let findItem = this.gridDisplayLines.find(x => x.machineNumber == updateItem.machineNumber); // Find the matching "header" item
        if (findItem != null && findItem != undefined) {
          findItem.status = updateItem.statusCode;
          findItem.rpcSend = false;
        }
      }
    });
  }

  onApplyFilters(newFilters) {   
    const searchValue = this._filterBarService.machineSearch.value;
    if (searchValue != undefined && searchValue.length > 0 && isNaN(Number(searchValue))) {
      this._filterBarService.machineSearchError = 'Machine search must be a number';
      return;
    }

    const request = this.buildSearchRequest(newFilters);
    this.search(request);
  }

  pageChanged() {
    this.onApplyFilters(false);
    window.scrollTo(0, 0);
  }
  
  private buildSearchRequest(newRequest: boolean) {
    let request = new MachineDeviceSearchRequest();
    request.machineNumber = Number(this._filterBarService.machineSearch.value);
    request.costCenterID = Number(this._common.getOperation().operationNo);
    request.majorAccountId = this.majorAccountId;
    request.accountLocationList = this.getAccLocationArray();

    let filterStatusCode = this._filterBarService.status.value.toUpperCase(); 
    let statusCode = '';
    switch (filterStatusCode)
    {
      case 'IN PROGRESS':
      case 'IN PROCESS':
        statusCode='IN_PROCESS';
        break;
      case 'Disabled':
        statusCode='DISABLED';
        break;
      case 'Enabled':
        statusCode='ENABLED';
        break;
      case null:
        statusCode = '';
        break;
      default:
        statusCode = filterStatusCode;
        break;
    }
    request.status = statusCode;

    this.page = newRequest ? 1 : this.page;
    request.pageNumber = this.page;
    request.pageSize = this.pageSize;
    request.sortColumn = this.sortColumn;
    request.sortDirection = this.sortDirection;
    request.routeIDList = this.getRouteArray();
    console.log("Search request:",request);
    return request;
  }

  private getRouteArray() {
    this.routeIDList = new Array();
    if (this._filterBarService.routes.value) {
      for (let i = 0; i < this._filterBarService.routes.value.length; i++) {
        if(this._filterBarService.routes.value[i].selected) {  
          this.routeIDList.push(this._filterBarService.routes.value[i].routeID);
        }
      }
    }  
    return this.routeIDList;
  }

  private getAccLocationArray() { 
    this.accLocList = new Array();
    if (this._filterBarService.accountLocationList.value != null) {
      for (let i = 0; i < this._filterBarService.accountLocationList.value.length; i++) {
        if(this._filterBarService.accountLocationList.value[i].selected) {
          this.accLocList.push(this._filterBarService.accountLocationList.value[i].id);
        }
      }
    }
   return this.accLocList;
 }

  private search(request: PogDexDiscrepancySearchRequest) {
    this.freshPage = false;
    this.errorMessage = '';
    this.responseMessage = '';
    this.isRefreshingPage = true;
    this._rpcService.PogDexDiscrepancySearch(request);
  }

  resetPage() {
    this.isRefreshingPage = true;
    this._rpcService.Initialize();
    this._filterBarService.ResetFilterBar();
    this.isRefreshingPage = false;
  }

  onChangeView(view) {
    this.view = view;
    if (this.isMobile) {
      this.filterPosition = 'out';
    } else {
      this.filterPosition = view === 'grid' ? 'out' : 'in';
    }
  }

  getStatusColumnClass(item: PogDexDiscrepancyDisplayItem){
    switch (item.status) {
      case 'COMPLETED':
        return 'text-center greenCheck';
      case 'ERROR':
        return 'text-center redX';
      case 'IN_PROCESS':
        return 'text-center blueCircle';
      default:
        return 'text-center';
    }
  }
  getStatusIcon(item: PogDexDiscrepancyDisplayItem) {
    switch (item.status) {
      case 'COMPLETED':
        return '\u2713';
      case 'ERROR':
        return '\u274c';
      case 'IN_PROCESS':
        return '\u25ef';
      case 'DISABLED':
        return '\u26d4'; // red circle with while horizontal line through it
      default:
        return '';
    }
  }
    
  showCurrency(value: number) {
    return typeof value == "number" ? value.toFixed(2) : "";
  }

  markAsDirty() {
    this.pageIsDirty = true;
  }

  buildDisplayLines() {
    this.gridDisplayLines = [];
    this.pogDexDiscrepancyInfos.forEach((item) => {
      this.gridDisplayLines.push(this.makeDisplayLineFromItem(item));
      if (!item.isCollapsed) {
        item.details.forEach((detail) => {
          this.gridDisplayLines.push(this.makeDisplayLineFromDetail(detail, item))
        });
      }
    });
  }

  private makeDisplayLineFromItem(item: PogDexDiscrepancySearchItem) {
    let result = new PogDexDiscrepancyDisplayItem();
    result.isHeaderItem     = true;
    result.accountName      = item.accountName;
    result.locationName     = item.locationName;
    result.routeNumber      = item.routeNumber;
    result.deviceNo         = item.deviceNo;
    result.machineId        = item.machineID;
    result.machineNumber    = item.machineNumber;
    result.dexType          = item.dexType;
    result.selection        = item.selection;
    result.dexPrice         = item.dexPrice;
    result.currentPogPrice  = item.currentPogPrice;
    result.productLongName  = '';
    result.rpcOn            = item.rpcEnabled ? 'YES' : 'NO';
    result.rpcSend          = false;
    result.rpcSendDate      = item.rpcSendDate;
    result.status           = item.statusCode;
    result.isCollapsed      = item.isCollapsed;
    return result;
  }

  private makeDisplayLineFromDetail(detail: PogDexDiscrepancySearchDetail, item: PogDexDiscrepancySearchItem) {
    let result = new PogDexDiscrepancyDisplayItem();
    result.isHeaderItem     = false;
    result.accountName      = '';
    result.locationName     = '';
    result.deviceNo         = null;
    result.routeNumber      = null;
    result.dexType          = '';
    result.selection        = detail.selectionName;
    result.dexPrice         = detail.dexPrice;
    result.currentPogPrice  = detail.pogPrice;
    result.productLongName  = detail.productLongName
    result.rpcOn            = '';
    result.rpcSendDate      = null;
    result.status           = null;
    result.isCollapsed      = item.isCollapsed;
    return result;
  }

  collapseItem(item : PogDexDiscrepancyDisplayItem) {
    var itemInList : PogDexDiscrepancySearchItem = this.pogDexDiscrepancyInfos.find(x => x.deviceNo == item.deviceNo && x.machineID == item.machineId);
    itemInList.isCollapsed = !itemInList.isCollapsed;
    this.buildDisplayLines();
  }

  showCheckboxOrNah(item: PogDexDiscrepancyDisplayItem) {
    if (item.isHeaderItem && (item.status =="ENABLED" || item.status=="ERROR" || item.status=="COMPLETED")) {
      return '';
    } 
    else {
      return 'hidden-checkbox';
    }
  }

  getPageInfo() {
    return this.totalNumberOfItems;
  }

  submitRPC() {
    let itemsToSend = new Array<PogDexDiscrepancySearchItem>();
    this.gridDisplayLines.forEach(item=> {
      if (item.rpcSend) {
        let findItem = this.pogDexDiscrepancyInfos.find(x=>x.machineNumber == item.machineNumber);
        if (findItem !== undefined) {
          itemsToSend.push(findItem);
        }
      }
    });
    this._rpcService.sendMachineDeviceSelectionPriceRequests(itemsToSend);
  }

  sortOpenOperationStatuses(sortAsc, columnName, boolValue) {
    this.sortColumn = columnName;
    this.sortDirection = sortAsc ? 'asc' : 'desc';
    let request : MachineDeviceSearchRequest = this.buildSearchRequest(true);
    this.search(request);
    this.buildDisplayLines();
  }

  blankOutMinDate(e: Date){
    const minDateTime = new Date('0001-01-01T00:00:00').getTime();
    var dateTime = new Date(e);
    if (e==null || dateTime.getTime() === minDateTime)
      return '';
    else
      return moment(dateTime).format ("MM/DD/YYYY h:mm A");
  }

}
