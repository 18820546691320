import { Component, OnInit, ViewChild } from '@angular/core';
import { DeviceManagementService } from '../device-service/device-management.service';
import { NewDevice } from '../device-service/device-management.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../core/common-service/common.service';
import { SecurityService } from '../../core/security/security.service';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  vendor = 'Select';
  vendorList: string[];
  deviceNumber: string;
  modelList: string[];
  dateReceived: Date;
  saveDisabled = false;
  newDevice: NewDevice;
  errorMessage = '';
  showDevicePrefixWarning = false;

  @ViewChild('basic') basic;
  basicModalOptions: NgbModalOptions = { centered: true };
  modalType = '';
  modalMessage = '';

  constructor(private _common: CommonService,
    private _security: SecurityService,
    private _device: DeviceManagementService,
    private _modal: NgbModal,
    private _router: Router) {
      this._common.setCurrentPage('Add Device');
      this._common.operationGuideSubject.next('Operations Guide - One Vend - Device Management.pdf');
  }

  ngOnInit() {
    this.newDevice = new NewDevice();
    this.newDevice.model = 'Select';
    this._device.GetVendors().subscribe((vendors) => {
      this.vendorList = vendors;
    });
  }

  addDevice() {
    this.saveDisabled = true;
    let error = false;
    let message = '';

    // Validation checks
    if (this.newDevice.model === 'Select') {
      error = true;
      message += 'Must select a device model. ';
    }
    if (!/^([A-Za-z0-9\-]+)$/.test(this.newDevice.deviceNumber)) {
      error = true;
      message += 'Device must have valid characters only [A-Z, a-z, 0-9, -]. ';
    }
    if (this.newDevice.deviceNumber === undefined) {
      error = true;
      message += 'A device number must be entered. ';
    }
    if (this.newDevice.dateReceived === undefined || this.newDevice.dateReceived === null) {
      error = true;
      message += 'A value for date received is required. ';
    }

    if (!error) {
      this.newDevice.deviceNumber = this.newDevice.deviceNumber.trim();
      this.newDevice.costCenter = Number(this._common.operation.operationNo);
      this.newDevice.vendorCode = this.vendor;
      this.newDevice.userId = this._security.getUserID();
      this._device.AddNewDevice(this.newDevice).subscribe((result) => {
        if (result.toString() === 'Device Added.') {
          console.log(this.newDevice);
          this.errorMessage = '';
          this.modalType = 'success';
          // this.modalMessage =
          // '<p>Your changes have been saved.</p><p><a href="/device/assign/' +
          // this.newDevice.deviceNumber + '">Assign</a> this device or click outside to Add another</p>';
          this.modalMessage = 'Your changes have been saved.';
          this._modal.open(this.basic);
          this.clear();
        } else {
          this.errorMessage = 'Error: ' + result;
        }
        this.saveDisabled = false;
      },
      (err) => {
        console.log(err);
        this.saveDisabled = false;
      });
    } else {
      this.errorMessage = 'Error: ' + message;
      this.saveDisabled = false;
    }
  }

  clear() {
    this.newDevice = new NewDevice();
    this.newDevice.model = 'Select';
  }

  goToAssignDevice(deviceID) {
    this._router.navigate(['/device/assign/' + deviceID]);
  }

  selectModel(model: any) {
    this.newDevice.model = model;
  }

  selectVendor(vendor: string) {
    this.vendor = vendor;
    this.showDevicePrefixWarning = (vendor === 'USAT');
    this._device.GetModels(vendor).subscribe((models) => {
      this.newDevice.model = 'Select';
      this.modelList = models;
    });
  }
}
