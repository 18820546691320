import { Injectable } from '@angular/core';
import {RouteLineItem,
        MachineServiceReviewListItem,
        MachineServiceReviewSearchResponse, 
        MachineServiceReviewSearchRequest,
        MachineServiceDetailsItem,
        MachineServiceDetailsResponse,
        MachineServicePhotoItem} from './machine-service.model';

import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';
import { SecurityService } from '../../core/security/security.service';
import { CommonService } from '../../core/common-service/common.service';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as moment from 'moment';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class MachineServiceService {
  mchServiceReviewList: MachineServiceReviewListItem[];
  mchServiceReviewSubject: Subject<MachineServiceReviewListItem[]> = new Subject<MachineServiceReviewListItem[]>();
  private pageSize: number;
  private pageNum: number;
  appSettings: any;
  previousSearch: MachineServiceReviewSearchRequest;
  previousRoutes: RouteLineItem[];
  restorePreviousSearch = false;

  constructor(private _http: HttpClient,
    private _filter: FilterBarService,
    private _common: CommonService,
    private appSettingsProvider: AppsettingsProvider) {
      this.appSettings = this.appSettingsProvider.getSettings();
      this.pageSize = this.appSettings.tableRowLength;
      this.pageNum = 1;
     }

  Initialize() {
    const costCenterID = Number(this._common.getOperation().operationNo);

    // Get all filter lists and the result set from MachineService API
    this._http.get<MachineServiceReviewSearchResponse>(
      this.appSettings.urlLocations.machineServiceURL + costCenterID)
      .subscribe((data: MachineServiceReviewSearchResponse) => {       
       const request = new MachineServiceReviewSearchRequest();
       const routeList = data.routeLineItems;
       this._filter.nonFilteredRoutes = routeList;
       this._filter.routes.next(routeList);
        request.costCenterID = costCenterID;
        request.fromDate = moment().subtract(1, 'days').toDate();
        request.toDate = moment().subtract(1, 'days').toDate();
        this._filter.routes.next(this._filter.nonFilteredRoutes.filter((item) => item.routeID));
        request.routeIDList = this._filter.routes.value.map(r => r.routeID);
        request.accountLocationList = null;
        request.machineNumber = 0;
        request.pageNumber = this.pageNum;
        request.pageSize = this.pageSize;

         this._http.post<MachineServiceReviewSearchResponse>(this.appSettings.urlLocations.machineServiceURL + 'search', request)
         .subscribe((mchServiceReviewData: MachineServiceReviewSearchResponse) => {
          this.mchServiceReviewList = mchServiceReviewData.machineServiceReviewListItems; 
         this.mchServiceReviewSubject.next(mchServiceReviewData.machineServiceReviewListItems.slice()); 
         },
         (error)=>{
          console.log(error);
         });
      });
  }

  Search(searchRequest: MachineServiceReviewSearchRequest) {
    this._http.post<MachineServiceReviewSearchResponse>(this.appSettings.urlLocations.machineServiceURL + 'search', searchRequest)
      .subscribe((data: MachineServiceReviewSearchResponse) => {
        this.mchServiceReviewList = data.machineServiceReviewListItems;
        this.mchServiceReviewSubject.next(data.machineServiceReviewListItems.slice());
    },
    (error)=> {
      console.log(error);
    });
  }

  SearchPhotos(photos:number):Observable<any>
  {
    const headers= new HttpHeaders()
   .set('Content-Type', 'application/octet-stream; charset=utf-8')
   .set('Access-Control-Allow-Origin', '*');
    return this._http.get<any>(this.appSettings.urlLocations.machineServiceURL + 'servicePhotoThumbnail/'+ photos,{ headers, responseType: 'arraybuffer' as 'json'});
  }

  GetServiceDetails(machineSvcID: number) {
    return this._http.get<MachineServiceDetailsResponse>(this.appSettings.urlLocations.machineServiceURL 
      + 'serviceDetails/' + machineSvcID);
  }
}
