import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ISubscription } from 'rxjs/Subscription';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit, OnDestroy {

  status: string;
  statusSubscription: ISubscription;
  @Input() params: string[];

  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this.statusSubscription = this._filter.status.subscribe((data:string) => {
      this.status = data;
    });
    this._filter.resetFilters.subscribe(data => {
      this.reset();
    });
  }

  ngOnDestroy() {
    this.statusSubscription.unsubscribe();
  }

  onChange(e: any) {
    this.status = e.target.value;
    this.status = e.target.value;
    this._filter.status.next(e.target.value);
  }

  reset() {
    this.status = '';
    let select = document.getElementById("status-filter") as HTMLSelectElement;
    if (select != null) {
      select.selectedIndex = 0;
    }
  }
}
