import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'YesNo' })
export class YesNoPipe implements PipeTransform {
    transform(value: boolean): string {
        if (value != null) {
            return value == true ? 'Yes' : 'No';
        } else {
            return '';
        }
    };
}
