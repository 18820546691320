import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../../../core/common-service/common.service';
import { Router } from '@angular/router';
import { FiscalCloseSummary } from '../../operation-service/fiscal-closing.model';
import * as moment from 'moment';
import { OperationsService } from '../../operation-service/operations.service';

@Component({
  selector: 'app-closing-summary',
  templateUrl: './closing-summary.component.html',
  styleUrls: ['./closing-summary.component.scss']
})
export class ClosingSummaryComponent implements OnInit {

  loading = false;
  fiscalWeeks: FiscalCloseSummary[];

  constructor(private _common: CommonService,
    private _operations: OperationsService,
    private _router: Router) {
    this._common.setCurrentPage('Fiscal Close');
    this._common.operationGuideSubject.next('Operations Guide - OneVend - Fiscal Close.pdf');
  }

  ngOnInit() {
    this._getSummary();
  }

  ngOnDestroy() {
    this._common.operationGuideSubject.next(null);
  }

  _getSummary() {
    this.loading = true;
    const operation = Number(this._common.operation.operationNo);
    this._operations.GetFiscalClosingSummary(operation).subscribe((data) => {
      this.fiscalWeeks = data;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  refresh() {
    this._getSummary();
  }

  viewDetail(week) {
    const fiscalCalendarID = week.fiscalCalendarID;
    this._router.navigate(['/operations/fiscal-closing/' + fiscalCalendarID]);
  }

}
