import { Switch } from '../../shared/switch/switch.model';

export class SAPExportFile {
  filename: string;
  selectedDisplay: Switch;

  public constructor(filename: string) {
    this.filename = filename;
    this.selectedDisplay = new Switch('', false, true);
  }
}

export class SAPExportRequestFile {
  filename: string;
}

export class SAPExportRequest {
  costCenterID: number;
  fileType: string;
  startDate: Date;
  endDate: Date;
  exportFiles: Array<SAPExportRequestFile>;

  constructor() {
    this.exportFiles = new Array<SAPExportRequestFile>();
  }
}


export class SAPExportResponse {
  messages: Array<string>;
  errors: Array<string>;
}
