import { SafeResourceUrl } from '@angular/platform-browser';

export class MachineServiceReviewSearchRequest {
  fromDate: Date | string;
  toDate: Date | string;
  costCenterID: number;
  routeIDList: number[];
  accountLocationList: number[];
  machineNumber: number;
  pageNumber: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: string;
  withPhotosOnlyInd:boolean;
  overridePhotoInd:boolean;
}

export interface MachineServiceReviewListItem {
  machineServiceID: number;
  routeNumber: string;
  serviceDate: Date;
  serviceDateDisplay: string;
  machineNumber: number;
  serviceType: string;
  accountName: string;
  locationName: string;
  servicerName: string;
  orderUsedInd: boolean;
  orderUsedDisplay: string;
  afterPhotoID:number;
  beforePhotoID:number; 
  afterPhotoSrc:SafeResourceUrl;
  beforePhotoSrc:SafeResourceUrl;
  hasServiceImages: boolean;
  photoRequiredDescription: string;
  overridePhotoReason: string;
  totalCount: number;
  beforePhotoChecked: boolean;
  afterPhotoChecked: boolean;
}

export interface MachineServiceDetailsItem {
  machineServiceID: number;
  photoBeforeID: number;
  photoAfterID: number;
  planogramID: number;
  routeNumber: string;
  serviceDate: Date;
  machineNumber: number;
  serviceType: string;
  dispenseType: string;
  servicer: string;
  account: string;
  location: string;
  salesAmount: number;
  refundAmount: number;
  salesUnits: number;
  filledUnits: number;
  selectionNoSalesCount: number;
  selectionStockoutCount: number;
}

export interface MachineServicePlanogramItem {
  productImageID: number;
  productItemID: number;
  productID: number;
  sku: string;
  description: string;
  binaryImage: Uint8Array[];
  fullImageSrcPath: SafeResourceUrl;
  fileExtension: string;
  row: number;
  column: number;
  selection: string;
  price: number;
  salesAmount: number;
  salesUnits: number;
  spoils: number;
  damaged: number;
  otherRemovals: number;
  beginningInventory: number;
  filledSalesAmount: number;
  endingInventory: number;
  par: number;
  capacity: number;
  stockOut: boolean;
  noSales: boolean;
}

export interface MachineServicePlanogramOrdering {
  maximumColumnCount: number;
  maximumRowCount: number;
  selectionRow: number;
  selectionColumn: number;
  productID: number;
  capacity: number;
}

export interface MachineServicePhotoItem {
  photoByteStream: Uint8Array[];
  isBrokenImage: boolean;
}

export interface MachineServiceReviewSearchResponse {
   routeLineItems: RouteLineItem[];
   machineServiceReviewListItems: MachineServiceReviewListItem[];
}

export interface MachineServiceDetailsResponse {
  machineServiceDetails: MachineServiceDetailsItem;
  machineServicePlanogram: MachineServicePlanogramItem[][];
  serviceBeforePhoto: MachineServicePhotoItem;
  serviceAfterPhoto: MachineServicePhotoItem;
}

export interface RouteLineItem {
  displayName: string;
  routeID: number;
  routeNumber: number;
  selected: boolean;
  preselectAllRoutes: boolean;
}
