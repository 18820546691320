import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../../core/common-service/common.service';
import { UtilitiesService } from '../utilities-service/utilities.service';
import { WarehouseInvConsolidationInformation } from '../utilities-service/warehouse-consolidation.model';

@Component({
  selector: 'app-warehouse-consolidation',
  templateUrl: './warehouse-consolidation.component.html',
  styleUrls: ['./warehouse-consolidation.component.scss']
})
export class WarehouseConsolidationComponent implements OnInit, OnDestroy {


  warehouses: WarehouseInvConsolidationInformation[];
  loading = false;

  constructor(private _common: CommonService,
    private _utility: UtilitiesService) {
    this._common.setCurrentPage('Warehouse Consolidation');
    this._common.operationGuideSubject.next('Operations Guide - One Vend - Warehouse Inventory Consolidation.pdf');
  }

  ngOnInit() {
    this._getWarehouseInfo();
  }

  ngOnDestroy() {
    this._common.operationGuideSubject.next(null);
  }

  consolidate(warehouse) {
    const costCenterID = Number(this._common.getOperation().operationNo);
    warehouse.consolidating = true;
    this._utility.ConsolidateWarehouse(warehouse.physicalInventoryID, costCenterID).subscribe((data) => {
      if (data === 'SUCCESS') {
        const operation = Number(this._common.operation.operationNo);
        this._utility.GetWarehouseInfo(operation).subscribe((warehouseInfo: WarehouseInvConsolidationInformation[]) => {
          warehouseInfo.forEach((w) => {
            // Find existing row and update with new values after consolidation runs
            if (w.physicalInventoryID == warehouse.physicalInventoryID) {
              warehouse.consolidatedStatus = w.consolidatedStatus;
              warehouse.reconciledStatus = w.reconciledStatus;
              warehouse.inventoryEntryCount = w.inventoryEntryCount;
              warehouse.eligibletoConsolidate = w.eligibletoConsolidate;
            }
          });
          warehouse.consolidating = false;
        }, (error) => {
          console.log(error);
          warehouse.consolidating = false;
        });
      } else if (data === 'RUNNING') {
        warehouse.eligibletoConsolidate = 'running';
      } else if (data === 'NOTVALID') {
        warehouse.eligibletoConsolidate = 'not valid';
      } else if (data === 'DUPLICATE') {
        warehouse.eligibletoConsolidate = 'duplicate';
      } else {
        warehouse.eligibletoConsolidate = 'Contact VIS';
      }
      warehouse.consolidating = false;
    }, (error) => {
      warehouse.consolidating = false;
      console.log(error);
    });
  }

  _getWarehouseInfo() {
    this.loading = true;
    const operation = Number(this._common.operation.operationNo);
    this._utility.GetWarehouseInfo(operation).subscribe((data: WarehouseInvConsolidationInformation[]) => {
      this.warehouses = data;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  refresh() {
    this._getWarehouseInfo();
  }

  sort(sortAsc, columnName, isString) {

  }

}
