import { Component, OnInit } from '@angular/core';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';

@Component({
  selector: 'app-photos-override',
  templateUrl: './photos-override.component.html',
  styleUrls: ['./photos-override.component.scss']
})
export class PhotosOverrideComponent implements OnInit {
  resetPhoto:boolean;
  public PhotosOverride: any;
  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this.resetPhoto = true;
  }
  ngDoCheck() {
    this.resetPhoto = this._filter.photosOverride.value;
  }
  photosCheck(event)
  {
    this.PhotosOverride = (event.target.checked);
    this._filter.photosOverride.next(this.PhotosOverride); 
  }
}
