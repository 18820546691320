import * as FileSaver from 'file-saver';

import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common-service/common.service';
import { FilesService } from '../../../shared/file-service/files.service';
import { AppsettingsProvider } from '../../appsettings.provider';


@Component({
  selector: 'app-operations-guide',
  templateUrl: './operations-guide.component.html',
  styleUrls: ['./operations-guide.component.scss']
})
export class OperationsGuideComponent implements OnInit {

  appSettings: any;
  operationGuideURL: string;
  guide: string;

  constructor(private _common: CommonService,
    private _appSettings: AppsettingsProvider) {
      this.appSettings = this._appSettings.getSettings();
      this.operationGuideURL = this.appSettings.urlLocations.operationGuidesURL;
  }

  ngOnInit() {
    this._common.operationGuideSubject.subscribe((guide) => {
      this.guide = guide;
    });
  }

}
