import { Component, Input, OnInit } from '@angular/core';
import { ISubscription } from 'rxjs-compat/Subscription';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';

@Component({
  selector: 'app-rpcActivation',
  templateUrl: './rpc-activation.component.html',
  styleUrls: ['./rpc-activation.component.scss']
})
export class RpcActivationComponent implements OnInit {

  rpcActivation: string;
  rpcActivationSubscription: ISubscription;
  @Input() params: string[];

  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this.rpcActivationSubscription = this._filter.rpcActivation.subscribe(data => {
      this.rpcActivation = data;
    });
    this._filter.resetFilters.subscribe(data => {
      this.reset();
    });
  }

  ngOnDestroy() {
    this.rpcActivationSubscription.unsubscribe();
  }

  onChange(e: any) {
    this.rpcActivation = e.target.value;
    this._filter.rpcActivation.next(e.target.value);
  }

  reset() {
    this.rpcActivation = '';
    let select = document.getElementById("rpcActivation-filter") as HTMLSelectElement;
    if (select != null) {
      select.selectedIndex = 0;
    }
  }

}
