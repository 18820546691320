import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Report_Param_CSM_Response } from '../../reports-service/reports.model';
import { Switch } from '../../../shared/switch/switch.model';


@Component({
  selector: 'app-rpt-csm',
  templateUrl: './rpt-csm.component.html',
  styleUrls: ['./rpt-csm.component.scss']
})
export class RptCsmComponent implements OnInit {

  @Input()
  csms: Report_Param_CSM_Response[];

  @Output()
  csmsChanged = new EventEmitter();

  csmDisplay: string;
  selectAll = new Switch('Select All', false, true);
  errorMessage = '';

  constructor() { }

  ngOnInit() {
  }


  toggleSelectAllCSMS(event: Switch) {
    this.csms.forEach(x => x.selected.input = !event.input);
    this.noneSelected(this.csms);
    this.csmsChanged.emit(this.csms);
  }

  csmOnClick(event: Switch, csm: Report_Param_CSM_Response) {
    const tempArray = this.csms.filter(x => x.csmid !== csm.csmid);
    // Uses bool directly from event to avoid 2 way binding delay
    const eventBool = !event.input;
    this.selectAll.input = tempArray.every(x => x.selected.input === true) &&  eventBool === true;
    this.noneSelected(tempArray, eventBool);
    this.csmsChanged.emit(this.csms);
  }

  // Pass extra bool to deal with 2 way binding delay
  noneSelected(inputArray, retrievedBool = null) {
    this.errorMessage = inputArray.every(x => x.selected.input === false) && (retrievedBool == null || retrievedBool === false)
    ? 'CSM is Required' : '';
  }
}
