import { Component, OnInit, OnDestroy } from '@angular/core';
import { ISubscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { CommonService } from '../../core/common-service/common.service';
import { SecurityService } from '../../core/security/security.service';
import { NoServiceReviewService } from '../no-service-review-service/no-service-review.service';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';
import {
  NoServiceReviewListItem,
  NoServiceReviewSearchRequest,
  NoServiceReviewUpdateRequest
} from '../no-service-review-service/no-service-review.model';
import { RoleGuardService } from '../../core/role-guard/role-guard.service';

@Component({
  selector: 'app-no-service-review',
  templateUrl: './no-service-review.component.html',
  styleUrls: ['./no-service-review.component.scss']
})
export class NoServiceReviewComponent implements OnInit, OnDestroy {
  isMobile = false;
  isRefreshingPage = false;
  freshPage = false;
  appSettings: any;
  totalNumberOfItems = 0;
  editMgmtException = this._roleGuard.hasRole('EDIT_NO_SERVICE_MGMT_EXCEPTION');
  filter: FilterBarService;
  previousPage = 1;
  page = 1;
  pageSize = 0;
  params = ['csm', 'date-range', 'routes', 'buttons', 'listView', 'gridView'];
  noServiceReviewList: NoServiceReviewListItem[] = new Array();
  view = 'list'; // Can also be grid
  filterPosition = 'in';
  private noServiceReviewSubscription: ISubscription;
  selectedCSM: string;
  selectedDateRange: string;
  selectedRoutes: string;

  sortDirection: string;
  sortColumn: string;

  constructor(private _common: CommonService,
    private _security: SecurityService,
    private _filter: FilterBarService,
    private _noService: NoServiceReviewService,
    private appSettingsProvider: AppsettingsProvider,
    private _roleGuard: RoleGuardService,
    private _deviceService: DeviceDetectorService) {
    this._common.setCurrentPage('No Service Review');
    this._common.operationGuideSubject.next('Operations Guide - One Vend - No Service Review.pdf');
    this.appSettings = this.appSettingsProvider.getSettings();
    this.pageSize = this.appSettings.tableRowLength;
  }

  ngOnInit() {
    this.filter = this._filter;
    this._filter.currentPage = 'no-service-review';
    this.sortColumn = 'routeNumber';
    this.sortDirection = 'DESC';
    this.isMobile = this._deviceService.isMobile();
    if (this.isMobile) {
      this.view = 'grid';
      this.filterPosition = 'out';
    }
    this.noServiceReviewSubscription = this._noService.noServiceReviewSubject.subscribe((data: NoServiceReviewListItem[]) => {
      this.noServiceReviewList = data;

      if (data.length > 0) {
        this.totalNumberOfItems = data[0].totalCount;
      }
      this.isRefreshingPage = false;      
    });

    this._filter.dateRangeLabel = 'FISCAL DATE*';

    this.resetPage();
  }

  ngOnDestroy() {
    this.noServiceReviewSubscription.unsubscribe();
    this._common.operationGuideSubject.next(null);
  }

  getDisplayableDateRange() {
    const from = this._filter.dateFrom.value;
    const fromString = (from.getMonth() + 1) + '/' + from.getDate() + '/' + from.getFullYear();
    const to = this._filter.dateTo.value;
    const toString = (to.getMonth() + 1) + '/' + to.getDate() + '/' + to.getFullYear();
    return fromString + ' to ' + toString;
  }

  getPageInfo() {
    const pn = this.page;
    const ps = this.pageSize;
    const firstPageRecord = (((pn - 1) * ps) + 1);
    return firstPageRecord + ' - ' + ((firstPageRecord - 1) + this.noServiceReviewList.length) + ' of ' + this.totalNumberOfItems;
  }

  getRouteNumbers() {
    const routeNumberList = new Array();
    for (let i = 0; i < this._filter.routes.value.length; i++) {
      if (this._filter.routes.value[i].selected) {
        routeNumberList.push(this._filter.routes.value[i].displayName);
      }
    }
    if (routeNumberList.length === 0) { // If no routes are selected then select all current routes
      return 'All Routes';
    }
    return routeNumberList.join(', ');
  }

  private getRouteArray() {
    const routeIDList = new Array();
    for (let i = 0; i < this._filter.routes.value.length; i++) {
      if (this._filter.routes.value[i].selected) {
        routeIDList.push(this._filter.routes.value[i].id);
      }
    }
    return routeIDList;
  }

  isRefreshing() {
    return this.isRefreshingPage;
  }

  resetPage() {
    this._noService.Initialize();
    const df = moment().subtract(8, 'days').toDate();
    const dt = moment().subtract(1, 'days').toDate();
    this._filter.dateFrom.next(df);
    this._filter.dateTo.next(dt);

    this._filter.dateFromError = '';
    this._filter.dateToError = '';
    this.isRefreshingPage = false;
    this.freshPage = true;
  }

  onApplyFilters(newFilters) {
    this.isRefreshingPage = true;
    if (typeof this._filter.dateFrom.value !== 'object' || this._filter.dateFrom.value == null) {
      this._filter.dateFromError = 'Service From is Required';
      return;
    }
    if (typeof this._filter.dateTo.value !== 'object' || this._filter.dateTo.value == null) {
      this._filter.dateToError = 'Service To is Required';
      return;
    }
    if (this._filter.dateFrom.value > this._filter.dateTo.value) {
      this._filter.dateFromError = 'Service From cannot be greater than Service To';
      return;
    }

    // Set selections for header for grid view so they don't change as filter changes
    this.selectedCSM = this._filter.csm.value.displayName;
    this.selectedDateRange = this.getDisplayableDateRange();
    this.selectedRoutes = this.getRouteNumbers();

    const request = this.buildSearchRequest(newFilters);
    this.search(request);
  }

  onChangeView(view) {
    this.view = view;
    if (this.isMobile) {
      this.filterPosition = 'out';
    } else {
      this.filterPosition = view === 'grid' ? 'out' : 'in';
    }
  }

  onResetFilters(apply) {
    this.resetPage();
  }

  private buildSearchRequest(newRequest: boolean) {
    const request = new NoServiceReviewSearchRequest();
    request.fromDate = this._filter.dateFrom.value;
    request.toDate = this._filter.dateTo.value;
    request.operationID = this._common.getOperation().operationID;
    request.costCenterID = Number(this._common.getOperation().operationNo);
    this.page = newRequest ? 1 : this.page;
    request.pageNumber = this.page;
    request.pageSize = this.pageSize;
    request.sort_Column = this.sortColumn;
    request.sort_Direction = this.sortDirection;
    request.routeIDList = this.getRouteArray();

    return request;
  }

  pageChanged() {
    this.onApplyFilters(false);
    window.scrollTo(0, 0);
  }

  private search(request: NoServiceReviewSearchRequest) {
    this.freshPage = false;
    this._noService.Search(request);
  }

  sort(sortAsc, columnName) {
    this.sortColumn = columnName;
    this.sortDirection = sortAsc ? 'ASC' : 'DESC';
    const request = this.buildSearchRequest(true);
    this.search(request);
  }

  switchAtFault(item) {
    if (item.canBeModifiedInd) {

      item.atFaultInd = !item.atFaultInd;
      item.managementExceptionInd = item.atFaultInd ? 1 : 0;
      item.statusDisplayText = item.atFaultInd ? 'Yes' : 'No';

      item.modifiedInd = (item.atFaultInd !== item.atFaultOriginalInd);

      const request: NoServiceReviewUpdateRequest = {
        userID: this._security.getUserID(),
        atFaultMachineServiceIDList: new Array(),
        notAtFaultMachineServiceIDList: new Array()
      };
      
      if (item.atFaultInd) {
        request.atFaultMachineServiceIDList.push(item.machineServiceID);
      } else {
        request.notAtFaultMachineServiceIDList.push(item.machineServiceID);
      }

      this._noService.Update(request).subscribe(data => {
        // Do nothing, UI has already changed
      }, (error) => {
        console.log(error);
      });
    }
  }
}
