import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {  MachineServiceDetailsItem, 
          MachineServiceDetailsResponse,
          MachineServicePlanogramItem, 
          MachineServicePlanogramOrdering, 
          MachineServiceReviewSearchRequest,
          MachineServicePhotoItem} from '../machine-service-service/machine-service.model';
import { CommonService } from '../../core/common-service/common.service';
import { MachineServiceService } from '../machine-service-service/machine-service.service';
import { ISubscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-machine-service-details',
  templateUrl: './machine-service-details.component.html',
  styleUrls: ['./machine-service-details.component.scss']
})
export class MachineServiceDetailsComponent implements OnInit {
  machineSvcID: number;
  machineServiceDetailsResponse: MachineServiceDetailsResponse;
  machineServiceDetails: MachineServiceDetailsItem;
  planogramTableArray: MachineServicePlanogramItem[][];
  servicePhotoBefore: MachineServicePhotoItem;
  servicePhotoAfter: MachineServicePhotoItem;
  appSettings: any;
  photoBeforeUrl: string;
  photoAfterUrl: string;
  displayedImage: string;
  maxColumnCount: number;
  maxRowCount: number;

  @ViewChild('fullScreenModal') fullScreenModal;
  @ViewChild('fullScreenClose') fullScrenClose;
  @ViewChild('planogramDiv') planogramDiv: ElementRef;

  constructor(
    private _route: ActivatedRoute,
    private _location: Location,
    private _common: CommonService,
    private appSettingsProvider: AppsettingsProvider,
    private sanitizer: DomSanitizer,
    private _machineService: MachineServiceService) {
      this._common.setCurrentPage('Machine Service Details');
      this._common.operationGuideSubject.next('Operations Guide - One Vend - Machine Service Review.pdf');
  }

  ngOnInit() {
    this.machineSvcID = this._route.snapshot.params['machineSvcID'];
    this.appSettings = this.appSettingsProvider.getSettings();
    this.displayedImage = 'after';
    this._getDetails();
  }

  _getDetails() {
    this._machineService.GetServiceDetails(this.machineSvcID).subscribe((data) => {
      this.machineServiceDetailsResponse = data;
      this.machineServiceDetails = data.machineServiceDetails;
      this.planogramTableArray = data.machineServicePlanogram;
      this.servicePhotoBefore = data.serviceBeforePhoto;
      this.servicePhotoAfter = data.serviceAfterPhoto;

      this.maxRowCount = this.planogramTableArray == null ? 0 : this.planogramTableArray.length;
      this.maxColumnCount = this.planogramTableArray == null ? 0 : this.planogramTableArray[0].length;

      this.parseServicePhotos();
      this.parsePlanogramArray();
    },
    (error) => {
      console.log(error);
    });
    
  }

  parseServicePhotos() {
    if (this.servicePhotoBefore != null && !this.servicePhotoBefore.isBrokenImage) {
      this.photoBeforeUrl = 'data:image/jpg;base64,' + this.servicePhotoBefore.photoByteStream;
    } else {
      this.photoBeforeUrl = '../../../assets/NoImageAvailable.jpg';
    }

    if (this.servicePhotoAfter != null && !this.servicePhotoAfter.isBrokenImage) {
      this.photoAfterUrl = 'data:image/jpg;base64,' + this.servicePhotoAfter.photoByteStream;
    } else {
      this.photoAfterUrl = '../../../assets/NoImageAvailable.jpg';
    }
  }

  parsePlanogramArray() {
    if (this.planogramTableArray != null) {
      for (let i = 0; i < this.planogramTableArray.length; i++) {
        for (let j = 0; j < this.planogramTableArray[i].length; j++) {
          const planogramItem = this.planogramTableArray[i][j];
          if (planogramItem != null) {
            const cleansedUrl = 'data:image/' + planogramItem.fileExtension + ';base64,' + planogramItem.binaryImage;
            this.planogramTableArray[i][j].fullImageSrcPath = this.sanitizer.bypassSecurityTrustResourceUrl(cleansedUrl);
          }
        }
      } 
    }
  }

  changeDisplayedImage(display: string) {
    if (display === 'BEFORE') {
      this.displayedImage = 'before';
    } else if (display === 'AFTER') {
      this.displayedImage = 'after';
    }
  }

  showHideFullScreen(mode: string) {
    if (mode === 'show') {
      this.fullScreenModal.nativeElement.style.display = 'block';
    } else {
      this.fullScreenModal.nativeElement.style.display = 'none';
    }
  }

  openPlanogramNewTab() {
    const wi = window.open();
    const planogramHtml = this.planogramDiv.nativeElement.outerHTML;
    wi.document.body.outerHTML = planogramHtml;
  }

  back() {
    this._machineService.restorePreviousSearch = true;
    this._location.back();
  }

}
