import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { NoServiceReviewSearchRequest, NoServiceReviewSearchResponse,
    CSMLineItem, RouteLineItem, NoServiceReviewListItem,
    NoServiceReviewUpdateRequest, SaveResult } from './no-service-review.model';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { SecurityService } from '../../core/security/security.service';
import { CommonService } from '../../core/common-service/common.service';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';

import * as moment from 'moment';

@Injectable()
export class NoServiceReviewService {
  routeList: RouteLineItem[];
  csmList: CSMLineItem[];
  noServiceReviewList: NoServiceReviewListItem[];
  private pageSize: number;
  private pageNum: number;
  noServiceReviewSubject: Subject<NoServiceReviewListItem[]> = new Subject<NoServiceReviewListItem[]>();
  appSettings: any;

  constructor(private _http: HttpClient,
    private _filter: FilterBarService,
    private _security: SecurityService,
    private _common: CommonService,
    private appSettingsProvider: AppsettingsProvider) {
    this.appSettings = this.appSettingsProvider.getSettings();
    this.pageSize = this.appSettings.tableRowLength;
    this.pageNum = 1;
  }

  Initialize() {
    const operationId = this._common.getOperation().operationID;
    this._http.get<NoServiceReviewSearchResponse>(this.appSettings.urlLocations.routesServiceURL + operationId)
      .subscribe((data: NoServiceReviewSearchResponse) => {
        this.routeList = data.routeLineItems;
        this.csmList = data.csmLineItems;
        this._filter.nonFilteredRoutes = this.routeList;
        this._filter.routes.next(this.routeList);
        this._filter.csms.next(this.csmList);
        this._filter.csm.next(this.csmList[0]);

        const request = new NoServiceReviewSearchRequest();

        request.operationID = operationId;
        request.fromDate = moment().subtract(1, 'days').toDate();
        request.toDate = moment().subtract(1, 'days').toDate();
        request.costCenterID = Number(this._common.getOperation().operationNo);

        this._filter.routes.next(this._filter.nonFilteredRoutes.filter((item) => item.csmid == this._filter.csm.value.id));
        request.routeIDList = this._filter.routes.value.map(r => r.id);

        request.pageNumber = this.pageNum;
        request.pageSize = this.pageSize;

        this._http.post<NoServiceReviewSearchResponse>(this.appSettings.urlLocations.routesServiceURL + 'search', request)
          .subscribe((noServiceData: NoServiceReviewSearchResponse) => {
            this.noServiceReviewList = noServiceData.noServiceReviewListItems;
            this.noServiceReviewSubject.next(noServiceData.noServiceReviewListItems.slice());
          });
      });
  }

  Search(searchRequest: NoServiceReviewSearchRequest) {
    this._http.post<NoServiceReviewSearchResponse>(this.appSettings.urlLocations.routesServiceURL + 'search', searchRequest)
      .subscribe((data: NoServiceReviewSearchResponse) => {
        this.noServiceReviewList = data.noServiceReviewListItems;
        this.noServiceReviewSubject.next(data.noServiceReviewListItems.slice());
      });
  }

  Update(updateRequest: NoServiceReviewUpdateRequest): Observable<any> {
    return this._http.put<SaveResult>(this.appSettings.urlLocations.routesServiceURL + 'update', updateRequest);
  }
}
