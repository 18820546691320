import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SecurityService } from '../../core/security/security.service';
import { CommonService } from '../../core/common-service/common.service';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { ReportGroupWrapper,
         Report,
         ReportSubmitRequest,
         ReportSearchResponse,
         GridRequest,
         FilterDataRequest,
         FilterResponse,
         Report_Param_Account_Responses,
         Report_Param_CSM_Responses,
         Report_Param_DateRange_Responses,
         Report_Param_Route_Responses,
         Report_Param_Period_Responses,
         Report_Param_OperationOrState_Responses,
         Report_Param_Quarter_Responses} from './reports.model';

@Injectable()
export class ReportsService {

  appSettings: any;

  constructor(private _http: HttpClient,
    private _security: SecurityService,
    private _common: CommonService,
    private _appSettings: AppsettingsProvider) {
    this.appSettings = this._appSettings.getSettings();
  }

  GetReport(reportExecutionID, userID) {
    return this._http.get(this.appSettings.urlLocations.reportingServiceURL +  reportExecutionID + '/' + userID, {responseType: 'blob'});
  }

  GetReportList() {
    return this._http.get<ReportGroupWrapper>(this.appSettings.urlLocations.reportingServiceURL + 'user/' + this._security.getUserID());
  }

  GetForReport(reportTypeID) {
    return this._http.get<Report>(this.appSettings.urlLocations.reportingServiceURL + reportTypeID + '/user/' + this._security.getUserID());
  }

  GetReportsForView(request: GridRequest) {
    return this._http.post<ReportSearchResponse>(this.appSettings.urlLocations.reportingServiceURL + 'filtergridsearch/', request);
  }

  GetFiltersForView(request: FilterDataRequest) {
    return this._http.post<FilterResponse>(this.appSettings.urlLocations.reportingServiceURL + 'filtersearh/', request);
  }

  SubmitReportRequest(request: ReportSubmitRequest) {
    return this._http.post(this.appSettings.urlLocations.reportingServiceURL + 'report', request);
  }

  ParameterCSM(operationID) {
    return this._http.get<Report_Param_CSM_Responses>(this.appSettings.urlLocations.reportingServiceURL + 'reportparamcsm/' + operationID);
  }

  ParameterAccount(operationID) {
    return this._http.get<Report_Param_Account_Responses>(this.appSettings.urlLocations.reportingServiceURL +
      'reportparamaccount/' + operationID);
  }

  ParameterDateRange(operationID) {
    return this._http.get<Report_Param_DateRange_Responses>(this.appSettings.urlLocations.reportingServiceURL
      + 'reportparamdaterange/' + operationID);
  }

  ParameterRoute(operationID) {
    return this._http.get<Report_Param_Route_Responses>(this.appSettings.urlLocations.reportingServiceURL
      + 'reportparamroute/' + operationID);
  }

  ParameterPeriod() {
    return this._http.get<Report_Param_Period_Responses>(this.appSettings.urlLocations.reportingServiceURL
      + 'reportparamperiod');
  }

  ParameterQuarter() {
    return this._http.get<Report_Param_Quarter_Responses>(this.appSettings.urlLocations.reportingServiceURL
      + 'reportparamquarter');
  }

  ParameterOperationOrState() {
    return this._http.get<Report_Param_OperationOrState_Responses>(this.appSettings.urlLocations.reportingServiceURL
      + 'reportparamoperationorstate');
  }
}
