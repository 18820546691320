import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { SecurityService } from '../../core/security/security.service';
import { CommonService } from '../../core/common-service/common.service';
import { SAPExportFile, SAPExportRequest, SAPExportRequestFile, SAPExportResponse } from './sap-export.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class SapExportService {

  appSettings: any;

  constructor(private _http: HttpClient,
              private _security: SecurityService,
              private appSettingsProvider: AppsettingsProvider,
              private _common: CommonService
              ) {
    this.appSettings = this.appSettingsProvider.getSettings();
  }


  GetDailyExportFiles(): SAPExportFile[] {
    const sapFiles = [];
    sapFiles.push(new SAPExportFile('DM0010'));
    sapFiles.push(new SAPExportFile('DM0020'));
    sapFiles.push(new SAPExportFile('DM0030'));
    sapFiles.push(new SAPExportFile('DM0070'));

    return sapFiles;
  }

  GetWeeklyExportFiles(): SAPExportFile[] {
    const sapFiles = [];
    sapFiles.push(new SAPExportFile('S36S2'));
    sapFiles.push(new SAPExportFile('S36S3'));
    sapFiles.push(new SAPExportFile('S36S4'));
    sapFiles.push(new SAPExportFile('S36S5'));
    sapFiles.push(new SAPExportFile('S36S6'));
    sapFiles.push(new SAPExportFile('DM0140'));
    sapFiles.push(new SAPExportFile('I36UP'));

    return sapFiles;
  }

  IsValidToExport(startDate: Date, endDate: Date, sapFiles: SAPExportFile[]): SAPExportResponse {
    const response = new SAPExportResponse();

    return response;
  }

  RequestDailyExport(startDate: Date, endDate: Date, sapFiles: SAPExportFile[]) {

    // Trim the time from the Date value.
    startDate = moment.utc(startDate).hour(0).minute(0).second(0).millisecond(0).toDate();
    endDate = moment.utc(endDate).hour(0).minute(0).second(0).millisecond(0).toDate();

    console.log(`RequestDailyExport (service)'. Dates:${startDate} to ${endDate}`);
    for (const sapFile of sapFiles) {
      console.log(sapFile.filename, sapFile.selectedDisplay.input);
    }

    console.log(`Call sapexport: ${this.appSettings.urlLocations.fiscalClosingURL}`);
    const request = this.CreateRequest(startDate, endDate, sapFiles);
    request.fileType = 'daily';
    const observable = this._http.post<SAPExportResponse>(this.appSettings.urlLocations.fiscalClosingURL +
      'sapexport', request);
    return observable;
  }

  RequestWeeklyExport(startDate: Date, endDate: Date, sapFiles: SAPExportFile[]) {
    console.log(`RequestWeeklyExport received: startDate:${startDate}, endDate:${endDate}, sapExportFiles:${sapFiles}`);

    // Trim the time from the Date value.
    startDate = moment.utc(startDate).hour(0).minute(0).second(0).millisecond(0).toDate();
    endDate = moment.utc(endDate).hour(0).minute(0).second(0).millisecond(0).toDate();

    console.log(`RequestWeeklyExport (service)'. Dates:${startDate} to ${endDate}`);
    for (const sapFile of sapFiles) {
      console.log(sapFile.filename, sapFile.selectedDisplay.input);
    }

    const request = this.CreateRequest(startDate, endDate, sapFiles);
    request.fileType = 'weekly';
    const observable = this._http.post<SAPExportResponse>(this.appSettings.urlLocations.fiscalClosingURL +
      'sapexport', request);
    return observable;
  }

  // --------------------------------------------------------------------------
  // Private methods

  private CreateRequest(startDate: Date, endDate: Date, sapFiles: SAPExportFile[]): SAPExportRequest {
    const request = new SAPExportRequest();
    request.costCenterID = Number(this._common.operation.operationNo);
    request.startDate = startDate;
    request.endDate = endDate;

    for (const sapFile of sapFiles) {
      if (sapFile.selectedDisplay.input) {
        const fileRequest = new SAPExportRequestFile();
        fileRequest.filename = sapFile.filename;
        request.exportFiles.push(fileRequest);
      }
    }

    console.log('GetRequest', request);

    return request;
  }
}
