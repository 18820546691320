import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuardService } from '../core/auth-guard/auth-guard.service';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RoleGuardService } from '../core/role-guard/role-guard.service';

import { RemotePriceChangeService } from './remote-price-change-service/remote-price-change.service';
import { MaintenanceComponent} from './maintenance/maintenance.component';
import { DexPriceDiscrepanciesComponent} from './dex-price-discrepancies/dex-price-discrepancies.component';
import { RpcStatusComponent } from './rpc-status/rpc-status.component';

const routes: Routes = [
  { path: 'remote-price-change/maintenance',
    component: MaintenanceComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'RPC_MAINTENANCE'} },
  { path: 'remote-price-change/dex-price-discrepancies',
    component: DexPriceDiscrepanciesComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'RPC_DEX_DISCREPANCIES'} },
  { path: 'remote-price-change/rpc-status',
    component: RpcStatusComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'RPC_ROLLOUT_MANUAL_TRACKING'} }
];

@NgModule({
  declarations: [
    MaintenanceComponent,
    DexPriceDiscrepanciesComponent,
    RpcStatusComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CoreModule,
    SharedModule,
    NgbModule,
  ],
  providers: [RemotePriceChangeService]
})
export class RemotePriceChangeModule { }
