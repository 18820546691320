import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class AppsettingsProvider {

  private settings: any = null;

  constructor(private _http: HttpClient) {
  }

  public getSettings(): any {
    return this.settings;
  }

  load() {
    return new Promise((resolve, reject) => {
      this._http
        .get('/assets/appsettings.json')
        .subscribe(response => {
          this.settings = response;
          resolve(true);
        });
    });
  }
}
