import { Component, OnInit } from '@angular/core';
import { MachineServiceService } from '../machine-service-service/machine-service.service';
import { CommonService } from '../../core/common-service/common.service';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';
import { MachineServiceReviewListItem, MachineServiceReviewSearchRequest } from '../machine-service-service/machine-service.model';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { ISubscription } from 'rxjs/Subscription';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { Router } from '@angular/router';



@Component({
  selector: 'app-machine-service-review',
  templateUrl: './machine-service-review.component.html',
  styleUrls: ['./machine-service-review.component.scss']
})
export class MachineServiceReviewComponent implements OnInit {
  
  filter: FilterBarService;
  appSettings: any;
  private mchServiceReviewSubscription: ISubscription;
  sortDirection: string;
  sortColumn: string;
  selectedDateRange: string;
  params = ['date-range', 'routes', 'accounts','machine-search','photosOnly', 'buttons'];
  mchServiceReviewList: MachineServiceReviewListItem[] = new Array();
  machineServiceReviewList: MachineServiceReviewListItem[];
  routeIDList: number[];
  photoExist :  boolean;
  photoDisable: boolean;
  accLocList: number[];
  photoSelect :  boolean;
  



  view = 'list';
  filterPosition = 'in';
  isMobile = false;
  previousPage = 1;
  page = 1;
  pageSize = 0;
  totalNumberOfItems = 0;
  isRefreshingPage = false;
  freshPage = false;
  restorePreviousFilter = false;
  emailBody:string;
 
     
  constructor(
    private _common: CommonService,
    private _machineService: MachineServiceService,
    private _filter: FilterBarService,
    private appSettingsProvider: AppsettingsProvider,
    private sanitizer: DomSanitizer,
    private _router: Router
    ) {
      this.appSettings = this.appSettingsProvider.getSettings();
      this.pageSize = this.appSettings.tableRowLength;
      this._common.setCurrentPage('Machine Service Review');
      this._common.operationGuideSubject.next('Operations Guide - One Vend - Machine Service Review.pdf');
      this.restorePreviousFilter = this._machineService.restorePreviousSearch;
    }

  ngOnInit() {
    this.emailBody = '';
    this.filter = this._filter;
    this._filter.currentPage = 'machine-service-review';
    this._filter.dateRangeLabel = 'Fiscal Date Range';
    this.sortColumn = 'routeNumber';
    this.sortDirection = 'ASC';
    this._filter.dateRangeMin = moment().add(1, 'months').subtract(2, 'years').toDate();
    this._filter.dateRangeMax = moment().add(1, 'months').toDate();
    this._filter.dontPreCheckAllRoutes = this.restorePreviousFilter;
    this._filter.maxWeekDateDifference = true;
    this.accLocList = [];
    

    this.machineServiceReview();
    
    if (this.restorePreviousFilter && this._machineService.previousSearch) {
        this.populatePreviousSearch();
      } else {
       this.resetPage();
    }
  }
  ngDoCheck() {
    //this.photoExist =this._filter.photosOnly.value;
  }
  
   machineServiceReview()
   {
   this.mchServiceReviewSubscription = this._machineService.mchServiceReviewSubject.subscribe((data: MachineServiceReviewListItem[]) => {
    this.mchServiceReviewList = data; 
            console.log("SEARCH REVIEW Resultt::",data);
        this.machineServiceReviewList = new Array();
        // let photoId = this._filter.photosOnly.value;
        
      this.populateMachineReview();                
      this.isRefreshingPage = false;
    });
   }

   
  populateMachineReview() {
    this.mchServiceReviewList.forEach((item) => {
      this.photoExist = true;
      item.machineServiceID = item.machineServiceID;
      item.routeNumber = item.routeNumber;
      item.serviceDateDisplay = moment(item.serviceDate).format('MM/DD/YYYY hh:mm:ss A');
      item.machineNumber = item.machineNumber;
      item.serviceType = item.serviceType;
      item.accountName = item.accountName;
      item.servicerName = item.servicerName;
      item.locationName = item.locationName;
      item.orderUsedDisplay = item.orderUsedInd ? 'Yes' : 'No';
      item.photoRequiredDescription = item.photoRequiredDescription;
      item.overridePhotoReason = item.overridePhotoReason;
      if (item.beforePhotoID == null) {
        item.beforePhotoSrc = '../../../assets/NoImageAvailable.jpg';
      }
      else if (item.beforePhotoID != null) {
        this._machineService.SearchPhotos(item.beforePhotoID).subscribe((data: any) => {
          let TYPED_ARRAY = new Uint8Array(data);
          const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
          let base64String = btoa(STRING_CHAR);
          const cleansedUrl = 'data:image/jpg;base64,' + base64String;
          item.beforePhotoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(cleansedUrl);
        });
      }

      if (item.afterPhotoID == null) {
        item.afterPhotoSrc = '../../../assets/NoImageAvailable.jpg';
      }
      else if (item.afterPhotoID != null) {
        this._machineService.SearchPhotos(item.afterPhotoID).subscribe((data: any) => {
          let TYPED_ARRAY = new Uint8Array(data);
          const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
          let base64String = btoa(STRING_CHAR);
          const cleansedUrl = 'data:image/jpg;base64,' + base64String;
          item.afterPhotoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(cleansedUrl);;
        });
      }
      item.beforePhotoChecked = false;
      item.afterPhotoChecked = false;
      this.machineServiceReviewList.push(item);
    });

    let totalCount = this.mchServiceReviewList == null || this.mchServiceReviewList.length == 0 ? 0 : this.mchServiceReviewList[0].totalCount;
    this.totalNumberOfItems = totalCount;
  }

  changeEmail(serviceReview)
  {
    this.emailBody = "";
     console.log("Email Clicked Value",serviceReview);
    if(serviceReview.beforePhotoID == null)
    {
      this.emailBody = 'Before: Not Available' + "  ";
    }
    else if(serviceReview.beforePhotoID != null)
    {
      this.emailBody += 'Before Available:  ' + this.appSettings.urlLocations.machineServiceURL+"servicePhoto/" + serviceReview.beforePhotoID +"   " + "\n" ;
    }
   if(serviceReview.afterPhotoID == null)
    {
      this.emailBody += 'After: Not Available' +"  ";
    }
    else if(serviceReview.afterPhotoID != null)
    {
      this.emailBody += 'After Available:  ' + this.appSettings.urlLocations.machineServiceURL+"servicePhoto/" + serviceReview.afterPhotoID +"   " + "\n" ;
   }
  
  }

  populatePreviousSearch() {
    this._machineService.restorePreviousSearch = false;
    const previousSearch = this._machineService.previousSearch;
    if (previousSearch != null) {      
      this._filter.machineSearch.next(previousSearch.machineNumber === 0 ? '' : previousSearch.machineNumber.toString());
      this._filter.dateFrom.next(moment(previousSearch.fromDate).toDate());
      this._filter.dateTo.next(moment(previousSearch.toDate).toDate());
      this._filter.routes.next(this._machineService.previousRoutes);
      this.page = previousSearch.pageNumber;
      this.pageSize = previousSearch.pageSize;
      this.search(previousSearch);
    }
  }

  private getRouteArray() {
    this.routeIDList = new Array();
    for (let i = 0; i < this._filter.routes.value.length; i++) {
    if(this._filter.routes.value[i].selected) {  
     this.routeIDList.push(this._filter.routes.value[i].routeID);
     }
    }
    return this.routeIDList;
  }
  private getAccLocationArray() { 
     this.accLocList = new Array();
      if (this._filter.accountLocationList.value != null) {
    for (let i = 0; i < this._filter.accountLocationList.value.length; i++) {
      if(this._filter.accountLocationList.value[i].selected) {
        this.accLocList.push(this._filter.accountLocationList.value[i].id);
      }
    }
  }
    return this.accLocList;
  }
  getDisplayableDateRange() {
    const from = this._filter.dateFrom.value;
    const fromString = (from.getMonth() + 1) + '/' + from.getDate() + '/' + from.getFullYear();
    const to = this._filter.dateTo.value;
    const toString = (to.getMonth() + 1) + '/' + to.getDate() + '/' + to.getFullYear();
    return fromString + ' to ' + toString;
  }

  sort(sortAsc, columnName) {
    this.sortColumn = columnName;
    this.sortDirection = sortAsc ? 'ASC' : 'DESC';
    const request = this.buildSearchRequest(true);    
    this.search(request);
  }

  onApplyFilters(newFilters) {   
    const fromDateParse = moment(this._filter.dateFrom.value);
    const toDateParse = moment(this._filter.dateTo.value);

    if (typeof this._filter.dateFrom.value !== 'object' || fromDateParse == null) {
      this._filter.dateFromError = 'Service From is Required';
      return;
    }
    if (typeof this._filter.dateTo.value !== 'object' || toDateParse == null) {
      this._filter.dateToError = 'Service To is Required';
      return;
    }
    if (fromDateParse > toDateParse) {
      this._filter.dateFromError = 'Service From cannot be greater than Service To';
      this._filter.dateToError = 'Service From cannot be greater than Service To';
      return;
    }
    if (toDateParse > fromDateParse.add(1, 'weeks')) {
      this._filter.dateFromError = 'Service Range cannot exceed a week apart';
      this._filter.dateToError = 'Service Range cannot exceed a week apart';
      return;
    }
    const minimumDate = moment().subtract(2, 'years');
    if (fromDateParse < minimumDate) {
      this._filter.dateFromError = 'Service From cannot be more than 2 years prior';
      return;
    }
    const searchValue = this._filter.machineSearch.value;
    if (searchValue.length > 0 && isNaN(Number(searchValue))) {
      this._filter.machineSearchError = 'Machine search must be a number';
      return;
    }

    this.selectedDateRange = this.getDisplayableDateRange();
    const request = this.buildSearchRequest(newFilters);
    this.search(request);
  }

  getPageInfo() {
    const pn = this.page;
    const ps = this.pageSize;
    const firstPageRecord = (((pn - 1) * ps) + 1);
    return firstPageRecord + ' - ' + ((firstPageRecord - 1) + this.mchServiceReviewList.length) + ' of ' + this.totalNumberOfItems;
  }

  pageChanged() {
    this.onApplyFilters(false);
    window.scrollTo(0, 0);
  }

  onResetFilters(reset) {
    this.resetPage();
  }

  onChangeView(view) {
    this.view = view;
    if (this.isMobile) {
      this.filterPosition = 'out';
    } else {
      this.filterPosition = view === 'grid' ? 'out' : 'in';
    }
  }

  viewDetails(machineId) {
    const machineSvcID = machineId;
    this._router.navigate(['/machine-service/machine-service-details/'+ machineSvcID]);
   }
  
  private buildSearchRequest(newRequest: boolean) {
    let request = new MachineServiceReviewSearchRequest();
    const fromDateParse = moment(this._filter.dateFrom.value);
    const toDateParse = moment(this._filter.dateTo.value);
    request.fromDate = fromDateParse.set('hour', 0).toDate();
    request.toDate = toDateParse.set('hour', 23).toDate();
    request.machineNumber = Number(this._filter.machineSearch.value);
    request.costCenterID = Number(this._common.getOperation().operationNo);
    request.accountLocationList = this.getAccLocationArray();
    this.page = newRequest ? 1 : this.page;
    request.pageNumber = this.page;
    request.pageSize = this.pageSize;
    request.sortColumn = this.sortColumn;
    request.sortDirection = this.sortDirection;
    request.routeIDList = this.getRouteArray();
    request.withPhotosOnlyInd = this._filter.photosOnly.value;
    request.overridePhotoInd = this._filter.photosOverride.value;
    console.log("Override PHOTO IND:",request);
    return request;
  }

  private search(request: MachineServiceReviewSearchRequest) {
    this.freshPage = false;
    this.isRefreshingPage = true;
    this._machineService.previousSearch = request;
    this.saveRouteSelection();
    this._machineService.Search(request);
  }

  private saveRouteSelection() {
    this._machineService.previousRoutes = [];
    if (this._filter.routes.value != null) {
      this._machineService.previousRoutes = this._filter.routes.value.slice();
    }
  }

  private resetPage() {
    this.isRefreshingPage = true;
    this.freshPage = true;
    this._machineService.Initialize();
    const df = moment().subtract(7, 'days').toDate();
    const dt = moment().toDate();
    this._filter.dateFrom.next(df);
    this._filter.dateTo.next(dt);
    this._filter.machineSearch.next('');
    this._filter.accountLocationList.value.length=0;
    this._filter.dateFromError = '';
    this._filter.dateToError = '';
    this._filter.machineSearchError = '';
    this._filter.dontPreCheckAllRoutes = false;
    this._filter.maxWeekDateDifference = true;
    this._filter.photosOnly.next(false);
    this._filter.photosOverride.next(false);
  }
}
