import { Component, OnInit } from '@angular/core';
import { SAPExportFile, SAPExportResponse } from '../../support/sap-export-service/sap-export.model';
import { SapExportService } from '../../support/sap-export-service/sap-export.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from '../../core/common-service/common.service';
import { RoleGuardService } from '../../core/role-guard/role-guard.service';
import { UnAuthorizationReason } from "../../core/auth-claims.model";
import { SecurityService } from "../../core/security/security.service";

@Component({
  selector: 'app-sap-export',
  templateUrl: './sap-export.component.html',
  styleUrls: ['./sap-export.component.scss']
})
export class SapExportComponent implements OnInit {

  loading = false;
  sapDailyExportFiles: SAPExportFile[];
  sapWeeklyExportFiles: SAPExportFile[];
  dailyStartDate: Date;
  dailyEndDate: Date;
  fiscalWeekStartDate: Date;
  fiscalWeekEndDate: Date;
  exportResponseMessage: string;
  exportErrorMessage: string;

  constructor(private _common: CommonService,
    private sapExportService: SapExportService,
    private _roleGuard: RoleGuardService,
    private _security: SecurityService) {
    this._common.setCurrentPage('SAP Export');
    this._common.operationGuideSubject.next('Operations Guide - One Vend - SAP Export.pdf');
  }

  ngOnInit() {
    if (!this._roleGuard.hasOneOfRoles(['EXPORT_SAP_DAILY','EXPORT_SAP_WEEKLY','FSC'])) {
      this._security.redirectToUnauthorized(UnAuthorizationReason.FunctionPermission);
      return;
    }

    this.loading = true;
    this.LoadSAPExportFiles();
    this.dailyStartDate = new Date();
    this.dailyEndDate = new Date();
    this.fiscalWeekStartDate = new Date();
    this.fiscalWeekEndDate = new Date();
    this.loading = false;
  }

  canView(requiredRoles: string[]) {
    return this._roleGuard.hasOneOfRoles(requiredRoles);
  }

  LoadSAPExportFiles() {
    // Daily export files
    this.sapDailyExportFiles = this.sapExportService.GetDailyExportFiles();
    this.sapWeeklyExportFiles = this.sapExportService.GetWeeklyExportFiles();
  }

  RequestDailyExport() {
    console.log('RequestDailyExport(UI)');
    this.clearAlerts();
    this.sapExportService.RequestDailyExport(this.dailyStartDate, this.dailyEndDate, this.sapDailyExportFiles)
      .subscribe((response) => {
        this.DisplayResponseMessage(response);
      },
      (error) => {
        console.log('error', error);
        this.DisplayErrorResponseMessage(error);
      }, () => {
        console.log('RequestDailyExport completed');
      });
  }

  RequestWeeklyExport() {
    console.log('RequestWeeklyExport(UI)', this.fiscalWeekStartDate, this.fiscalWeekEndDate);
    this.clearAlerts();
    this.sapExportService.RequestWeeklyExport(this.fiscalWeekStartDate, this.fiscalWeekEndDate, this.sapWeeklyExportFiles)
    .subscribe((response) => {
      this.DisplayResponseMessage(response);
    }, (error) => {
      this.DisplayErrorResponseMessage(error);
    }, () => {
      console.log('RequestWeeklyExport completed');
    });
  }

  DisplayResponseMessage(response: SAPExportResponse) {
    let displayMessage = '';

    // Add messages
    if (response.messages != null && response.messages.length > 0) {
      for (const message of response.messages) {
        displayMessage = (displayMessage + (displayMessage.length > 0 ? '\r\n' : '')) + message;
      }
      this.exportResponseMessage = displayMessage;
    }

    // Add errors, if any
    if (response.errors != null && response.errors.length > 0) {
      displayMessage = 'Errors: ';
      for (const message of response.errors) {
        displayMessage = (displayMessage + (displayMessage.length > 0 ? '\r\n' : '')) + message;
      }
      this.exportErrorMessage = displayMessage;
    }

    console.log('DisplayResponseMessage', displayMessage);
  }

  DisplayErrorResponseMessage(errorResponse: HttpErrorResponse) {
    let displayMessage = `error.statusText (${errorResponse.status})\r\n`;
    displayMessage = displayMessage + `Message: ${errorResponse.message}\r\n`;
    displayMessage = displayMessage + `Name   : ${errorResponse.name}\r\n\r\n`;

    if (errorResponse.error != null) {
      const response = <SAPExportResponse>errorResponse.error;

      if (response.messages != null) {
        for (const message of response.messages) {
          displayMessage = (displayMessage + `${message}\r\n`);
        }
      }

      if (response.errors != null) {
        for (const message of response.errors) {
          displayMessage = (displayMessage + `${message}\r\n`);
        }
      }
    }

    this.exportErrorMessage = displayMessage;

    console.log('DisplayErrorResponseMessage', displayMessage);
  }

  clearAlerts() {
    this.exportErrorMessage = null;
    this.exportResponseMessage = null;
  }
}
