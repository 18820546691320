import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Report_Param_Period_Responses, Report_Param_Period_Response } from '../../reports-service/reports.model';
import { Switch } from '../../../shared/switch/switch.model';

@Component({
  selector: 'app-rpt-period-entry',
  templateUrl: './rpt-period-entry.component.html',
  styleUrls: ['./rpt-period-entry.component.scss']
})
export class RptPeriodEntryComponent implements OnInit  {

  @Input()
  singlePeriodYear: Number;
  @Output()
  singlePeriodYearEvent = new EventEmitter<Number>();

  @Input()
  singlePeriodPeriod: Number;
  @Output()
  singlePeriodPeriodEvent = new EventEmitter<Number>();

  @Output()
  errorMessage = '';

  constructor() { }

  ngOnInit() {
  }

  updatePeriodYear(periodYear) {
    console.log('periodYear', periodYear);
    this.singlePeriodYearEvent.emit(periodYear);
  }

  updatePeriodPeriod(periodPeriod) {
    console.log('periodPeriod', periodPeriod);
    this.singlePeriodPeriodEvent.emit(periodPeriod);
  }
}
