import { Injectable } from '@angular/core';

import { Operation } from '../operation/operation.model';
import { BehaviorSubject } from 'rxjs';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppsettingsProvider } from '../appsettings.provider';
import * as moment from 'moment';

@Injectable()
export class CommonService {
  currentPage: string;
  private navigationPane = 'in';
  operation: Operation;

  private appSettings: any;

  operationGuideSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  columnToSort: string;
  columnSortAsc: boolean;

  constructor(private _router: Router,
    private _appSettings: AppsettingsProvider,
    private _http: HttpClient) {
    const showNavigationPane = false;
    const currentPage = 'Home';
    this.appSettings = this._appSettings.getSettings();
  }

  /* Current Page */
  getCurrentPage() {
    return this.currentPage;
  }

  setCurrentPage(page: string) {
    this.currentPage = page;
    if (this.appSettings.logToSplunk == 'true') {
      const pageVisitObject = {
        'Page': page,
        'User': localStorage.getItem('username')
      };
      this._http.post(this.appSettings.urlLocations.reportingServiceURL + 'pagevisit', pageVisitObject).subscribe((data) => {
        console.log(data);
      }, (error) => {
        console.log(error);
      });
    }
  }

  /* Navigation Pane */
  getNavigationPane() {
    return this.navigationPane;
  }
  setNavigationPane(state) {
    this.navigationPane = state.toString() === 'in' ? 'in' : 'out';
  }
  showNavigationPane() {
    return this.navigationPane === 'out';
  }
  toggleNavigationPane() {
    this.navigationPane = this.navigationPane === 'out' ? 'in' : 'out';
  }

  /* Operation */
  getOperation(): Operation {
    return this.operation;
  }

  setOperation(operation: Operation, goHome: boolean = false) {
    this.operation = operation;
    localStorage.setItem('operationNo', operation.operationNo);
    if (goHome) {
      this._router.navigate(['/home']);
    }
  }

  /* Helper functions */
  parseDate(date) {
    return date ? new Date(date) : null;
  }

  stringifyDate(date: Date) {
    return (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
  }

  convertDateTimeToString(d: Date) {
    // Date
    const day = ('0' + d.getDate()).slice(-2);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();
    const date = year + '-' + month + '-' + day;
    // Time
    const hour = ('0' + d.getHours()).slice(-2);
    const minutes = ('0' + d.getMinutes()).slice(-2);
    const seconds = ('0' + d.getSeconds()).slice(-2);
    const time = hour + ':' + minutes + ':' + seconds;

    // Combined (don't add Z or it'll default to time zone and conversion won't matter)
    return date + 'T' + time;
  }

  convertDateToString(d: Date) {
    // Date
    const day = ('0' + d.getDate()).slice(-2);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();
    const date = month + '-' + day + '-' + year;

    return date;
  }

  combineDateTimeForServer(d: Date, t: NgbTimeStruct) {
    const year = d.getFullYear();
    let month = (d.getMonth() + 1).toString();
    month = month.length === 1 ? '0' + month : month;
    let day = d.getDate().toString();
    day = day.length === 1 ? '0' + day : day;
    const date = year + '-' + month + '-' + day;
    let hour = t.hour != null && t.hour.toString() !== 'NaN' ? t.hour.toString() : '00';
    hour = hour.length === 1 ? '0' + hour : hour;
    let minute = t.minute != null && t.minute.toString() !== 'NaN' ? t.minute.toString() : '00';
    minute = minute.length === 1 ? '0' + minute : minute;
    let second = t.second != null && t.second.toString() !== 'NaN' ? t.second.toString() : '00';
    second = second.length === 1 ? '0' + second : second;
    const time = hour + ':' + minute + ':' + second;
    return date + 'T' + time;
  }

  dynamicSort(asc, property) {
    let sortOrder = 1;
    if (!asc) {
      sortOrder = -1;
    }
    return function (a, b) {
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }

  dynamicNullableSort(asc, property) {
    let sortOrder = 1;
    if (!asc) {
      sortOrder = -1;
    }
    return function (a, b) {
      const a_value = a[property];
      const b_value = b[property];
      let result = 0;

      if (a_value == null) {
        // a_value is null ...
        if (b_value == null) {
          result = 0; // both null - same as equal
        } else {
          result = 1; // b_value is not null, therefore greater-than a_value
        }
      } else {
        // a_value is not null
        if (b_value == null) {
          result = -1; // a_value is not null, therefore greater than b_value
        } else {
          // Neither a_value nor b_value is null - use the regular sort
          result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        }
      }

      return result * sortOrder;
    };
  }
  
  hasValueString(s: string): boolean {
    return s != null && s !== '';
  }

  getDSTOffset(date: Date): number {
    // var tempDate = date;
    // /*Test DST start */
    // date = moment('3/10/19').toDate();
    // console.log("DST Start:" + date);
    // console.log("day of week:" + date.getDay());
    // console.log("week:"+moment(date).week());
    // /*Test DST End */
    // date = moment('11/3/19').toDate();
    // console.log("DST End:" + date);
    // console.log("day of week:" + date.getDay());
    // console.log("week:"+moment(date).week());
    // date = tempDate;

    // Spring forward second sunday in March, 11th week of year
    // Leap back first sunday of November
    if (moment(date).week() >= 11 && moment(date).week() < 45) {
      return 4;
    } else {
      return 5;
    }
  }


}
