import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Switch } from './switch.model';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input()
  switchInfo: Switch;

  @Output() switchChange = new EventEmitter();

  switchOnClick() {
    this.switchChange.emit(this.switchInfo);
  }


  constructor() { }

  ngOnInit() {
  }


}
