import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';
import { ISubscription } from 'rxjs/Subscription';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-machine-search',
  templateUrl: './machine-search.component.html',
  styleUrls: ['./machine-search.component.scss']
})
export class MachineSearchComponent implements OnInit, OnDestroy {
  @Input() public searchData;
  @ViewChild('searchInput') searchInput: ElementRef;
  machineSearchSubscription: ISubscription;
  label: String;
  machineSearch: String;
  isActive: boolean = false;

  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this.machineSearchSubscription = this._filter.machineSearch.subscribe((data: string) => {
      this.machineSearch = data;
    });
   
    this.label = 'MACHINE NUMBER';
    this.isActive = false;
  }
   ngDoCheck() {
    let search:any =this.machineSearch; 
   if(this.searchData == false)
    {
      //this.machineSearch = '';
     // this.searchInput.nativeElement.value = '';
    }
     else 
     {
       this.updateFilter(search);
    } 
  }

  ngOnDestroy() {
    this.machineSearchSubscription.unsubscribe();
    this._filter.machineSearchError = '';
  }

  showError() {
    return this._filter.machineSearchError;
  }

  updateFilter(newSearch: string) {
    const machineNumber = Number(newSearch);
    if (newSearch.length > 0 && isNaN(machineNumber)) {
      this._filter.machineSearchError = 'Search must be a number';
      return;
    }
    this._filter.machineSearch.next(newSearch);
    this._filter.machineSearchError = '';
  }

}
