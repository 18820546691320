import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Report_Param_Period_Responses, Report_Param_Period_Response } from '../../reports-service/reports.model';
import { Switch } from '../../../shared/switch/switch.model';

@Component({
  selector: 'app-rpt-period',
  templateUrl: './rpt-period.component.html',
  styleUrls: ['./rpt-period.component.scss']
})
export class RptPeriodComponent implements OnInit {

  @Input()
  periods: Report_Param_Period_Response[];

  @Output()
  periodSelected = new EventEmitter();

  selectAll = new Switch('Select All', false, true);
  errorMessage = '';

  constructor() { }

  ngOnInit() {
  }

  flipAllSwitches(event: Switch) {
    this.periods.forEach(x => x.selected.input = !event.input);
    this.noneSelected(this.periods);
    this.periodSelected.emit(this.periods);
  }

  periodOnClick(event: Switch, period: Report_Param_Period_Response) {
    const tempArray = this.periods.filter(x => x.fiscalPeriodID !== period.fiscalPeriodID);
    // Uses bool directly from event to avoid 2 way binding delay
    const eventBool = !event.input;
    this.selectAll.input = tempArray.every(x => x.selected.input === true) &&  eventBool === true;
    this.noneSelected(tempArray, eventBool);
    this.periodSelected.emit(this.periods);
  }

  // Pass extra bool to deal with 2 way binding delay
  noneSelected(inputArray, retrievedBool = null) {
    this.errorMessage = inputArray.every(x => x.selected.input === false) && (retrievedBool == null || retrievedBool === false)
    ? 'Fiscal Period is Required' : '';
  }

}
