import { Component, OnInit, OnDestroy, Directive, Output, EventEmitter } from '@angular/core';
import { UtilitiesService } from '../utilities-service/utilities.service';
import { CommonService } from '../../core/common-service/common.service';
import { ConversionPlanOGram, ConversionPlanOGramDetail, ConversionPlanOGramMachineServicesResponse, ConversionPlanOGramResponse, ConversionProduct } from '../utilities-service/cat-pog-conv.model';
import { Switch } from '../../shared/switch/switch.model';
import {formatDate} from '@angular/common';
import { SecurityService } from '../../core/security/security.service';
import * as FileSaver from 'file-saver';

@Directive({
  selector: '[onCreate]'
})
export class OnCreate {

  @Output() onCreate: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}
  ngOnInit() {      
     this.onCreate.emit('dummy'); 
  } 
}

@Component({
  selector: 'app-cat-pog-conv',
  templateUrl: './cat-pog-conv.component.html',
  styleUrls: ['./cat-pog-conv.component.scss']
})
export class CatPOGConvComponent implements OnInit, OnDestroy {
  constructor(private _common: CommonService,
    private _utility: UtilitiesService,
    private _security: SecurityService) {
    this._common.setCurrentPage('Category POG Conversion');
    this._common.operationGuideSubject.next('Operations Guide - One Vend - Category POG Conversion.pdf');
    this.userID = _security.getUserID();
    this.operationNo = Number.parseInt(_common.operation.operationNo);
  }

  // const defaultValues: {isParIncrease: false, isParDecrease: false, parChangePercentage: 35};
  loading = false;
  isSaving = false;
  isPlanOGramSaved: Boolean = false;
  isFinalServiceSaved: Boolean = false;
  isInitialServiceSaved: Boolean = false;
  isSpotServiceSaved: Boolean = false;
  isAllSaved: Boolean = false;
  showDetails = false;
  errorMessage = '';
  validationErrorMessages = [];
  successMessages = [];
  errorMessages = [];
  method: any = 0;
  popupOpened = false;
  operationNo: Number;
  userID: Number = 0;
  
  switchCreateFromDEX: Switch = new Switch('Create from DEX', false, true);;
  switchModelAfter: Switch = new Switch('Model After another Machine', false, false);;
  switchBrandNewPOG: Switch = new Switch('Brand New POG', false, false);

  selectedMethod: any = null;
  selectedMethodTitle : String = '';


  machineNumber: Number = null;
  sourceMachineNumber : Number = null;
  selectionCount: Number = null;
  effectiveDate: Date;
  planOGram: ConversionPlanOGram = null;
  planOGramResponse: ConversionPlanOGramResponse;
  planOGramDetails: ConversionPlanOGramDetail[] = [];
  categoryProductsMap : Map<String, ConversionProduct[]>; // map of category code to array of products 
  categoryProductsForItem: ConversionProduct[]; // list of category's products while rendering the PlanOGram details

  ngOnInit() {
    this.categoryProductsMap = new Map<String, ConversionProduct[]>();
    this.clearSearch();
  }

  ngOnDestroy() {
    this._common.operationGuideSubject.next(null);
  }

  /***
  getMessageAlertClass(message: Message) {
    let alertClass = 'alert ';
    switch (message.levelCode) {
      case 'INFO':
        alertClass += 'alert-info';
        break;
      case 'WARN':
        alertClass += 'alert-warning';
        break;
      case 'ERROR':
        alertClass += 'alert-danger';
        break;
    }

    return alertClass;
  }
  ***/

  // True iif key is digit
  returnNumericOnly(detail:ConversionPlanOGramDetail, columnName:string,  event) {
    return event.charCode >= 48 && event.charCode <= 57;  
  }

  // True iif key is digit or decimal (.)
  returnNumericOrDecimalOnly(detail:ConversionPlanOGramDetail, columnName:string,  event) {
    return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 /*decimal */);  
  }

  setValue(detail, columnName:string, event) {
    switch(columnName) {
      case 'price':
        let price:number = Number.parseFloat(event.target.value);
        detail.price = price;
        event.target.value = price.toFixed(2);
        break;
    }
  }

  clearSearch() {
    console.log('clearSearch');
    this.method = 0;
    this.selectedMethod = null;
    this.selectedMethodTitle = null;
    this.sourceMachineNumber = null;
    this.machineNumber = null;
    this.sourceMachineNumber = null;
    this.selectionCount = null;
    this.switchCreateFromDEX.input = false; this.toggleSelectedMethod(this.switchCreateFromDEX);this.switchCreateFromDEX.input = true
    const now = new Date();
    this.effectiveDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    this.isAllSaved = false;
    this.isPlanOGramSaved = false;
    this.isFinalServiceSaved = false;
    this.isInitialServiceSaved = false;
    this.isSpotServiceSaved = false;
  
    this.isSaving = false;
    this.clearAllMessages();
  }

  toggleSelectedMethod(event: Switch) {
    console.log('toggleSelectedMethod');

    let switchSelected = !event.input; // opposite of the value that it was changed to
    let otherSwitches = [];

    switch (event.label) {
      case 'Create from DEX':
        otherSwitches = [this.switchModelAfter, this.switchBrandNewPOG]
        this.method = 0;
        break;
      case 'Model After another Machine':
        otherSwitches = [this.switchCreateFromDEX, this.switchBrandNewPOG];
        this.method = 1;
        break;
      case 'Brand New POG':
        otherSwitches = [this.switchCreateFromDEX, this.switchModelAfter];
        this.method = 2;
        break;
      }

      otherSwitches.forEach((x: Switch)=> {
        x.input = !switchSelected;
      });
  }


  showMethod() {
    console.log('showMethod');
    this.loading = true;
    this.planOGram = null;
    this.planOGramDetails = [];
    this.clearErrorAndValidationMessages();

    this._utility.GetConversionPlanOGram(this._common.operation.operationNo, this.machineNumber, this.getMethodCode(this.method), this.effectiveDate, this.sourceMachineNumber, this.selectionCount)
      .subscribe((response: ConversionPlanOGramResponse) => {
        this.loading = false;
        this.planOGramResponse = response;
        this.validationErrorMessages = response.validationMessages;
        this.errorMessages = response.errorMessages;

        if (response.success) {
          this.selectedMethod = this.method;
          this.planOGram = response.planOGram;
          this.planOGram.userID = this.userID;
          this.planOGramDetails = response.planOGramDetails;
          this.planOGram.changeDEXToCashSelection = true;
          this.planOGramDetails.forEach((detail)=> {
            this.onCategorySAPCodeChange(null, detail);
          });
        } else {
          this.selectedMethod = null;
        }
      }, (error) => {
        console.log(error);
        this.loading = false;
        this.errorMessage = "Failure to get PlanOGram. Message:" + error.message;
        this.selectedMethod = null;
        return null;
      });
  }

  getSelectedMethodTitle() : String {
    var formattedDate = formatDate(this.effectiveDate,'MM/dd/yyyy','en-US');
    var suffix = `- ${this.machineNumber} - ${formattedDate}`;

    switch (this.selectedMethod) {
      case 0: return `Create POG from DEX ${suffix}`; 
      case 1: return `Model After another Machine ${suffix}`;
      case 2: return `Brand New POG ${suffix}`;
      default: return 'Unknown';
    }
  }


  getMethodCode(methodCode:any) : string {
    switch (methodCode) {
      case 0: return 'CREATE_FROM_DEX';
      case 1: return 'CREATE_FROM_MODEL_AFTER'; 
      case 2: return 'CREATE_BRAND_NEW_POG';
      default: return 'Unknown'
    }
  }

  isActiveVisible() {
    return this.method != 2;
  }
  isFloatVisible() {
    return true;
  }

  getDetailClass(name: string) {
    let returnValue = '';

    if (this.method == 0) { // CREATE_FROM_DEX
      switch(name) {
        case 'row': 
        case 'column': 
        case 'price':
          returnValue = "[readonly]=true";
        default:
          returnValue = '';
      }
    }

    return returnValue;
  }

  getClassOfPopup(detail:ConversionPlanOGramDetail) {
    if (this.popupOpened) {
      return ''; 
    } else {
      return '';
    }
  }

  goBack(event: any) {
    console.log('goBack');
    this.selectedMethod = null;
    this.clearAllMessages();

    if (this.isAllSaved) {
      this.clearSearch();
    }
  }

  isRefreshing() {
    return false;
  }  

  sortPlanOGramDetails(sortAsc, columnName, boolValue) {
    this.planOGramDetails.sort(this._common.dynamicSort(sortAsc, columnName));
  }

  setProduct(detail : ConversionPlanOGramDetail, product: ConversionProduct, event) {
    detail.productID = product.productID;
    detail.productName = product.name;
    detail.productNameFilter = '';
    detail.filteredProducts = detail.products;
  }

  onProductDropDownOpenChange(openOrClosed: boolean) {
    console.log(`onProductDropDownOpenChange:${openOrClosed}`); 
    this.popupOpened = openOrClosed;  // value is counter-intuitively opposite
  }

  getProductDisplay(detail : ConversionPlanOGramDetail) {
    if (detail.productID == null || detail.productID==-1) {
      if (detail.products == null || detail.products == undefined || detail.products.length == 0) {
        return "Enter valid category code ...";
      } else {
        return "Select product ...";
      }
    } else {
      let products = this.categoryProductsMap.get(detail.categorySAPCode);
      if (products != null && products != undefined) {
        let foundProduct = products.find(product => product.productID == detail.productID);
        return foundProduct == null ? `ProductID:${detail.productID}` : foundProduct.name;
      } else {
        return "Enter valid category code ...";
      }
    }
  }

  filterProducts(detail : ConversionPlanOGramDetail, event) {
    detail.filteredProducts = [];
    let filterValue:string = detail.productNameFilter; //event.target.value;
    let searchString = filterValue.toLowerCase();

    let filteredByName = detail.products.filter(product=>product.name.toLowerCase().includes(searchString) ||
                                                         product.longName.toLowerCase().includes(searchString));
    let filteredBySKU  = detail.products.filter(product=>product.sku.includes(searchString));

    // Get an array of concatenation of the above, including only unique items
    detail.filteredProducts = filteredByName.concat(filteredBySKU.filter(x => filteredByName.every(y => y !== x))); //[1, 2, 3, 4, 5];
  }

  getProductsForDetailCategory(detail : ConversionPlanOGramDetail, onGetProducts) {
    this.errorMessage = '';
    if (this.categoryProductsMap.has(detail.categorySAPCode)) {
      let products = this.categoryProductsMap.get(detail.categorySAPCode);
      detail.products = products;
      if (onGetProducts != null) {
        onGetProducts(detail.products);
      }
    } else {
      this.loading = true;
      this._utility.GetConversionProductsForCategory(this._common.operation.operationNo, this.machineNumber, detail.categorySAPCode)
        .subscribe((products: ConversionProduct[]) => {
          this.loading = false;
          this.categoryProductsMap.set(detail.categorySAPCode, products);
          detail.products = this.categoryProductsMap.get(detail.categorySAPCode);
          if (onGetProducts != null) {
            onGetProducts(detail.products);
          }
        }, (error) => {
          this.loading = false;
          console.log(error);
          this.errorMessage = `Unable to get products for category ${detail.categorySAPCode}`;
        });
    }
  }

  onCategorySAPCodeChange(evt, detail : ConversionPlanOGramDetail) {
    console.log('onCategorySAPCodeChange')

    if (detail.categorySAPCode == null || detail.categorySAPCode == undefined) {
      // Nothing to do
    } else {
      detail.categorySAPCode = detail.categorySAPCode.toString();
      if (detail.categorySAPCode.length == 2 || detail.categorySAPCode.length == 4) {
        this.getProductsForDetailCategory(detail, (products) => {
          if (products == undefined) {
            products = [];
          }
    
          if (products.length == 0) {
            this.errorMessage = `No products returned for category ${detail.categorySAPCode}`;
          } else {
            products.sort((a,b) => {
              if (a.name < b.name) return -1;
              else if (a.name > b.name) return 1;
              else return 0;
            });
            detail.products = products;
            detail.filteredProducts = products;
      
            // Look for the current product in the list of products.  If not found, set current product to null.
            let findProduct = detail.products.find(x=>x.productID == detail.productID);
            if (findProduct == null) {
              detail.productID = -1;
            }
          }
        });
      }
    }
  }

  formatValue(detail: ConversionPlanOGramDetail, columnName:string, event) {
    switch(columnName) {
      case "price":
        event.target.value = parseFloat(event.target.value).toFixed(2);
        break;
      case "selectionName":
        detail.selectionName = detail.selectionName.toUpperCase();
        break;
    }
    //let formattedAmount = this.currencyPipe.transform(detail.price);
    //element.target.value = formattedAmount;
  } 

  isEntryDisabled(detail:ConversionPlanOGramDetail, name:string) {
    let disabled:Boolean = !detail.activeInd

    if (!disabled) {
      switch(name) {
        case 'par':
          disabled = detail.floatInd;
          break;
        case 'productID':
          disabled = detail.products==null || detail.products.length==0;
          break;
      }
    }
    return disabled;
  }
  
  onModelChange(detail: ConversionPlanOGramDetail, columnName:string, event) {
    switch (columnName) {
      case "float":
        if (detail.floatInd) {
          detail.par = null;
          detail.parDisabled = true;
        }
        else {
          detail.parDisabled = false;
        }
        break;
    }
  }

  exportPlanOGram() {
    let pog = this.planOGram;
    let details = this.planOGramDetails;
    let data = '';
    let formattedEffectiveDate = formatDate(pog.effectiveDate,'MM/dd/yyyy','en-US');

    data += ('OperationNo,MachineNumber,EffectiveDate,MethodCode,UserID,ChangeDEXToSelection\r\n');
    data += (`${pog.operationNo},${pog.machineNumber},${formattedEffectiveDate},${pog.methodCode},${pog.userID},${pog.changeDEXToCashSelection}`);
    data += '\r\n\r\n'

    data += ('Row,Column,Selection,CategoryCode,ProductID,ProductName,Price,Par,Capacity,FloatInd\r\n')
    details.forEach(detail => {
      let priceFixed = detail.price == null ? "" : detail.price.toFixed(2);
      let parDisplay = detail.par == null ? "" : `${detail.par}`;
      data += (`${detail.row},${detail.column},${detail.selectionName},${detail.categorySAPCode},${detail.productID},${detail.productName},${priceFixed},${parDisplay},${detail.capacity},${detail.floatInd}\r\n`);
    }); 

    const blob = new Blob([data], { type: 'application/vnd.ms-excel' });

    let formattedFilenameDate = formatDate(pog.effectiveDate,'yyyy-MM-dd','en-US');
    FileSaver.saveAs(blob, `${pog.operationNo}.${pog.machineNumber}.${formattedFilenameDate}` + '.csv');    
  }

  savePlanOGramAndCreateServices(event: any) {
    console.log('savePlanOGramAndCreateServices');

    this.clearErrorAndValidationMessages();

    if (this.isPlanOGramSaved) {
      this.saveMachineServices();
    } else {
      this.isSaving = true;
      this._utility.SavePlanOGram(this.planOGram, this.planOGramDetails)
        .subscribe((response: ConversionPlanOGramResponse) => {
          if (response.success) {
            console.log('savePlanOGram Successful');
            this.isPlanOGramSaved = true;
            if (response.successMessages != null && response.successMessages.length > 0) {
              this.successMessages = response.successMessages;
            }
            this.saveMachineServices();
          } else {
            this.isSaving = false;
            if (response.errorMessages != null && response.errorMessages.length > 0 ||
                response.validationMessages != null && response.validationMessages.length > 0) {
              this.errorMessages = response.errorMessages || [];
              this.validationErrorMessages = response.validationMessages || [];
              window.scroll(0,0);            
            } 
          }
          window.scroll(0,0);
        }, (error) => {
          console.log(error);
          this.isSaving = false;
          window.scroll(0,0);            
          this.errorMessage = `Failed to save PlanOGram. ${error.statusText} ${error.message}`;
        });
    }
  }

  saveMachineServices() {
    let me = this;
    this.errorMessages = [];
    this._utility.SaveMachineServices(this.operationNo, this.machineNumber, this.effectiveDate, this.userID, !this.isFinalServiceSaved, !this.isInitialServiceSaved, !this.isSpotServiceSaved)
    .subscribe((response: ConversionPlanOGramMachineServicesResponse) => {

      me.isFinalServiceSaved = me.isFinalServiceSaved || (response.isSaveFinalServiceSuccess != null && response.isSaveFinalServiceSuccess);
      me.isInitialServiceSaved = me.isInitialServiceSaved || (response.isSaveInitialServiceSuccess != null && response.isSaveInitialServiceSuccess);
      me.isSpotServiceSaved = me.isSpotServiceSaved || (response.isSaveSpotServiceSuccess != null && response.isSaveSpotServiceSuccess);

      if (response.successMessages != null && response.successMessages.length > 0) {
        me.successMessages.push(response.successMessages);
      }

      if (response.errorMessages != null && response.errorMessages.length > 0) {
        me.errorMessages = response.errorMessages;
        window.scroll(0,0);            
      } 

      me.isAllSaved = response.success;
      me.isSaving = false;
      window.scroll(0,0);
    }, (error) => {
      console.log(error);
      window.scroll(0,0);            
      this.errorMessage = `Failed to save PlanOGram. ${error.statusText} ${error.message}`;
      this.isSaving = false;
    });

  }

  isSaveButtonDisabled() {
    return this.isSaving || this.isAllSaved;
  }
  
  isGoBackButtonDisabled() {
    return this.isSaving;
  }

  clearErrorAndValidationMessages() {
    this.errorMessage = '';
    this.errorMessages = [];
    this.validationErrorMessages = [];
  }

  clearAllMessages() {
    this.errorMessage = '';
    this.errorMessages = [];
    this.successMessages = [];
    this.validationErrorMessages = [];
  }
}
