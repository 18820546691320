export class ConversionPlanOGramResponse {
    planOGram : ConversionPlanOGram;
    planOGramDetails : ConversionPlanOGramDetail[];
    success: Boolean;
    validationMessages: String[];
    errorMessages: String[];
    successMessages: String[];
}

export class ConversionPlanOGramRequest {
    planOGram : ConversionPlanOGram;
    planOGramDetails : ConversionPlanOGramDetail[];
}

export class ConversionPlanOGram {
    operationNo : Number;
    machineNumber : Number;
    effectiveDate : Date;
    modelAfterMachineNumber : Number;
    methodCode : String;
    userID : Number;
    changeDEXToCashSelection : Boolean;
}

export class ConversionPlanOGramDetail {
    row : Number;
    column : Number;
    selectionName : String;
    categorySAPCode : String;
    productID : Number;
    productName : String;
    price : Number;
    par : Number;
    capacity : Number;
    activeInd: Boolean;
    floatInd: Boolean;
    products : ConversionProduct[];
    filteredProducts : ConversionProduct[];
    productNameFilter: string;
    parDisabled: Boolean;
}

export class ConversionCategory {
    sapCode : String;
    name : String;
}

export class ConversionProduct {
    productID : Number;
    sku: String;
    name : String;
    longName : String;
}

export class ConversionPlanOGramMachineServicesRequest {
    operationNo: Number;
    machineNumber: Number;
    effectiveDate: Date;
    userID: Number;
    isSaveFinalService: Boolean;
    isSaveInitialService: Boolean;
    isSaveSpotService: Boolean;
}

export class ConversionPlanOGramMachineServicesResponse {
    success: Boolean;
    isSaveFinalServiceSuccess: Boolean;
    isSaveInitialServiceSuccess: Boolean;
    isSaveSpotServiceSuccess: Boolean;

    errorMessages: String[];
    successMessages: String[];
}
