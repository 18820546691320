import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from './datepicker-formatter';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { NgbDateNativeAdapter } from './datepicker-adapter';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { ReportsModule } from './reports/reports.module';
import { SmartServiceModule } from './smart-service/smart-service.module';

import { AuthGuardService } from './core/auth-guard/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { AppsettingsProvider } from './core/appsettings.provider';
import { DeviceModule } from './device/device.module';
import { UtilitiesModule } from './utilities/utilities.module';
import { RefreshInterceptorService } from './core/security/refresh-interceptor.service';
import { OperationsModule } from './operations/operations.module';
import { MachineServiceModule } from './machine-service/machine-service.module';
import { SupportModule } from './support/support.module';
import { RemotePriceChangeModule } from './remote-price-change/remote-price-change.module';
import { DatePipe } from '@angular/common';
import { TokenComponent } from './token/token.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LogoutComponent } from './logout/logout.component';

export function appsettingsProviderFactory(provider: AppsettingsProvider) {
  return () => provider.load();
}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

const jwtConfig = {
  config: {
    tokenGetter: tokenGetter,
    whitelistedDomains: [new RegExp('localhost'),
      'test-aws-onevendservices.cg-canteen.com',
      'staging-aws-onevendservices.cg-canteen.com',
      'staging-onevendservices.allinonesys.com',
      'onevend.allinonesys.com',
      'onevend.canteen.com',
      'onevendservices.allinonesys.com',
      'onevendservices.canteen.com'],
    blacklistedRoutes: ['http-inputs-cpgna.splunkcloud.com']
  }
};

const routes: Routes = [
  { path: '', redirectTo: '/token', pathMatch: 'full' },
  { path: 'token', component: TokenComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService], runGuardsAndResolvers: 'always'},
  { path: '', component: AppComponent },
  { path: 'unauthorized', component: UnauthorizedComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TokenComponent,
    UnauthorizedComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload'}),
    HttpClientModule,
    JwtModule.forRoot(jwtConfig),
    CoreModule,
    SharedModule,
    RoutesModule,
    ReportsModule,
    SmartServiceModule,
    DeviceModule,
    MachineServiceModule,
    UtilitiesModule,
    OperationsModule,
    SupportModule,
    RemotePriceChangeModule,
    FormsModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    NgxChartsModule
  ],
  providers: [
    AppsettingsProvider,
    { provide: APP_INITIALIZER, useFactory: appsettingsProviderFactory, deps: [AppsettingsProvider], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshInterceptorService, multi: true },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    DatePipe
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
