import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppsettingsProvider } from '../../core/appsettings.provider';
import { SecurityService } from '../../core/security/security.service';
import { WarehouseInvConsolidationInformation } from './warehouse-consolidation.model';
import { POGAdjustmentMachineInfo,
         PlanOGramAdjustmentRequest,
         PlanOGramAdjustmentRequestMachinePOG,
         PlanOGramAdjustmentResponse } from './pog-adjustment.model';
import { ConversionCategory, ConversionPlanOGram, ConversionPlanOGramDetail, ConversionPlanOGramMachineServicesRequest, ConversionPlanOGramMachineServicesResponse, ConversionPlanOGramRequest, ConversionPlanOGramResponse, ConversionProduct } from './cat-pog-conv.model';

@Injectable()
export class UtilitiesService {

  appSettings: any;

  constructor(private _http: HttpClient,
    private _security: SecurityService,
    private appSettingsProvider: AppsettingsProvider) {
    this.appSettings = this.appSettingsProvider.getSettings();
  }

  // --------------------------------------------------------------------------

  ConsolidateWarehouse(physicalInventoryId, costCenterID) {
    return this._http.put<any>(this.appSettings.urlLocations.warehouseURL +
      'consolidate/' + physicalInventoryId + '/' + costCenterID, {});
  }

  GetWarehouseInfo(operationNo: number) {
    return this._http.get<WarehouseInvConsolidationInformation[]>(this.appSettings.urlLocations.warehouseURL +
      'inventory/ ' + operationNo);
  }

  // --------------------------------------------------------------------------

  GetPOGAdjustmentMachinePOGInfosForAccountIDs(operationNo: string, accountIDs: string) {
    return this._http.get<POGAdjustmentMachineInfo[]>(this.appSettings.urlLocations.machineServiceURL +
      'machinePOGInfos/' + operationNo + '/' + accountIDs);
  }

  PostPOGAdjustmentMachinePOGs(machinePOGInfos: POGAdjustmentMachineInfo[], operationNo: string,
                               methodCode: string, percentageChange: number, parMinimumValue: number, effectiveDate: Date) {
    const request = new PlanOGramAdjustmentRequest();
    request.operationNo = parseInt(operationNo, 10);
    request.minimumPar = parMinimumValue;
    request.percentageChange = percentageChange;
    request.methodCode = methodCode;
    request.userID = this._security.getUserID();
    request.effectiveDate = effectiveDate;
    request.machinePOGs = [];

    for (let i = 0; i < machinePOGInfos.length; i++) {
      const machinePOGInfo = machinePOGInfos[i];
      const machinePOGRequest = new PlanOGramAdjustmentRequestMachinePOG();
      machinePOGRequest.machineID = machinePOGInfo.machineID;
      machinePOGRequest.machineNumber = machinePOGInfo.machineNumber;
      machinePOGRequest.planOGramID   = machinePOGInfo.planOGramID;
      request.machinePOGs.push(machinePOGRequest);
    }

    return this._http.post<PlanOGramAdjustmentResponse>(this.appSettings.urlLocations.machineServiceURL +
      'generatePOGForMachines', request);
  }

  // --------------------------------------------------------------------------

  GetConversionPlanOGram(operationNo: string, machineNumber: Number, methodCode: string, effectiveDate: Date, modelAfterMachineNumber: Number, selectionCount: Number) {
    //?OperationNo={operationNo}&MachineNumber={machineNumber}&MethodCode={methodCode&EffectiveDate={effectiveDate}&ModelAfterMachineNumber={modelAfterMachineNumber}}
    return this._http.get<ConversionPlanOGramResponse>(this.appSettings.urlLocations.machineServiceURL +
      `CategoryPOGConversion/PlanOGram?OperationNo=${operationNo}&MachineNumber=${machineNumber}&MethodCode=${methodCode}&EffectiveDate=${effectiveDate.toLocaleString()}&ModelAfterMachineNumber=${modelAfterMachineNumber}&SelectionCount=${selectionCount}`);
  }

  GetConversionCategories(operationNo : string, machineNumber: Number) {
    return this._http.get<ConversionCategory[]>(this.appSettings.urlLocations.machineServiceURL +
      `CategoryPOGConversion/ProductCategory?OperationNo=${operationNo}&MachineNumber=${machineNumber}`);
  }

  GetConversionProductsForCategory(operationNo : string, machineNumber: Number, categoryCode : String) {
    return this._http.get<ConversionProduct[]>(this.appSettings.urlLocations.machineServiceURL +
      `CategoryPOGConversion/ProductsForCategory?OperationNo=${operationNo}&MachineNumber=${machineNumber}&SAPCode=${categoryCode}`);
  }

  SavePlanOGram(planOGram: ConversionPlanOGram, planOGramDetails: ConversionPlanOGramDetail[]) {
    let request = new ConversionPlanOGramRequest();
    request.planOGram = planOGram;
    request.planOGramDetails = planOGramDetails;
    return this._http.post<ConversionPlanOGramRequest>(this.appSettings.urlLocations.machineServiceURL +
      `CategoryPOGConversion/PlanOGram`, request);
  }

  SaveMachineServices(operationNo:Number, machineNumber:Number, effectiveDate:Date, userID:Number, isSaveFinalService:Boolean, isSaveInitialService:Boolean, isSaveSpotService:Boolean) {
    let request = new ConversionPlanOGramMachineServicesRequest();
    request.operationNo = operationNo;
    request.machineNumber = machineNumber;
    request.effectiveDate = effectiveDate;
    request.userID = userID;
    request.isSaveFinalService = isSaveFinalService;
    request.isSaveInitialService = isSaveInitialService;
    request.isSaveSpotService = isSaveSpotService;
    return this._http.post<ConversionPlanOGramMachineServicesResponse>(this.appSettings.urlLocations.machineServiceURL +
      `CategoryPOGConversion/MachineServices`, request);
  }
}
