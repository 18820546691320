import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {AppsettingsProvider} from '../core/appsettings.provider';
import {CommonService} from '../core/common-service/common.service';
import {SecurityService} from '../core/security/security.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  issueMessage = 'You have successfully logged out!';
  noteMessage = 'Note: To login again, please';
  navigateUrl = '/';
  status = '';
  message = '';
  appSettings: any;

  constructor(
      private _router: Router,
      private _common: CommonService,
      private _activatedRoute: ActivatedRoute,
      private _securityService: SecurityService,
      private appSettingsProvider: AppsettingsProvider) {
    this.appSettings = this.appSettingsProvider.getSettings();
    this._common.setCurrentPage('Logout');
  }

  ngOnInit() {
    if (this._securityService.isLoggedIn()) {
      this._router.navigate(['home']);
      return;
    }

    this._activatedRoute.queryParams.subscribe(params => {
      this.status = params['status'];
      this.message = params['message'];
    });

    if (this.status === '404' || this.status === '200') {
      return;
    }

    this.issueMessage = this.message;
    this.noteMessage = 'Note: You have not been logged out from the OMS, to do that ';
    this.navigateUrl = `${this.appSettings.omsSettings.omsBaseUrl}logout`;
  }
}
