import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  numAlerts: number;

  constructor() { }

  ngOnInit() {
    this.numAlerts = 0;
  }

}
