import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DEXFreq } from '../../reports-service/reports.model';

@Component({
  selector: 'app-rpt-vendor-dex-freq',
  templateUrl: './rpt-vendor-dex-freq.component.html',
  styleUrls: ['./rpt-vendor-dex-freq.component.scss']
})
export class RptVendorDexFreqComponent implements OnInit {

  @Input()
  vendorDEXFreqs: DEXFreq[];

  @Input()
  submitted: boolean = false;

  @Output()
  onSetDEXFreq = new EventEmitter<DEXFreq>();

  displayDex = "Select";

  constructor() { }

  ngOnInit() {

  }

  selectDex(dex: DEXFreq) {
    this.onSetDEXFreq.emit(dex);
    this.displayDex = dex.name;
  }

  getError() {
    if (this.submitted && this.displayDex == "Select")
      return "This field is required.";
    else
      return "";
  }
}
