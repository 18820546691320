import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeviceModifyInfoReponse, DeviceStatus, DeviceModifyInfo } from '../../device/device-service/device-management.model';
import { DeviceManagementService } from '../../device/device-service/device-management.service';
import { Operation } from '../../core/operation/operation.model';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export class MockDeviceModifyInfoResponse {
  deviceModifyRecord: DeviceModifyInfo;
  statusList: DeviceStatus[];
  disposalReasonList: DeviceStatus[];

  constructor() {
    this.statusList = [{ iVendLookupID: 1, description: 'nothing'}];
    this.disposalReasonList = [{ iVendLookupID: 2, description: 'everything'}]
  }
}

export class MockDeviceModifyInfo implements DeviceModifyInfo {
  viDeviceID: number;
  viDeviceModelID: number;
  operationGoLiveDate: Date;
  deviceSerialNo: string;
  initialCurrentStartDate: Date;
  initialCurrentEndDate: string | Date;
  currentStartDate: Date;
  currentEndDate: string | Date;
  totalNotMapped: number;
  latestNotMappedDateTime: string | Date;
  suggestedStartDateTime: Date;
  latestTransactionMapped: Date;
  earliestNotMappedDateTime: Date;
  mostRecentDeviceStartDateTime: Date;
  lastFullFinalServiceDateTime: Date;
  vendorCode: string;
  deviceStatus: string;
  reason: string;
  deviceNo: string;
  twoTierDeviceIndicator: Date;
  lastVendorDexDateTimeIndicator: Date;
  acquiringOperation: number;
  acquiringOperationName: string;
  costCenterAcquireDate: Date;
  costCenterLatest: number;
  costCenterNameLatest: string;
  costCenterCurrent: number;
  costCenterNameCurrent: string;
  accountCurrent: string;
  locationCurrent: string;
  machineCurrent: number;
  routeNumberCurrent: number;
  latestTransactionCurrent: string | Date;
  lastFullFinalServiceDateTimeCurrent: Date;
  deviceStartDateCurrent: string | Date;
  deviceEndDateCurrent: Date;
  deviceNotesCurrent: string;
  costCenterPrior: number;
  costCenterNamePrior: string;
  accountPrior: string;
  locationPrior: string;
  machinePrior: number;
  routeNumberPrior: number;
  latestTransactionPrior: string | Date;
  lastFullFinalServiceDateTimePrior: Date;
  deviceStartDatePrior: string | Date;
  deviceEndDatePrior: Date;
  deviceNotesPrior: string;
  disposalReason: number;
  routeMachineDescCurrent: string;
  routeMachineDescPrior: string;
  modifiedByUser: string;
  modifiedDateTime: string | Date;
  viSalesTxnInd: boolean;
  viDeviceEventInd: boolean;
}

export class MockOperation {
  constructor() {
    this.allow = true;
    this.applicationID = 11111;
    this.name = 'Minneapolis';
    this.operationID = 44444;
    this.operationNo = '44444';
    this.orgLevelDisplay = '';
    this.isOnOneWarehouse = false;
    this.isOnSeed = false;
  }

  allow: boolean;
  applicationID: number;
  name: string;
  operationID: number;
  operationNo: string;
  orgLevelDisplay: string;
  isOnOneWarehouse: boolean;
  isOnSeed: boolean;
}

export class MockCommonService {
  constructor() {}

  operationGuideSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  operation: Operation;

  getCurrentPage() {}
  setCurrentPage() {}
  getNavigationPane() {}
  setNavigationPane() {}
  showNavigationPane() {}
  toggleNavigationPane() {}
  getOperation() {}
  setOperation() {}
  parseDate() {}
  stringifyDate() {}
  convertDateTimeToString() {}
  convertDateToString() {}
  combineDateTimeForServer() {}
  dynamicSort() {}
  hasValueString() {}
  getDSTOffset() {}
}

export class MockSecurityService {
  constructor() {}

  getUserID(): number {
    return 12345;
  }

  getUsername(): string {
    return 'Mock User';
  }

  loggedIn(): boolean {
    return true;
  }
}

export class MockDeviceManagementService {
  appSettings: any;
  deviceInfo: BehaviorSubject<DeviceModifyInfoReponse> = new BehaviorSubject<DeviceModifyInfoReponse>(null);
  serviceError: BehaviorSubject<string> = new BehaviorSubject<string>('');

  GetDeviceInfo() {
  }

  ModifyDevice() {
  }
}

export class MockModal {
  open() {}
}

export class MockAppSettingsProvider {
  constructor() {}

  getSettings() {
    return {
      logToSplunk: false,
      urlLocations: {
        deviceManagementURL: 'http://localhost:60005/'
      }
    };
  }
}

@Component({
  selector: 'app-mock',
  templateUrl: './mock.component.html',
  styleUrls: ['./mock.component.scss']
})

export class MockComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  getDeviceModifyInfoDummy() {
    const deviceModifyInfo = new MockDeviceModifyInfo();
    deviceModifyInfo.accountCurrent = 'MyAccount';
    deviceModifyInfo.accountPrior = 'An old prior account';
    deviceModifyInfo.acquiringOperation = 33333;
    deviceModifyInfo.acquiringOperationName = 'New Orleans';
    deviceModifyInfo.costCenterAcquireDate = null;
    deviceModifyInfo.costCenterCurrent = 44444;
    deviceModifyInfo.costCenterLatest = 13333;
    deviceModifyInfo.costCenterNameCurrent = 'Phoenix';
    deviceModifyInfo.costCenterNameLatest = 'Boston';
    deviceModifyInfo.costCenterNamePrior = 'Las Vegas';
    deviceModifyInfo.costCenterPrior = 40003;
    deviceModifyInfo.currentEndDate = null;
    deviceModifyInfo.currentStartDate = null;
    deviceModifyInfo.operationGoLiveDate = null;
    deviceModifyInfo.deviceEndDateCurrent = null;
    deviceModifyInfo.deviceEndDatePrior = null;
    deviceModifyInfo.deviceStartDatePrior = null;
    deviceModifyInfo.deviceNo = '7890';
    deviceModifyInfo.deviceNotesCurrent = 'no notes';
    deviceModifyInfo.deviceNotesPrior = 'no notes';
    deviceModifyInfo.deviceSerialNo = 'abcdefg';
    deviceModifyInfo.deviceStartDateCurrent = 'December 31, 2009';
    deviceModifyInfo.deviceStatus = 'active';
    deviceModifyInfo.disposalReason = 44;
    deviceModifyInfo.earliestNotMappedDateTime = null;
    deviceModifyInfo.initialCurrentStartDate = null;
    deviceModifyInfo.initialCurrentEndDate = null;
    deviceModifyInfo.lastFullFinalServiceDateTime = null;
    deviceModifyInfo.lastFullFinalServiceDateTimeCurrent = null;
    deviceModifyInfo.lastFullFinalServiceDateTimePrior = null;
    deviceModifyInfo.lastVendorDexDateTimeIndicator = null;
    deviceModifyInfo.latestNotMappedDateTime = null;
    deviceModifyInfo.latestTransactionCurrent = null;
    deviceModifyInfo.latestTransactionMapped = null;
    deviceModifyInfo.latestTransactionPrior = null;
    deviceModifyInfo.locationCurrent = 'The Hamptons';
    deviceModifyInfo.locationPrior = 'The suburbs of DC';
    deviceModifyInfo.machineCurrent = 445566;
    deviceModifyInfo.machinePrior = 99999;
    deviceModifyInfo.modifiedByUser = 'Canteen User';
    deviceModifyInfo.modifiedDateTime = null;
    deviceModifyInfo.mostRecentDeviceStartDateTime = null;
    deviceModifyInfo.reason = 'reason';
    deviceModifyInfo.routeMachineDescCurrent = 'Fantastic Machine';
    deviceModifyInfo.routeMachineDescPrior = 'Old Used up Machine';
    deviceModifyInfo.routeNumberCurrent = 499449;
    deviceModifyInfo.routeNumberPrior = 474747;
    deviceModifyInfo.suggestedStartDateTime = null;
    deviceModifyInfo.totalNotMapped = 12345;
    deviceModifyInfo.twoTierDeviceIndicator = null;
    deviceModifyInfo.vendorCode = 'vendor#1';
  return deviceModifyInfo;
  }
}
