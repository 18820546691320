import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthToken, UnAuthorizationReason} from '../core/auth-claims.model';
import {SecurityService} from '../core/security/security.service';

@Component({
  selector: 'app-token',
  template: '',
})
export class TokenComponent implements OnInit {
  omsCode = null;
  appSettings: any;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _securityService: SecurityService
  ) {
  }

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe((params) => {
      this.omsCode = params['code'];
    });

    if (!this.omsCode) {
      this._router.navigate(['home']);
      return;
    }

    this._securityService.login(this.omsCode).subscribe((AuthResult: AuthToken) => {
      if (this._securityService.isLoggedIn()) {
          this._router.navigate(['home']);
      } else {
        this._securityService.redirectToUnauthorized(UnAuthorizationReason.ServiceError);
      }
    }, () => {
      this._securityService.redirectToUnauthorized(UnAuthorizationReason.ServiceError);
    });
  }

}
