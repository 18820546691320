import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../core/common-service/common.service';
import { DeviceManagementService } from '../device-service/device-management.service';
import { DeviceAssignInfo, DeviceAssignPersist, DeviceAssignValidation } from '../device-service/device-management.model';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { SecurityService } from '../../core/security/security.service';

@Component({
  selector: 'app-assign',
  templateUrl: './assign.component.html',
  styleUrls: ['./assign.component.scss']
})

export class AssignComponent implements OnInit {
  deviceNumber = '';
  machineNumber = '';
  assignInfo: DeviceAssignInfo;
  errorMessage = '';
  machineExists = false;
  searching = false;
  deviceAlreadyInstalledError = false;
  deviceBeingReassignedToSameMachine = false;

  // Variables to use for time
  installTime: NgbTimeStruct;

  @ViewChild('notSuggested') notSuggested;
  notSuggestedMessage: string;
  modalOptions: NgbModalOptions = { backdrop: 'static', keyboard: false, centered: true };

  @ViewChild('basic') basic;
  basicModalOptions: NgbModalOptions = { centered: true };
  modalType = '';
  modalMessage = '';

  constructor(private _modal: NgbModal,
    private _security: SecurityService,
    private _common: CommonService,
    private _device: DeviceManagementService,
    private _route: ActivatedRoute) {
      this._common.setCurrentPage('Assign Device');
      this._common.operationGuideSubject.next('Operations Guide - One Vend - Device Management.pdf');
  }

  ngOnInit() {
    const deviceNumber = this._route.snapshot.params['deviceNumber'];
    if (deviceNumber != null) {
      this.deviceNumber = deviceNumber;
    }
  }

  installDateIsUndefinedOrNull(date: Date): boolean {
    if (date === undefined || date === null) {
      this.errorMessage = 'Please enter a valid install date.';
      return true;
    }
    return false;
  }

  installDateIsAValidMomentDate(date: Date): boolean {
    const result = moment(date);
    if (!result.isValid()) {
      this.errorMessage = 'Invalid install date, please fix';
      return true;
    }
    return false;
  }

  installDateIsBefore2000(date: Date): boolean {
    if (date < new Date('01/01/2000')) {
      this.errorMessage = 'Install date is invalid.';
      return true;
    }
    return false;
  }

  installDateIsNotInTheFuture(date: Date): boolean {
    if (date > new Date()) {
      this.errorMessage = 'Install date cannot be in the future.';
      return true;
    }
    return false;
  }

  installDateBeforeLatestTransactionDate(serverInstallDate: string, latestTransactionDate: Date): boolean {
    if (this.dateIsPrior(serverInstallDate, latestTransactionDate)) {
      this.errorMessage = 'Install date cannot be less than the latest transaction date.';
      return true;
    }
    return false;
  }

  installDateBeforeRecommendedDate(serverInstallDate: string, recommendedDate: Date): boolean {
    if (this.deviceBeingReassignedToSameMachine === false && this.dateIsPrior(serverInstallDate, recommendedDate)) {
      this.errorMessage = 'Install date cannot be less than the recommended date.';
      return true;
    }
    return false;
  }

  installDateBeforeOperationLive(serverInstallDate: string, operationLiveDate: Date): boolean {
    if (this.dateIsPrior(serverInstallDate, operationLiveDate)) {
      this.errorMessage = 'Install Date must be after the Operation go live date, ' + moment(operationLiveDate).format('MM/DD/YYYY');
      return true;
    }
    return false;
  }

  installDateBeforeWarehouseDate(serverInstallDate: string, warehouseDate: Date): boolean {
    if (this.dateIsPrior(serverInstallDate, warehouseDate)) {
        this.errorMessage = 'Install date cannot be less than current non-warehouse start date.';
        return true;
    }
    return false;
  }

  checkIfDeviceReassignedToPreviousMachine() {
    if (this.assignInfo.deviceMachineId === this.assignInfo.targetMachineId) {
      this.deviceBeingReassignedToSameMachine = true;
      this.assignInfo.installDate = null;
      this.assignInfo.deviceMachineLastFullServiceDate = this.assignInfo.targetMachineLastFullServiceDate;
      return true;
    }
    return false;
  }

  dateIsPrior(firstDate: string, secondDate: Date) {
    return moment(firstDate).isBefore(secondDate);
  }

  deviceDatesAreValid(): boolean {
    this.errorMessage = '';

    const date = this.assignInfo.installDate;
    if (this.installDateIsUndefinedOrNull(date) ||
        this.installDateIsAValidMomentDate(date)) {
      return false;
    }

    const installDate = moment(
      this._common.combineDateTimeForServer(moment(this.assignInfo.installDate).toDate(),
      this.installTime)).toDate();
    const serverInstallDate = this._common.combineDateTimeForServer(moment(this.assignInfo.installDate).toDate(),
      this.installTime);

    if (this.installDateIsBefore2000(installDate) ||
        this.installDateIsNotInTheFuture(installDate) ||
        this.installDateBeforeLatestTransactionDate(serverInstallDate, this.assignInfo.deviceLastTransactionAsCollections) ||
        (this.assignInfo.deviceInstalled != null &&
          this.installDateBeforeWarehouseDate(serverInstallDate, this.assignInfo.lastInstallDateNotWarehouse)) ||
        this.installDateBeforeRecommendedDate(serverInstallDate, this.assignInfo.deviceRecommendedInstallDate) ||
        this.installDateBeforeOperationLive(serverInstallDate, this.assignInfo.systemOfRecordDate)) {
        return false;
    }

    return true;
  }

  recommendedInstallDateIsDifferentThanSuggestedInstallDate() {
    const isSame = moment(this.assignInfo.installDate).isSame(this.assignInfo.deviceRecommendedInstallDate, 'day');

    if (!isSame && this.assignInfo.deviceRecommendedInstallDate !== null && this.deviceBeingReassignedToSameMachine !== true) {
      this.notSuggestedMessage = 'You have selected a date different from the suggested date. Do you still wish to proceed?';
      this._modal.open(this.notSuggested, this.modalOptions).result.then((modalResult) => {
        const result = modalResult.toString();
        if (result === 'CONTINUE' ){
          this.assigningDeviceInDisposal();
        } else {
          return;
        }
      });
    } else {
      this.assigningDeviceInDisposal();
    }
  }

  assignDevice() {
    if (this.deviceDatesAreValid()) {
      this.recommendedInstallDateIsDifferentThanSuggestedInstallDate();
    }
  }

  persistDevice() {
    const assign = new DeviceAssignPersist();
    assign.deviceSerialNo = this.assignInfo.deviceSerialNo;
    assign.newStartDate = this.deviceBeingReassignedToSameMachine ? this.assignInfo.deviceInstalled
                          : this._common.combineDateTimeForServer(moment(this.assignInfo.installDate).toDate(), this.installTime);
    assign.machineId = this.assignInfo.targetMachineId;
    assign.userId = this._security.getUserID();
    assign.notes = this.assignInfo.deviceNotes;
    assign.recomendedStartDate =
      this.assignInfo.deviceRecommendedInstallDate == null ? null :
        this._common.convertDateTimeToString(this._common.parseDate(this.assignInfo.deviceRecommendedInstallDate));
    assign.costCenter = this.assignInfo.deviceCostCenterId;
    this.searching = true;
    this._device.AssignDevice(assign).subscribe((response) => {
      if (response == true) {
        this.modalType = 'success';
        this.modalMessage = 'Your changes have been saved.';
        this._modal.open(this.basic);
        this.searchClear();
      } else {
        this.modalType = 'error';
        this.modalMessage = 'An error has occured. Please contact VIS.';
        this._modal.open(this.basic);
      }
    }, (error) => {
      console.log(error);
    });
  }

  searchClear() {
    this.errorMessage = '';
    this.assignInfo = null;
    this.machineNumber = '';
    this.deviceNumber = '';
    this.deviceBeingReassignedToSameMachine = false;
    this.searching = false;
  }

  search() {
    this.errorMessage = '';
    this.searching = true;
    this.assignInfo = null;
    this.deviceBeingReassignedToSameMachine = false;

    const dn = this._common.hasValueString(this.deviceNumber);
    const mn = this._common.hasValueString(this.machineNumber);
    if (!dn && !mn) {
      this.errorMessage = 'Device and Target Machine Numbers are required.';
      this.searching = false;
      return;
    } else if (!dn) {
      this.errorMessage = 'Device Number is required.';
      this.searching = false;
      return;
    } else if (!mn) {
      this.errorMessage = 'Target Machine Number is required.';
      this.searching = false;
      return;
    }

    this.deviceNumber = this.deviceNumber.trim();
    this.machineNumber = this.machineNumber.trim();

    this._device.GetAssignCostCenter(this.deviceNumber).subscribe((deviceCostCenter) => {
      this._device.GetAssignInfo(this.deviceNumber,
        Number(this.machineNumber),
        Number(this._common.operation.operationNo)).subscribe((data) => {
          this._checkAssignInfo(data, deviceCostCenter);

        }, (error) => {
          console.log(error);
          this.searching = false;
          if (error.status.toString() === '418') {
            this.errorMessage = 'A record was not able to be found for the provided device and machine.';
          } else {
            this.errorMessage = 'An unhandled error has occurred.';
          }
        });
    }, (error) => {
      console.log(error);
      this.searching = false;
      if (error.status.toString() === '418') {
        this.errorMessage = 'A record was not able to be found for the provided device.';
      } else {
        this.errorMessage = 'An unhandled error has occured.';
      }
    });
  }

  assigningDeviceInDisposal(){
    if (this.assignInfo.deviceStatus === 'Disposal') {
      this.notSuggestedMessage = 'Device status is Disposal.  Are you sure you want to assign to a machine?';
      this._modal.open(this.notSuggested, this.modalOptions).result.then((result) => {
        if (result.toString() === 'CONTINUE') {
          this.persistDevice();
        } else {
          return;
        }
      });
    } else {
      this.persistDevice();
    }
  }

  private _checkAssignInfo(data: DeviceAssignInfo, deviceCostCenter: DeviceAssignValidation) {

    if (data.machineError === 1) {
      this.errorMessage += 'Machine does not exist.<br />';
    } else {
      this.machineExists = true;
    }

    if (data.machineError === 2) {
      this.errorMessage += 'Invalid Machine Number.<br />';
    }

    if (data.deviceError === 1) {
      this.errorMessage += 'Device does not exist.<br />';
    }

    if (data.deviceError === 2) {
      this.errorMessage += 'Device belongs to a different cost center ' +
        '<a target="_blank" href="https://mycompassdocuments.compass-usa.com/Documents/Forms/AllItems.aspx' +
        '#InplviewHash5568f076-880a-4d35-adac-db4bda94fd6d=' +
        'FilterField1%3DDocument%255Fx0020%255FCategory-FilterValue1%3D7660-FilterOp1%3DIn-FilterLookupId1%3D1-FilterData1' +
        '%3D0%252C746f3da5%252Dfa66%252D4319%252D8a9d%252D15db8342701f">' +
        deviceCostCenter.deviceCostCenterName + '(' +
        deviceCostCenter.deviceCostCenterId + ')</a><br />';
    }

    if (data.targetMachineStatus != null && data.targetMachineStatus !== 'A') {
      this.errorMessage += 'Machine must be active.<br />';
    }

    if (data.targetMachineFloorStatus === 'D') {
      this.errorMessage += 'Destruction of selected Machine is pending.<br />';
    }

    if (data.targetMachineFloorStatus === 'S') {
      this.errorMessage += 'Sale of selected Machine is pending.<br />';
    }

    if (data.targetMachineServicedByType === 'A') {
      this.errorMessage += 'Machine must be serviced by the vending branch.<br />';
    }

    if (data.targetMachineRevenueGeneratingInd === 0 && this.machineExists) {
      this.errorMessage += 'Machine type must be for a Dry, Wet, or GM Machine.<br />';
    }

    if (data.deviceMachineNumber === this.machineNumber && data.deviceEndDate == null && data.deviceStatus === 'Installed') {
      this.errorMessage = 'Device is already installed on Machine. Please see the Modify Device screen for details.<br />';
    }

    if (this.errorMessage !== '') {
      this.searching = false;
      return;
    }

    data.installDate = moment(data.deviceRecommendedInstallDate).toDate();

    const currInstall = moment(data.deviceRecommendedInstallDate);
    this.installTime = { 'hour': currInstall.hour(), 'minute': currInstall.minute(), 'second': currInstall.second() };

    this.assignInfo = data;
    this.searching = false;
    this.checkIfDeviceReassignedToPreviousMachine();
  }
}
