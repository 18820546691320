import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes, Params } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

import { ListComponent } from './list/list.component';
import { AuthGuardService } from '../core/auth-guard/auth-guard.service';
import { ReportsService } from './reports-service/reports.service';
import { CreateComponent } from './create/create.component';
import { ViewComponent } from './view/view.component';

import { RptDateRangeComponent } from './report-parameters/rpt-date-range/rpt-date-range.component';
import { RptRouteComponent } from './report-parameters/rpt-route/rpt-route.component';
import { RptVendorDexFreqComponent } from './report-parameters/rpt-vendor-dex-freq/rpt-vendor-dex-freq.component';
import { RptSkuToleranceComponent } from './report-parameters/rpt-sku-tolerance/rpt-sku-tolerance.component';
import { RptCsmComponent } from './report-parameters/rpt-csm/rpt-csm.component';
import { RptPeriodComponent } from './report-parameters/rpt-period/rpt-period.component';
import { RptPeriodEntryComponent } from './report-parameters/rpt-period-entry/rpt-period-entry.component';
import { RptQuarterComponent } from './report-parameters/rpt-quarter/rpt-quarter.component';
import { RptOperationOrStateComponent } from './report-parameters/rpt-operation-or-state/rpt-operation-or-state.component';
import { RptAccountComponent} from './report-parameters/rpt-account/rpt-account.component';
import { RptSingleDateComponent } from './report-parameters/rpt-single-date/rpt-single-date.component';

const routes: Routes = [
  { path: 'reports/create/:reportTypeID', component: CreateComponent, canActivate: [AuthGuardService] },
  { path: 'reports/list', component: ListComponent, canActivate: [AuthGuardService] },
  { path: 'reports/view', component: ViewComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CoreModule,
    SharedModule,
    NgbModule
  ],
  providers: [ReportsService],
  declarations: [
    ListComponent,
    ViewComponent,
    CreateComponent,
    RptDateRangeComponent,
    RptSingleDateComponent,
    RptRouteComponent,
    RptVendorDexFreqComponent,
    RptSkuToleranceComponent,
    RptCsmComponent,
    RptAccountComponent,
    RptPeriodComponent,
    RptPeriodEntryComponent,
    RptQuarterComponent,
    RptOperationOrStateComponent
  ]
})
export class ReportsModule { }
