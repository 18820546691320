export class NoServiceReviewSearchRequest {
  fromDate: Date | string;
  toDate: Date | string;
  operationID: number;
  costCenterID: number;
  routeIDList: number[];
  pageNumber: number;
  pageSize: number;
  sort_Column: string;
  sort_Direction: string;
}

export interface NoServiceReviewSearchResponse {
  csmLineItems: CSMLineItem[];
  routeLineItems: RouteLineItem[];
  noServiceReviewListItems: NoServiceReviewListItem[];
}

export interface CSMLineItem {
  displayName: string;
  id: number;
  number: number;
  selected: boolean;
  userID: number;
}

export interface RouteLineItem {
  displayName: string;
  id: number;
  number: number;
  selected: boolean;
  csmid: number;
  WarehouseID: number;
}

export interface NoServiceReviewListItem {
  routeNumber: string;
  machineServiceID: number;
  businessDate: Date | string;
  serviceDate: Date | string;
  managementExceptionInd: number;
  atFaultInd: boolean;
  atFaultOriginalInd: boolean;
  statusDisplayText: string;
  noServiceReason: string;
  scheduledType: string;
  machineNumber: number;
  machineID: number;
  dispenseType: string;
  account: string;
  location: string;
  totalCount: number;
  modifiedInd: boolean;
  canBeModifiedInd: boolean;
}

export interface NoServiceReviewUpdateRequest {
  userID: number;
  atFaultMachineServiceIDList: number[];
  notAtFaultMachineServiceIDList: number[];
}

export interface SaveResult {
    successInd: boolean;
    message: string;
    confirmationNumber: string;
}
