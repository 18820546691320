import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ISubscription } from 'rxjs/Subscription';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit, OnDestroy {
  fromDate: Date;
  toDate: Date;
  label: string;
  limitWeekDifference: boolean;
  
  minDate: any;
  maxDate: any;

  fromSubscription: ISubscription;
  toSubscription: ISubscription;

  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this.minDate = null;
    this.maxDate = null;
    this.fromSubscription = this._filter.dateFrom.subscribe((data: Date) => {
      this.fromDate = data;
    });
    this.toSubscription = this._filter.dateTo.subscribe((data: Date) => {
      this.toDate = data;
    });
    this.label = this._filter.dateRangeLabel;
    this.limitWeekDifference = this._filter.maxWeekDateDifference;

    const currentDate = new Date();
    const filteredMinDate = this._filter.dateRangeMin;
    const filteredMaxDate = this._filter.dateRangeMax;

    this.minDate = filteredMinDate == null ?
      {year: 2015, 
      month: 1, 
      day: 1} 
        : 
      {year: filteredMinDate.getFullYear(), 
      month: filteredMinDate.getMonth(), 
      day: filteredMinDate.getDate()};
    
    this.maxDate = filteredMaxDate == null ?
      {year: currentDate.getFullYear() + 2, 
      month: 12, 
      day: 31} 
        :
      {year: filteredMaxDate.getFullYear(), 
      month: filteredMaxDate.getMonth(), 
      day: filteredMaxDate.getDate()};
  }

  ngOnDestroy() {
    this.fromSubscription.unsubscribe();
    this.toSubscription.unsubscribe();
    this._filter.dateRangeMin = null;
    this._filter.dateRangeMax = null;
    this._filter.maxWeekDateDifference = false;
  }

  showFromError() {
    return this._filter.dateFromError;
  }

  showToError() {
    return this._filter.dateToError;
  }

  toggleDatePickers(element, other) {
    element.toggle();
    other.close();
  }

  update() {    
    this._filter.dateFrom.next(this.fromDate);
    this._filter.dateTo.next(this.toDate);
    
    if (!moment(this.fromDate).isValid()) {
      this._filter.dateFromError = 'Date From is Required';
      return;
    } 
    if (!moment(this.toDate).isValid()) {
      this._filter.dateToError = 'Date To is Required';
      return;
    }
    if (this.fromDate > this.toDate) {
      this._filter.dateFromError = 'From cannot be after To';
      this._filter.dateToError = 'From cannot be after To';
      return;
    }
    if (this.limitWeekDifference && moment(this.toDate) > moment(this.fromDate).add(1, 'weeks')) {
      this._filter.dateFromError = 'Date Range cannot exceed a week apart';
      this._filter.dateToError = 'Date Range cannot exceed a week apart';
      return;
    } 

    this._filter.dateFromError = '';
    this._filter.dateToError = '';
  }

/*   update(date, isFrom: boolean) {
    const result = Date.parse(date); // If not valid date, will return NaN    
    if (typeof date !== 'object' || date == null || isNaN(result)) {
      if (isFrom) {
        this._filter.dateFromError = 'Date From is Required';
      } else {
        this._filter.dateToError = 'Date To is Required';
      }
      return;
    }
    // check that from date is not after to date
    if (isFrom) {
      if (date > this.toDate) {
        this._filter.dateFromError = 'From cannot be after To';
        return;
      } else if (this.limitWeekDifference && moment(this.toDate).subtract(7, 'days').isAfter(moment(date))) {
        this._filter.dateToError = 'Date Range cannot exceed a week apart';
        return;        
      }
    } else {
      if (this.fromDate > date) {
        this._filter.dateToError = 'From cannot be after To';
        return;
      } else if (this.limitWeekDifference && moment(date).isAfter(moment(this.fromDate).add(7, 'days'))) {
        this._filter.dateToError = 'Date Range cannot exceed a week apart';
        return;
      }
    }

    if (isFrom) {
      this._filter.dateFrom.next(date);
    } else {
      this._filter.dateTo.next(date);
    }
    this._filter.dateFromError = '';
    this._filter.dateToError = '';
  }*/

}
