import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClosingDetailComponent } from './closing/closing-detail/closing-detail.component';
import { ClosingSummaryComponent } from './closing/closing-summary/closing-summary.component';

import { AuthGuardService } from '../core/auth-guard/auth-guard.service';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { RoleGuardService } from '../core/role-guard/role-guard.service';
import { OperationsService } from './operation-service/operations.service';
import { ClosingConfigsComponent } from './closing/closing-configs/closing-configs.component';

const routes: Routes = [
    { path: 'operations/fiscal-closing',
    component: ClosingSummaryComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'VIEW_FISCAL_WEEK_CLOSED'} },
    { path: 'operations/fiscal-closing/:fiscalCalendarID',
    component: ClosingDetailComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'VIEW_FISCAL_WEEK_CLOSED'} },
    { path: 'operations/closing-configs',
    component: ClosingConfigsComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'TECHNICAL_SUPPORT_MENU_ACCESS'} }
];

@NgModule({
  declarations: [
    ClosingDetailComponent,
    ClosingSummaryComponent,
    ClosingConfigsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CoreModule,
    SharedModule,
    NgbModule
  ],
  providers: [
    OperationsService
  ]
})
export class OperationsModule { }
