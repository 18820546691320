import { Component, OnInit, OnDestroy } from '@angular/core';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';
import { ISubscription } from 'rxjs/Subscription';
import { OperationLineItem } from '../../../smart-service/smart-service-service/smart-service.model';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit, OnDestroy {
  
  operations: any[];
  operationSubscription: ISubscription;
   
  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this.operationSubscription = this._filter.operations.subscribe((data: OperationLineItem[]) => {
      this.operations = data;
    });
  }

  ngOnDestroy() {
    this.operationSubscription.unsubscribe();
  }

  showOperationsError(){
    return this._filter.operationError;
  }
}
