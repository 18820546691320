import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from '../../core/common-service/common.service';
import { RoleGuardService } from '../../core/role-guard/role-guard.service';
import { SupportService } from '../support-service/support.service';
import { MdmInstallBaseShareRequest, MdmInstallBaseShareResponse } from '../support-service/support.model';

@Component({
  selector: 'app-mdm-install-base-share',
  templateUrl: './mdm-install-base-share.component.html',
  styleUrls: ['./mdm-install-base-share.component.scss']
})
export class MdmInstallBaseShareComponent implements OnInit {

  loading = false;
  processResponseMessage: string;
  processErrorMessage: string;
  oldMachineNumber: number;
  newMachineNumber: number;

  constructor(private _common: CommonService,
    private supportService: SupportService) {
    this._common.setCurrentPage('MDM Install Base Share');
    //this._common.operationGuideSubject.next('Operations Guide - One Vend - SAP Export.pdf');
  }

  ngOnInit() {
    this.loading = true;
    this.loading = false;
  }

  assign() {
    console.log('RequestDailyExport(UI)');
    this.clearAlerts();

    /***
    let fakeResponse = new MdmInstallBaseShareResponse();
    fakeResponse.errors = ["Fake Fake Fake"];
    fakeResponse.messages = ["Hello, ha ha ha"];
    this.DisplayResponseMessage(fakeResponse);
    ***/

    var request = new MdmInstallBaseShareRequest();
    request.oldMachineNumber = this.oldMachineNumber;
    request.newMachineNumber = this.newMachineNumber;
    this.supportService.RequestMdmInstallBaseShare(request)
      .subscribe(
        (response) => {
            this.DisplayResponseMessage(response);
        },
        (error) => {
          console.log('RequestMdmInstallBaseShare Error', error);
          this.DisplayErrorResponseMessage(error);
        }, () => {
          console.log('RequestMdmInstallBaseShare completed');
      });
  }

  clear() {

  }

  DisplayResponseMessage(response: MdmInstallBaseShareResponse) {
    let displayMessage = '';

    if (response == null) {
      this.processErrorMessage = "Response was received as OK, but was empty";
    } else {
      // Add messages
      if (response.messages != null && response.messages.length > 0) {
        for (const message of response.messages) {
          displayMessage = (displayMessage + (displayMessage.length > 0 ? '\r\n' : '')) + message;
        }
        this.processResponseMessage = displayMessage;
      }

      // Add errors, if any
      if (response.errors != null && response.errors.length > 0) {
        displayMessage = 'Errors: ';
        for (const message of response.errors) {
          displayMessage = (displayMessage + (displayMessage.length > 0 ? '\r\n' : '')) + message;
        }
        this.processErrorMessage = displayMessage;
      }

      console.log('DisplayResponseMessage', displayMessage);

    }
  }

  DisplayErrorResponseMessage(errorResponse: HttpErrorResponse) {
    let displayMessage = `error.statusText (${errorResponse.status})\r\n`;
    displayMessage = displayMessage + `Message: ${errorResponse.message}\r\n`;
    displayMessage = displayMessage + `Name   : ${errorResponse.name}\r\n\r\n`;

    if (errorResponse.error != null) {
      const response = <MdmInstallBaseShareResponse>errorResponse.error;

      if (response.messages != null) {
        for (const message of response.messages) {
          displayMessage = (displayMessage + `${message}\r\n`);
        }
      }

      if (response.errors != null) {
        for (const message of response.errors) {
          displayMessage = (displayMessage + `${message}\r\n`);
        }
      }
    }

    this.processErrorMessage = displayMessage;

    console.log('DisplayErrorResponseMessage', displayMessage);
  }

  clearAlerts() {
    this.processErrorMessage = null;
    this.processResponseMessage = null;
  }
}
