import { Component, OnInit } from '@angular/core';
import { CashlessDeviceInventoryExcelExportItem, CashlessDeviceInventoryItem, CashlessDeviceInventoryItemUpdate } from '../device-service/device-management.model';
import { DeviceManagementService } from '../device-service/device-management.service';
import { ISubscription } from 'rxjs-compat/Subscription';
import { CommonService } from '../../core/common-service/common.service';
import { SecurityService } from '../../core/security/security.service';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../core/excel-service/excel.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-device-inventory',
  templateUrl: './device-inventory.component.html',
  styleUrls: ['./device-inventory.component.scss']
})
export class DeviceInventoryComponent implements OnInit {

  constructor(private _common: CommonService,
    private _security: SecurityService,
    private _device: DeviceManagementService,
    private _datePipe: DatePipe,
    private _excel: ExcelService,) 
  {
    this._common.setCurrentPage('Cashless Device Inventory');
  }

  deviceInventoryTableItems: CashlessDeviceInventoryItem[];
  deviceInventoryTableItemSubject: ISubscription;
  tableIsDirty: boolean;
  isRefreshingPage: boolean;
  costCenterID: number;
  userID: number;

  ngOnInit() {
    this.tableIsDirty = false;
    this.deviceInventoryTableItems = new Array();
    this.costCenterID = Number(this._common.operation.operationNo);
    this.setUpSubjects();
    this.userID = this._security.getUserID();
  }

  private setUpSubjects() {
    this.isRefreshingPage = true;
    this.deviceInventoryTableItemSubject = this._device.GetCashlessDeviceInventoryItems(this.costCenterID).subscribe((data: CashlessDeviceInventoryItem[]) => {
      console.log("SEARCH REVIEW Result::",data);
      this.deviceInventoryTableItems = data;   
      this.isRefreshingPage = false;
    });
  }

  saveButton() {
    let verificationsToSend = new CashlessDeviceInventoryItemUpdate();
    verificationsToSend.viDeviceIdList = this.prepareUpdates();
    verificationsToSend.userId = this.userID;
    this._device.UpdateCashlessDeviceInventoryItem(verificationsToSend).subscribe(() => {
      this.deviceInventoryTableItems = [];
      this.isRefreshingPage = true;
      window.location.reload();
    })
    
  }

  private prepareUpdates() {
    let result: string = '';
    if (this.deviceInventoryTableItems.length === 0)
      return result;

    this.deviceInventoryTableItems.forEach(x => {
      if (x.selected) {
        result = result + x.viDeviceId + ',';
      }
    });
    return result.slice(0,result.length - 1);
  }

  sortOpenOperationStatuses(sortAsc, columnName) {
    if (sortAsc) {
      switch (columnName) {
        case 'deviceNo': this.deviceInventoryTableItems.sort((a, b) => { return (a.deviceNo > b.deviceNo) ? 1 : (a.deviceNo < b.deviceNo) ? -1 : 0; }); break;
        case 'vendorCode': this.deviceInventoryTableItems.sort((a, b) => { return (a.vendorCode > b.vendorCode) ? 1 : (a.vendorCode < b.vendorCode) ? -1 : 0; }); break;
        case 'inventoryStatus': this.deviceInventoryTableItems.sort((a, b) => { return (a.inventoryStatus > b.inventoryStatus) ? 1 : (a.inventoryStatus < b.inventoryStatus) ? -1 : 0; }); break;
        case 'addedToInventory': this.deviceInventoryTableItems.sort((a, b) => { return (a.addedToInventory > b.addedToInventory) ? 1 : (a.addedToInventory < b.addedToInventory) ? -1 : 0; }); break;
        case 'name': this.deviceInventoryTableItems.sort((a, b) => { return (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0; }); break;
        case 'daysInInventory': this.deviceInventoryTableItems.sort((a, b) => { return (a.daysInInventory > b.daysInInventory) ? 1 : (a.daysInInventory < b.daysInInventory) ? -1 : 0; }); break;
        case 'lastVerificationDateTime': this.deviceInventoryTableItems.sort((a, b) => { 
          if (a.lastVerificationDateTime === null)
            return -1;
          if (b.lastVerificationDateTime === null)
            return 1;
          return (a.lastVerificationDateTime > b.lastVerificationDateTime) ? 1 : (a.lastVerificationDateTime < b.lastVerificationDateTime) ? -1 : 0;
          }); break;
        case 'lastVerifiedByName': this.deviceInventoryTableItems.sort((a, b) => { 
          if (a.lastVerifiedByName === null || a.lastVerifiedByName.length === 0)
            return -1;
          if (b.lastVerifiedByName === null || b.lastVerifiedByName.length === 0)
            return 1;
          return (a.lastVerifiedByName > b.lastVerifiedByName) ? 1 : (a.lastVerifiedByName < b.lastVerifiedByName) ? -1 : 0;
          }); break; 
        case 'daysSinceLastVerification': this.deviceInventoryTableItems.sort((a, b) => { return (a.daysSinceLastVerification > b.daysSinceLastVerification) ? 1 : (a.daysSinceLastVerification < b.daysSinceLastVerification) ? -1 : 0; }); break;
      }     
    } else {
      switch (columnName) {
        case 'deviceNo': this.deviceInventoryTableItems.sort((a, b) => { return (a.deviceNo < b.deviceNo) ? 1 : (a.deviceNo > b.deviceNo) ? -1 : 0; }); break;
        case 'vendorCode': this.deviceInventoryTableItems.sort((a, b) => { return (a.vendorCode < b.vendorCode) ? 1 : (a.vendorCode > b.vendorCode) ? -1 : 0; }); break;
        case 'inventoryStatus': this.deviceInventoryTableItems.sort((a, b) => { return (a.inventoryStatus < b.inventoryStatus) ? 1 : (a.inventoryStatus > b.inventoryStatus) ? -1 : 0; }); break;
        case 'addedToInventory': this.deviceInventoryTableItems.sort((a, b) => { return (a.addedToInventory < b.addedToInventory) ? 1 : (a.addedToInventory > b.addedToInventory) ? -1 : 0; }); break;
        case 'name': this.deviceInventoryTableItems.sort((a, b) => { return (a.name < b.name) ? 1 : (a.name > b.name) ? -1 : 0; }); break;
        case 'daysInInventory': this.deviceInventoryTableItems.sort((a, b) => { return (a.daysInInventory < b.daysInInventory) ? 1 : (a.daysInInventory > b.daysInInventory) ? -1 : 0; }); break;
        case 'lastVerificationDateTime': this.deviceInventoryTableItems.sort((a, b) => { 
          if (a.lastVerificationDateTime === null)
            return 1;
          if (b.lastVerificationDateTime === null)
            return -1;
          return (a.lastVerificationDateTime < b.lastVerificationDateTime) ? 1 : (a.lastVerificationDateTime > b.lastVerificationDateTime) ? -1 : 0; 
          }); break;
        case 'lastVerifiedByName': this.deviceInventoryTableItems.sort((a, b) => { 
          if (a.lastVerifiedByName === null || a.lastVerifiedByName.length === 0)
            return 1;
          if (b.lastVerifiedByName === null || b.lastVerifiedByName.length === 0)
            return -1;
          return (a.lastVerifiedByName < b.lastVerifiedByName) ? 1 : (a.lastVerifiedByName > b.lastVerifiedByName) ? -1 : 0;
          }); break;
        case 'daysSinceLastVerification': this.deviceInventoryTableItems.sort((a, b) => { return (a.daysSinceLastVerification < b.daysSinceLastVerification) ? 1 : (a.daysSinceLastVerification > b.daysSinceLastVerification) ? -1 : 0; }); break;
      }
    }
  }

  exportToExcel() {
    if (this.deviceInventoryTableItems && this.deviceInventoryTableItems.length > 0) {
      const dateFormat = "YYYYMMDD";
      let _now: Moment = moment(new Date());
      const fileName = 'Cashless_Device_Inventory' + '_' + _now.format(dateFormat);

      item : CashlessDeviceInventoryItem;
      let exportItems = [];
      this.deviceInventoryTableItems.forEach((item) => { 
        let exportItem = new CashlessDeviceInventoryExcelExportItem()
        exportItem.CostCenter = item.currentCostCenterId;
        exportItem.DeviceNo = item.deviceNo;
        exportItem.VendorCode = item.vendorCode;
        exportItem.InventoryStatus = item.inventoryStatus;
        exportItem.AddedToInventoryOn = item.addedToInventory == null ? "" : moment(item.addedToInventory).format('MM/DD/YYYY hh:mm A');
        exportItem.ModifiedBy = item.name;
        exportItem.DaysInInventory = item.daysInInventory;
        exportItem.LastVerifiedOn = item.lastVerificationDateTime == null ? "" : moment(item.lastVerificationDateTime).format('MM/DD/YYYY hh:mm A');
        exportItem.LastVerifiedBy = item.lastVerifiedByName;
        exportItem.DaysSinceVerification = item.daysSinceLastVerification;
        exportItems.push(exportItem);
      });

/***
***/

      this._excel.exportAsExcelFile(exportItems, fileName);
    }
  }
}
