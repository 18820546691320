import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-column-header',
  templateUrl: './column-header.component.html',
  styleUrls: ['./column-header.component.scss']
})

export class ColumnHeaderComponent implements OnInit {

  @Input()
  columnName: string;

  @Input()
  sortCarets = true;

  @Output()
  onSort = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    console.log(this.onSort);
  }

  sort(sortAsc) {
    this.onSort.emit(sortAsc);
  }

}
