import { Component, Input, Output, OnInit, EventEmitter, ElementRef, ViewChild, Renderer2, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FilterBarService } from '../filter-bar-service/filter-bar.service';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { ActivatedRoute,Router} from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})


export class FilterBarComponent implements OnInit 
{
  isSliderDisabled: boolean= false;
  iscompDisabled: boolean = true;
  isActiveNOofCasesNo: boolean =true;
  public isRouteRefresh: boolean;
  public isAccountRefresh: boolean;
  public isMachineRefresh: boolean;
  isMobile = false;
  autoRefresh = false;
  refreshInterval = 5;
  tableIsDirtySubscription: Subscription;
  isTableDirty: boolean;

  isSubmitRPCDisabledSubscription: Subscription;
  isSubmitRPCDisabled: boolean;
  
  isActivateRPCDisabledSubscription: Subscription;
  isActivateRPCDisabled:boolean;

  @Output() public isAccountRef = new EventEmitter<boolean>();
  public autoref:boolean = true;

  @ViewChild('search') searchElement: ElementRef;
  @ViewChild('dateRange') calenderElement: ElementRef;
  @ViewChild('acctCheckbox') acctCheck: ElementRef;
  @ViewChild('myname') input:ElementRef; 
  
  @Input()
  filterState: string;

  @Input()
  params: string[];

  @Input()
  displayItems: string[];

  @Output()
  onApplyFilters = new EventEmitter<boolean>();

  @Output()
  onAutoRefresh = new EventEmitter<boolean>();

  @Output()
  onRefresh = new EventEmitter<boolean>();

  @Output()
  onChangeView = new EventEmitter<string>();

  constructor(private _filter: FilterBarService,
    private _deviceService: DeviceDetectorService, private rd: Renderer2,
    private route: ActivatedRoute,
    private _changeRef: ChangeDetectorRef
    ) {
      this._changeRef.detach();
  }

  ngOnInit() {
    this.isRouteRefresh = true;
    this.isAccountRefresh = false;
    this.isMachineRefresh = false;
    this.isTableDirty = false;

    this.isMobile = this._deviceService.isMobile();
    if (this.filterState !== 'in' || this.isMobile) {
      this.filterState = 'out';
    }
    this.tableIsDirtySubscription = this._filter.tableIsDirty$.subscribe(sub => this.isTableDirty = sub);
    this.isSubmitRPCDisabledSubscription = this._filter.isSubmitRPCEnabled$.subscribe(sub => this.isSubmitRPCDisabled = !sub);
    this.isActivateRPCDisabledSubscription = this._filter.isActivateRPCEnabled$.subscribe(sub => this.isActivateRPCDisabled = !sub);
  }

  ngDoCheck() {
    this._changeRef.detectChanges();
  }
  
  applyFilters() {
    this.filterState = 'out';
    this.onApplyFilters.emit(true);
  }

  changeView(view) {
    this.onChangeView.emit(view);
  }

  hideRoutes() {
    return this._filter.multipleOperations === true;
  }

  lastRefreshed() {
    return this._filter.refreshedOn;
  }

  refresh() {
    this.onRefresh.emit(true);
  }

  resetFilters() {
   this._filter.ResetFilterBar();
   this.isAccountRefresh = false; 
  }
  
  setAutoRefresh() {
    this._filter.autoRefresh = this.autoRefresh;
    this.onAutoRefresh.emit(this.autoRefresh);
  }

  setRefreshInterval() {
    this._filter.refreshInterval.next(this.refreshInterval);
  }

  toggleFilter() {
    this.filterState = this.filterState === 'out' ? 'in' : 'out';
  }

  refreshComponent() {
    window.location.reload();
}
  
  dateToggle()
  {
    this.isActiveNOofCasesNo = true;
    this.isRouteRefresh = true;
    this.isAccountRefresh = false;
    this.isMachineRefresh = false;
  }
  dropToggle()
  {
    this.isRouteRefresh = false;
    this.isAccountRefresh = true;
    this.isMachineRefresh = false;  
  }
  searchToggle(){    
    this.isAccountRef.emit(this.autoref);  
    this.isActiveNOofCasesNo = true;
    this.isRouteRefresh = false;
    this.isAccountRefresh = false;
    this.isMachineRefresh = true;
 }

 activateRPC() {
    this._filter.activateRPC();
 }

 submitRPC() {
    this._filter.submitRPC();
 }

 selectAllEnable() {
    this._filter.selectAllEnabledEvent();
 }

 selectAllAutoSend() {
    this._filter.selectAllAutoSendEvent();
}

}