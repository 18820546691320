import { Pipe, PipeTransform } from '@angular/core';
import { CostCenterInventoryDevice } from '../device-service/device-management.model';

@Pipe({
  name: 'deviceFilter'
})
export class DeviceFilterPipe implements PipeTransform {

  transform(items: CostCenterInventoryDevice[], filter: string): any {
    if(!items || !filter){
      return items;
    }

    return items.filter(item => item.device.toLowerCase().indexOf(filter.toLowerCase()) > -1);
  }

}
