import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../core/common-service/common.service';
import { SecurityService } from '../../core/security/security.service';
import { DeviceManagementService } from '../device-service/device-management.service';
import { CostCenterInventoryDevice, Operation, TransferDeviceRecord,TransferDeviceResponse } from '../device-service/device-management.model';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {

  selectedToOperation: Operation = { operationID: null, costCenterID: null, costCenter: 'Select' };
  selectedFrom: string;
  operations: Operation[];
  filter: string;
  currentCostCenterID: number;

  devices: CostCenterInventoryDevice[];
  selectedDevices: CostCenterInventoryDevice[];
  deviceErrors = '';

  @ViewChild('basic') basic;
  basicModalOptions: NgbModalOptions = { centered: true };
  modalType = '';
  modalMessage = '';

  constructor(private _common: CommonService,
    private _security: SecurityService,
    private _device: DeviceManagementService,
    private _modal: NgbModal) {
    this._common.setCurrentPage('Transfer Device');
    this._common.operationGuideSubject.next('Operations Guide - One Vend - Device Management.pdf');
    this.currentCostCenterID = Number(this._common.getOperation().operationNo);
  }

  ngOnInit() {
    this.selectedDevices = new Array();
    this.selectedFrom = this._common.operation.name;
      this._device.GetCostCenterDevices(Number(this._common.operation.operationNo)).subscribe((devices: CostCenterInventoryDevice[]) => {
        devices.forEach((d) => {
          d.selected = false;
          d.hidden = false;
        });
        this.devices = devices;
      });
    this._device.GetOperations().subscribe((ops: Operation[]) => {
       let value:Operation =
       {
        operationID:-3,
        costCenterID:-3,
        costCenter:"Disposal"
       }   
       ops.push(value);
      this.operations = ops.filter(x => x.costCenterID !== this.currentCostCenterID);
      this.selectedFrom = ops.find(y => y.costCenterID === this.currentCostCenterID).costCenter;
    });
  }

  selectToOperation(op) {
   
    this.selectedToOperation = op;
    console.log("OPER-Device::",op);
  }

  moveAvailableDevices() {
    this.devices.forEach((device) => {
      if (device.selected === true && device.hidden === false) {
        device.selected = false;
        device.hidden = true;
        this.selectedDevices.push(device);
      }
    });
  }

  moveSelectedDevices() {
    const selected = new Array<number>();
    for (let i = this.selectedDevices.length - 1; i >= 0; i--) {
      if (this.selectedDevices[i].selected) {
        this.devices.forEach((d) => {
          if (d.viDeviceID === this.selectedDevices[i].viDeviceID) {
            d.hidden = false;
            d.selected = false;
          }
        });
        this.selectedDevices.splice(i, 1);
      }
    }
  }

  transferDevices() {
    let error = false;
    this.modalMessage = '';
    if (this.selectedToOperation && this.selectedToOperation.costCenter === 'Select') {
      this.modalMessage += 'Please select an operation to transfer the devices to.<br />';
      this.modalType = 'error';
      error = true;
    }

    if (this.selectedDevices && this.selectedDevices.length < 1) {
      this.modalMessage += 'Please select devices to transfer.<br />';
      this.modalType = 'error';
      error = true;
    }

    if (error) {
      this._modal.open(this.basic, this.basicModalOptions);
      return;
    }

    const devices = new Array<TransferDeviceRecord>();
    this.selectedDevices.forEach((d) => {
      if(this.selectedToOperation.costCenterID > 0)     
      {
      devices.push({
        viDeviceID: d.viDeviceID,
        currentCostCenterId: this.selectedToOperation.costCenterID,
        userID: this._security.getUserID(),
        currentDeviceStatusLookupId: 0
      });
    }
      else if(this.selectedToOperation.costCenterID < 0)
      {
        devices.push({
          viDeviceID: d.viDeviceID,
          currentDeviceStatusLookupId: -1 * this.selectedToOperation.costCenterID,
          userID: this._security.getUserID(),
          currentCostCenterId: 0
        });
      }
    });
    this._device.TransferDevices(devices).subscribe((result: TransferDeviceResponse) => {
      this.deviceErrors = '';
      if (result.viDeviceIDSucess.length === this.selectedDevices.length) {
        this.modalType = 'success';
        this.modalMessage = 'Your changes have been saved.';
        this._modal.open(this.basic);
      } 
      else {
        this.modalType = 'error';
        this.modalMessage = 'Some of the selected devices were not able to be transfered.';
        this._modal.open(this.basic);
        this.deviceErrors = 'The following devices failed to transfer: ';
        let match = false;
        result.viDeviceIDFail.forEach((f) => {
          this.selectedDevices.forEach((d) => {
            if (d.viDeviceID === f) {
              this.deviceErrors += d.device + ', ';
              match = true;
            }
          });
        });
        if (match) {
          this.deviceErrors = this.deviceErrors.substring(0, this.deviceErrors.length - 3);
          this.deviceErrors += '.';
        }
      }
      this.ngOnInit();
    }, (transferDevicesErr) => {
      this.modalMessage = 'An error has occured while trying to transfer the selected devices.';
      this.modalType = 'error';
      this._modal.open(this.basic, this.basicModalOptions);
      console.log(transferDevicesErr);
    });
  }
}
