import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SecurityService } from '../security/security.service';
import {AppsettingsProvider} from '../appsettings.provider';

@Injectable()
export class AuthGuardService implements CanActivate {
  appSettings: any;

  constructor(
    private _security: SecurityService,
    private appSettingsProvider: AppsettingsProvider
  ) {
    this.appSettings = this.appSettingsProvider.getSettings();
  }

  canActivate() {

    const loggedIn = this._security.isLoggedIn();
    if (loggedIn) {
      const unauthorizedReason = this._security.getUnauthorizedReasonFromToken();

      if (unauthorizedReason) {
        this._security.redirectToUnauthorized(unauthorizedReason);
        return;
      }

      return true;
    }

    const omsBaseUrl = this.appSettings.omsSettings.omsBaseUrl;
    const omsClientId = this.appSettings.omsSettings.omsClientId;
    const omsRedirectUrl = encodeURIComponent(this.appSettings.omsSettings.omsRedirectUrl);

    window.location.href = `${omsBaseUrl}/oauth2.0/authorize?client_id=${omsClientId}&response_type=code&redirect_uri=${omsRedirectUrl}`;
    return false;
  }
}
