import { Switch } from '../../shared/switch/switch.model';

export class POGAdjustmentMachineInfo {
  selectedDisplay: Switch;
  accountNameAndNumber: string;
  locationNameAndNumber: string;
  machineID: number;
  machineNumber: number;
  dispenseType: string;
  planOGramID: number;
  hasPreviousDecreaseInd: boolean;
  hasPreviousIncreaseInd: boolean;
  latestPlanOGramDateTime: Date;
  canGeneratePlanOGramInd: boolean;
  errorMessage = '';
  errorIndex: number = null;
  messages: Message[];
}

// ----------------------------------------------------------------------------

export class PlanOGramAdjustmentRequestMachinePOG {
  machineID: number;
  machineNumber: number;
  planOGramID: number;
}
export class PlanOGramAdjustmentRequest {
  operationNo: number;
  methodCode: string;
  percentageChange: number;
  minimumPar: number;
  userID: number;
  effectiveDate: Date;
  machinePOGs: PlanOGramAdjustmentRequestMachinePOG[];
}

// ----------------------------------------------------------------------------

export class Message {
  messageCode: string;
  levelCode: string;
  messageText: string;
}
export class PlanOGramAdjustmentResponseMachinePOG {
  machineID: number;
  machineNumber: number;
  oldPlanOGramID: number;
  newPlanOGramID: number;
  newEffectiveDate: Date;
  canGeneratePlanOGramInd: boolean;
  isSuccess: boolean;
  errorMessage = '';
  messages: Message[];
}
export class PlanOGramAdjustmentResponse {
  operationNo: number;
  methodCode: string;
  percentagePar: number;
  minimumPar: number;
  errorMessage: string;
  machinePOGs: PlanOGramAdjustmentResponseMachinePOG[];
}

