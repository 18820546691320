import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { AuthGuardService } from '../core/auth-guard/auth-guard.service';

import { ModifyComponent } from './modify/modify.component';
import { AssignComponent } from './assign/assign.component';
import { TransferComponent } from './transfer/transfer.component';
import { DeviceManagementService } from './device-service/device-management.service';

import { DeviceFilterPipe } from './transfer/device-filter.pipe';
import { AddComponent } from './add/add.component';
import { RoleGuardService } from '../core/role-guard/role-guard.service';
import { DeviceInventoryComponent } from './device-inventory/device-inventory.component';

const routes: Routes = [
  { path: 'device/modify', component: ModifyComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'MODIFY_DEVICE_SCREEN_ACCESS'} },
  { path: 'device/assign', component: AssignComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'ASSIGN_DEVICE_SCREEN_ACCESS'} },
  { path: 'device/assign/:deviceNumber', component: AssignComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'ASSIGN_DEVICE_SCREEN_ACCESS'} },
  { path: 'device/transfer', component: TransferComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'TRANSFER_DEVICE_SCREEN_ACCESS'} },
  { path: 'device/add', component: AddComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'ADD_DEVICE_SCREEN_ACCESS'} },
  { path: 'device/device-inventory', component: DeviceInventoryComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'MODIFY_DEVICE_SCREEN_ACCESS'} }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CoreModule,
    SharedModule,
    NgbModule
  ],
  declarations: [ModifyComponent, AssignComponent, TransferComponent, DeviceFilterPipe, AddComponent, DeviceInventoryComponent],
  providers: [DeviceManagementService]
})
export class DeviceModule { }
