// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import 'zone.js/dist/zone-error';

export const environment = {
  production: false,
  rootUrl: "http://qas-onevend.allinonesys.com",
  tableRowLength: 20,
  appSettings: {
    reportingServiceURL: "http://qas-onevendservices.allinonesys.com/ReportingService/api/Request",
    routesServiceURL: "http://qas-onevendservices.allinonesys.com/RouteService/api/Request",
    smartServiceURL: "http://localhost:60003/api/smartservicestatus",
    securityServiceURL: "http://localhost:60004/api/security"
  }
};
