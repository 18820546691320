import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { DeviceManagementService } from '../device-service/device-management.service';
import {
  DeviceModifyInfo, DeviceStatus, DeviceModifyInfoReponse, ValidateResult, ModifyDevice, VendorInfo, DeviceModelInfo
} from '../device-service/device-management.model';
import { CommonService } from '../../core/common-service/common.service';
import { NgbModal, NgbModalOptions, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { SecurityService } from '../../core/security/security.service';
import { ISubscription } from 'rxjs/Subscription';
import { NgbTimeStructAdapter } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-adapter';

@Component({
  selector: 'app-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.scss']
})
export class ModifyComponent implements OnInit, OnDestroy {
  deviceSerialNumber: string; // Test: 101014482 Orlando
  searchError = '';
  searching = false;
  isInWarehouse = false;
  hasEndDate = false;
  deviceInfo: DeviceModifyInfo = null;
  selectedDeviceModel: DeviceModelInfo = null;
  selectADeviceModel: DeviceModelInfo = {viDeviceModelID:-1, viDeviceManufacturerID:-1, vendorCode:'', manufacturerName:'', deviceModel:'Select a model'};
  statusList: DeviceStatus[] = new Array();
  disposalReasons: DeviceStatus[] = new Array();
  vendorList: VendorInfo[] = new Array();
  deviceModelList: DeviceModelInfo[] = new Array();
  deviceModelForVendorList: DeviceModelInfo[] = new Array();
  private _originalDeviceStatus = '';
  private _originalDisposalReason = '';
  deviceStatus = 'Select';
  deviceStatusID = '';
  disposalReason = 'N/A';
  statusIsDisposal = false;
  isNewDevice = false;
  originalStatusIsDisposal = false;

  // Variables to use for time
  startTime: NgbTimeStruct;
  endTime: NgbTimeStruct;

  // Subsriptions
  deviceInfoSub: ISubscription;
  deviceErrorSub: ISubscription;

  // Show/hide booleans
  showTransactionDataFields = false;
  showWarningMessage = false;
  showRecommendedSentence = false;
  showAssignSentence = false;
  showAssignDisposeSentence = false;
  showStartEndDates = false;
  disableStartDate = true;
  disableEndDate = true;
  disableSaveButton = true;
  prepopulateStartDate = false;
  disabledDeviceModel = true;

  basicModalOptions: NgbModalOptions = { centered: true };
  modalType = '';
  modalMessage = '';

  notSuggestedMessage = '';
  modalOptions: NgbModalOptions = { backdrop: 'static', keyboard: false, centered: true };

  endDateHasChanged = false;

  @ViewChild('basic') basic;
  @ViewChild('notSuggested') notSuggested;
  @ViewChild('acc') acc;

  constructor(private _common: CommonService,
    private _security: SecurityService,
    private _device: DeviceManagementService,
    private _modal: NgbModal) {
      this._common.setCurrentPage('Modify Device');
      this._common.operationGuideSubject.next('Operations Guide - One Vend - Device Management.pdf');
  }

  ngOnInit() {
    this.deviceErrorSub = this._device.serviceError.subscribe((error) => {
      this.searchError = error;
    });

    this.deviceInfoSub = this._device.deviceInfo.subscribe((data: DeviceModifyInfoReponse) => {
      this.handleDeviceModifyInfoResponse(data);
    }, (error) => {
      this.searching = false;
      console.log(error);
      this.searchError = error.message;
    });
  }

  handleDeviceModifyInfoResponse(data: DeviceModifyInfoReponse) {
    this.searching = false;
    this.searchError = '';
    this.isInWarehouse = false;
    const searchErrorText = 'This is a known device but is currently ' +
    'assigned to <a target="_blank" href="https://mycompassdocuments.compass-usa.com/'
    + '_layouts/15/DocIdRedir.aspx?ID=WNQ5PVUCSJJV-1-83042'
    + '&file=Current%20Canteen%20Directory.xlsx&action=default">';

    

      if (data && data.deviceModifyRecord == null) {
      if (this.deviceSerialNumber != null && this.deviceSerialNumber !== '') {
       // this.searchError = 'Device is not yet set up. Please visit the '
        // + '<a routerLinkActive="active" [routerLink]="[\'/device/add\']">Add Device screen</a>.';
        this.searchError = 'Device is not yet set up. Please visit the Add Device Screen';
      } else {
        this.searchError = 'Please enter a device.';
      }
      return;
    }

    if (data && data.deviceModifyRecord) {
      // console.log(data.deviceModifyRecord.costCenterCurrent);
      // console.log(this._common.operation.operationNo);
      if (data.deviceModifyRecord.costCenterCurrent.toString() !== this._common.operation.operationNo.toString()) {
        this.searchError = searchErrorText +
          data.deviceModifyRecord.costCenterNameCurrent +
          ' - ' +
          data.deviceModifyRecord.costCenterCurrent + '</a>';
        return;
      }

      this.parseDeviceRecordDates(data.deviceModifyRecord);

      this.deviceStatus = data.deviceModifyRecord.deviceStatus !== null &&
        data.deviceModifyRecord.deviceStatus !== '' ? data.deviceModifyRecord.deviceStatus : this.deviceStatus;
      this._originalDeviceStatus = this.deviceStatus;
      this.disposalReason = 'N/A';
      this.disposalReason = data.deviceModifyRecord.reason != null &&
        data.deviceModifyRecord.reason !== '' ? data.deviceModifyRecord.reason : this.disposalReason;
        this._originalDisposalReason = this.disposalReason;

      data.statusList.forEach((status) => {
        if (status.description === data.deviceModifyRecord.deviceStatus) {
          this.deviceStatusID = status.iVendLookupID.toString();
          this.deviceStatus = status.description;
        }
      });

      this.deviceInfo = data.deviceModifyRecord;
      this.disabledDeviceModel = data.deviceModifyRecord.viDeviceEventInd || data.deviceModifyRecord.viSalesTxnInd;
      this.statusList = data.statusList.filter(
        status => {
          return status.description !== 'Installed';
        });
      this.disposalReasons = data.disposalReasonList;
      this.vendorList = data.vendorList;
      this.deviceModelList = data.deviceModelList;
      this.deviceModelForVendorList = data.deviceModelList.filter(
        model=> {
          return model.vendorCode.toLowerCase() == this.deviceInfo.vendorCode.trim().toLowerCase();
        });
      this.deviceModelForVendorList.sort((a,b)=>{return a.deviceModel.localeCompare(b.deviceModel);})
  

      this.selectedDeviceModel = this.deviceModelList.find(x=>x.viDeviceModelID == this.deviceInfo.viDeviceModelID);
      if (this.selectedDeviceModel == null) {this.selectedDeviceModel = this.selectADeviceModel;}

      // Parent if cases
      const excptPmtTransNeedingAction = this.deviceInfo != null &&
        this.deviceInfo.earliestNotMappedDateTime != null &&
        this.deviceInfo.totalNotMapped > 0;
      const pmtTransWaitingMchSvc = this.deviceInfo != null &&
        this.deviceInfo.earliestNotMappedDateTime != null &&
        (this.deviceInfo.totalNotMapped === 0 ||
          this.deviceInfo.totalNotMapped == null);
      const dvcWithAllPmtTrans = this.deviceInfo != null &&
        this.deviceInfo.earliestNotMappedDateTime == null &&
        (this.deviceInfo.totalNotMapped === 0 ||
          this.deviceInfo.totalNotMapped == null);

      // Children if cases
      const noLocExistDvc = this.deviceInfo != null && this.deviceInfo.deviceStartDateCurrent == null;
      const currLocOnAcct = this.deviceInfo != null &&
        this.deviceInfo.locationCurrent != null &&
        this.deviceInfo.deviceEndDateCurrent == null;

      const currLocWH = this.deviceInfo != null &&
        this.deviceInfo.locationCurrent == null &&
        this.deviceInfo.deviceStartDateCurrent != null &&
        this.deviceInfo.deviceEndDateCurrent == null;

      const currLocWHHasNotEnded = this.deviceInfo != null &&
        this.deviceInfo.locationCurrent == null &&
        this.deviceInfo.locationPrior == null &&
        this.deviceInfo.deviceEndDateCurrent == null;
      // Don't need to check LocationCurrent for populated or null
      const currLocEndedWH = this.deviceInfo != null &&
        this.deviceInfo.deviceEndDateCurrent != null;

      const lastAssignedToWarehouseMch = this.deviceInfo != null &&
          this.deviceInfo.machineCurrent != null &&
          this.deviceInfo.locationCurrent == null &&
          this.deviceInfo.accountCurrent == null;

      // Logic for showing and hiding elements on screen, per user story 1089
      this.showTransactionDataFields = excptPmtTransNeedingAction || pmtTransWaitingMchSvc;
      this.showWarningMessage = excptPmtTransNeedingAction;

      this.showRecommendedSentence = (excptPmtTransNeedingAction && currLocOnAcct)
        || (pmtTransWaitingMchSvc && currLocOnAcct)
        || (dvcWithAllPmtTrans && currLocOnAcct);

      this.showAssignSentence = (excptPmtTransNeedingAction && (noLocExistDvc || currLocEndedWH))
        || (pmtTransWaitingMchSvc && currLocEndedWH)
        || (dvcWithAllPmtTrans && (noLocExistDvc || (currLocEndedWH && this.deviceInfo.deviceStatus === 'Inventory')));

      this.showAssignDisposeSentence = (excptPmtTransNeedingAction && currLocWH)
        || (pmtTransWaitingMchSvc && currLocWH)
        || (dvcWithAllPmtTrans && currLocWHHasNotEnded);

      this.showStartEndDates = (excptPmtTransNeedingAction && (currLocOnAcct || currLocWH || currLocEndedWH))
        || (pmtTransWaitingMchSvc)
        || (dvcWithAllPmtTrans && (currLocOnAcct || currLocWHHasNotEnded || currLocEndedWH || currLocWH));

      this.isNewDevice = noLocExistDvc;
      this.originalStatusIsDisposal = this._originalDeviceStatus === 'Disposal';
      this.disableEndDate =  this.originalStatusIsDisposal || this.deviceStatus=='Inventory';
      this.disableStartDate = lastAssignedToWarehouseMch || this.originalStatusIsDisposal || this.deviceStatus=='Inventory';
      this.disableSaveButton = this.originalStatusIsDisposal;

      if (moment(this.deviceInfo.currentStartDate).isSame(this.deviceInfo.suggestedStartDateTime)) {
        this.showRecommendedSentence = false;
      }

      // Used in accordion, device info
      this.isInWarehouse = this.deviceInfo.accountCurrent == null && this.deviceInfo.locationCurrent == null;

      this.deviceInfo.currentStartDate = this._common.parseDate(this.deviceInfo.deviceStartDateCurrent)
      const currStart = moment(data.deviceModifyRecord.currentStartDate);
      const currEnd = moment(data.deviceModifyRecord.currentEndDate);
      this.startTime = { 'hour': currStart.hour(), 'minute': currStart.minute(), 'second': currStart.second() };
      this.endTime = { 'hour': currEnd.hour(), 'minute': currEnd.minute(), 'second': currEnd.second() };
      this.hasEndDate = (this._common.parseDate(this.deviceInfo.currentEndDate) != null);
    } else {
      this.deviceInfo = null;
    }

    console.log(data);
  }

  parseDeviceRecordDates(device: DeviceModifyInfo) {
    const parseDate = this._common.parseDate;

    device.deviceStartDateCurrent = parseDate(device.deviceStartDateCurrent);
    device.initialCurrentStartDate = parseDate(device.initialCurrentStartDate);
    device.initialCurrentEndDate = parseDate(device.initialCurrentEndDate);
    device.currentStartDate = parseDate(device.currentStartDate);
    device.currentEndDate = parseDate(device.currentEndDate);
    device.latestNotMappedDateTime = parseDate(device.latestNotMappedDateTime);
    device.suggestedStartDateTime = parseDate(device.suggestedStartDateTime);
    device.latestTransactionMapped = parseDate(device.latestTransactionMapped);
    device.earliestNotMappedDateTime = parseDate(device.earliestNotMappedDateTime);
    device.mostRecentDeviceStartDateTime = parseDate(device.mostRecentDeviceStartDateTime);
    device.lastFullFinalServiceDateTime = parseDate(device.lastFullFinalServiceDateTime);
    device.costCenterAcquireDate = parseDate(device.costCenterAcquireDate);
    device.latestTransactionCurrent = parseDate(device.latestTransactionCurrent);
    device.lastFullFinalServiceDateTimeCurrent = parseDate(device.lastFullFinalServiceDateTimeCurrent);
    device.deviceStartDateCurrent = parseDate(device.deviceStartDateCurrent);
    device.deviceEndDateCurrent = parseDate(device.deviceEndDateCurrent);
    device.latestTransactionPrior = parseDate(device.latestTransactionPrior);
    device.lastFullFinalServiceDateTimePrior = parseDate(device.lastFullFinalServiceDateTimePrior);
    device.deviceStartDatePrior = parseDate(device.deviceStartDatePrior);
    device.deviceEndDatePrior = parseDate(device.deviceEndDatePrior);
    device.twoTierDeviceIndicator = parseDate(device.twoTierDeviceIndicator);
    device.lastVendorDexDateTimeIndicator = parseDate(device.lastVendorDexDateTimeIndicator);
    device.modifiedDateTime = parseDate(device.modifiedDateTime);
  }

  ngOnDestroy() {
    this._device.serviceError.next(null);
    this._device.deviceInfo.next(null);
    this.deviceInfoSub.unsubscribe();
    this.deviceErrorSub.unsubscribe();
  }

  endDateChanged() {
    this.endDateHasChanged = true;
    //IS OK TO REMOVE? this.acc.activeIds = 'DeviceAttributes';
  }

  lockStatusDropdown() { //Status dropdown is disabled if this returns true
    let endDateChangedOrPopulated : boolean = this.endDateHasChanged || this.hasEndDate;
    return !endDateChangedOrPopulated || this.originalStatusIsDisposal;
  } 

  lockDisposalReasonDropdown() { //dropdown is disabled if this returns true
    return this.originalStatusIsDisposal || this.deviceStatus !== 'Disposal'
  }

  saveChanges() {
    // console.log('Start Date: ', this.deviceInfo.currentStartDate);
    // console.log('End Date: ', this.deviceInfo.currentEndDate);
    this.hasEndDate = (this._common.parseDate(this.deviceInfo.currentEndDate) != null);

    if (this.endDateHasChanged === true || this.hasEndDate) {
      if ((this.deviceStatus === this._originalDeviceStatus && !this.hasEndDate && this.disposalReason === this._originalDisposalReason)
      || (this.deviceInfo.deviceStatus == null)
      || (this.deviceStatus === 'Installed' && this.hasEndDate)
      || (this.deviceStatus === 'Disposal' && (this.deviceInfo.reason == null || this.disposalReason === 'N/A'))) {
        this.modalType = 'error';
        this.modalMessage = 'Please update the device status and reason.';
        this._modal.open(this.basic, this.basicModalOptions);
        this.acc.activeIds = 'DeviceAttributes';
        return;
      }
    }

    if (moment(this.deviceInfo.currentStartDate).isBefore(this.deviceInfo.operationGoLiveDate)) {
      this.modalType = 'error';
      this.modalMessage = 'Install Date must be after the Operation go live date, '
      + moment(this.deviceInfo.operationGoLiveDate).format('MM/DD/YYYY');
      this._modal.open(this.basic, this.basicModalOptions);
      return;
    }

    const isSame = moment(this.deviceInfo.currentStartDate).isSame(this.deviceInfo.suggestedStartDateTime, 'day');
    if (!isSame) {
      if (this.deviceInfo.totalNotMapped > 0) {
        this.notSuggestedMessage = 'You may be missing collections when using this device start date. Do you still wish to proceed?';
        this._modal.open(this.notSuggested, this.modalOptions).result.then((result) => {
          if (result.toString() === 'CONTINUE') {
            this._saveChanges();
          } else {
            return;
          }
        });
      } else {
        this._saveChanges();
      }
    } else {
      // If the same date, fake add the timestamp from the suggested
      this.deviceInfo.currentStartDate = this.deviceInfo.suggestedStartDateTime;
      this._saveChanges();
    }
  }

  private _saveChanges() {

    // If recommended start date == null, display a validation error
    if (!moment(this.deviceInfo.currentStartDate).isValid()
        || this.startTime == null
        || this.startTime.hour == null
        || this.startTime.minute == null) {
      this.modalType = 'error';
      this.modalMessage = 'Recommended Start Date is invalid';
      this._modal.open(this.basic, this.basicModalOptions);
      return;
    }
    const saveRequest = new ModifyDevice();
    saveRequest.deviceModifyRecord = { ... this.deviceInfo };
    saveRequest.deviceModifyRecord.deviceStartDatePrior = saveRequest.deviceModifyRecord.deviceStartDatePrior != null ?
      this._common.convertDateTimeToString(this._common.parseDate(saveRequest.deviceModifyRecord.deviceStartDatePrior)) : null;
    saveRequest.deviceModifyRecord.latestTransactionPrior = saveRequest.deviceModifyRecord.latestTransactionPrior != null ?
      this._common.convertDateTimeToString(this._common.parseDate(saveRequest.deviceModifyRecord.latestTransactionPrior)) : null;
    saveRequest.deviceModifyRecord.currentEndDate = saveRequest.deviceModifyRecord.currentEndDate != null ?
      this._common.convertDateTimeToString(this._common.parseDate(saveRequest.deviceModifyRecord.currentEndDate)) : null;
    saveRequest.deviceModifyRecord.initialCurrentEndDate = saveRequest.deviceModifyRecord.initialCurrentEndDate != null ?
      this._common.convertDateTimeToString(this._common.parseDate(saveRequest.deviceModifyRecord.initialCurrentEndDate)) : null;
    saveRequest.deviceModifyRecord.latestNotMappedDateTime = saveRequest.deviceModifyRecord.latestNotMappedDateTime != null ?
      this._common.convertDateTimeToString(this._common.parseDate(saveRequest.deviceModifyRecord.latestNotMappedDateTime)) : null;
    saveRequest.deviceModifyRecord.latestTransactionCurrent = saveRequest.deviceModifyRecord.latestTransactionCurrent != null ?
      this._common.convertDateTimeToString(this._common.parseDate(saveRequest.deviceModifyRecord.latestTransactionCurrent)) : null;
    saveRequest.deviceModifyRecord.deviceStartDateCurrent = saveRequest.deviceModifyRecord.deviceStartDateCurrent != null ?
      this._common.convertDateTimeToString(this._common.parseDate(saveRequest.deviceModifyRecord.deviceStartDateCurrent)) : null;
    saveRequest.deviceSerialNo = this.deviceSerialNumber;
    saveRequest.deviceStatus = Number(this.deviceStatusID);
    saveRequest.disposalReason = this.deviceInfo.disposalReason;
    saveRequest.newStartDate = this._common.combineDateTimeForServer(moment(this.deviceInfo.currentStartDate).toDate(), this.startTime);
    saveRequest.newEndDate = this.deviceInfo.currentEndDate != null ?
      this._common.combineDateTimeForServer(moment(this.deviceInfo.currentEndDate).toDate(), this.endTime) : null;
    saveRequest.userID = this._security.getUserID();
    saveRequest.proceed = false;

    this.searching = true;
    this._device.ModifyDevice(saveRequest).subscribe((saveResult: ValidateResult) => {
      if (saveResult.passedValidation) {
        this.deviceSerialNumber = null;
        this._device.deviceInfo.next(null);
        this.modalType = 'success';
        this.modalMessage = 'Your changes have been saved.';
        this._modal.open(this.basic);
        this.searchClear();
      } else if (saveResult.confirmationNeeded === true) {
        this.notSuggestedMessage = saveResult.message;
        this._modal.open(this.notSuggested, this.modalOptions).result.then((result) => {
          if (result.toString() === 'CONTINUE') {
            saveRequest.proceed = true;
            this._device.ModifyDevice(saveRequest).subscribe((saveResult2: ValidateResult) => {
              if (saveResult2.passedValidation) {
                this.deviceSerialNumber = null;
                this._device.deviceInfo.next(null);
                this.modalType = 'success';
                this.modalMessage = 'Your changes have been saved.';
                this._modal.open(this.basic);
                this.searchClear();
              } else {
                this.modalType = 'error';
                this.modalMessage = saveResult2.message;
                this._modal.open(this.basic, this.basicModalOptions);
                this.searching = false;
              }
            }, (error) => {
              console.log(error);
              this.modalType = 'error';
              this.modalMessage = error.message.toString() !== '' &&
                error.message != null ? error.message : 'An error occured while saving your changes.';
              this._modal.open(this.basic, this.basicModalOptions);
              this.searching = false;
            });
          }
        });
      } else {
        this.modalType = 'error';
        this.modalMessage = saveResult.message === 'ERROR' ||
          saveResult.message == null ? 'An error occured while trying to save your changes.' : saveResult.message;
        this._modal.open(this.basic, this.basicModalOptions);
        this.searching = false;
      }
    }, (error) => {
      console.log(error);
      this.modalType = 'error';
      this.modalMessage = error.message.toString() !== '' &&
        error.message != null ? error.message : 'An error occured while saving your changes.';
      this._modal.open(this.basic, this.basicModalOptions);
      this.searchClear();
    });
  }

  searchClear() {
    this.searchError = '';
    this.deviceSerialNumber = '';
    this.deviceInfo = null;
    this.deviceStatus = 'Select';
    this.deviceStatusID = '';
    this._device.deviceInfo.next(null);
    this.searching = false;
  }

  search() {
    this.searching = true;
    this.endDateHasChanged = false;
    this.deviceInfo = null;
    if (this.deviceSerialNumber == null || this.deviceSerialNumber === '') {
      this.searchError = 'Please enter a device.';
      this.searching = false;
      return;
    }
    this.deviceSerialNumber = this.deviceSerialNumber.trim();
    this._device.GetDeviceInfo(this.deviceSerialNumber);
  }

  selectReason(reason) {
    this.deviceInfo.disposalReason = reason.iVendLookupID;
    this.disposalReason = reason.description;
  }

  selectStatus(status) {
    this.deviceStatusID = status.iVendLookupID;
    this.deviceStatus = status.description;
    this.statusIsDisposal = (status.description === 'Disposal');
    if (!this.statusIsDisposal) {
      this.deviceInfo.disposalReason = null;
      this.disposalReason = 'N/A';
    }
  }

  selectVendor(vendor:VendorInfo) {
    console.log('selectVendor');

    if (this.deviceInfo.vendorCode.trim() == vendor.vendorCode) {
      // Do nothing
    } else {
      this.deviceInfo.vendorCode = vendor.vendorCode;
      let modelList = this.deviceModelList.filter(
        model=> {
          return model.vendorCode == this.deviceInfo.vendorCode.trim();
        });
      modelList.sort((a,b)=>{return a.deviceModel.localeCompare(b.deviceModel);})
      this.deviceModelForVendorList = modelList;
      if (this.selectedDeviceModel.vendorCode != this.deviceInfo.vendorCode.trim()) {
        this.selectedDeviceModel = this.selectADeviceModel;
        this.deviceInfo.viDeviceModelID = 0;
      }
    }
  }

  selectDeviceModel(deviceModel:DeviceModelInfo) {
    console.log('selectDeviceModel');
    this.selectedDeviceModel = deviceModel;
    this.deviceInfo.viDeviceModelID = deviceModel.viDeviceModelID;
  }
}
