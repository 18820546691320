import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/common-service/common.service';
import { ReportsService } from '../reports-service/reports.service';
import { ReportGroupWrapper, ReportGroup } from '../reports-service/reports.model';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  reportList: ReportGroup[];
  generating: boolean = false;

  constructor(private _common: CommonService,
    private _reports: ReportsService) {
    this._common.setCurrentPage('Report Search');
  }

  ngOnInit() {
    this.generating = true;
    this._reports.GetReportList().subscribe((data: ReportGroupWrapper) => {
      this.reportList = data.reportGroupList;
      this.generating = false;
    });
  }

}
