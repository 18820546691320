import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilitiesService } from '../utilities-service/utilities.service';
import { CommonService } from '../../core/common-service/common.service';
import { POGAdjustmentMachineInfo, PlanOGramAdjustmentResponse, Message } from '../utilities-service/pog-adjustment.model';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';
import { AccountLineItem } from '../../smart-service/smart-service-service/smart-service.model';
import { Switch } from '../../shared/switch/switch.model';

@Component({
  selector: 'app-pog-adjustment',
  templateUrl: './pog-adjustment.component.html',
  styleUrls: ['./pog-adjustment.component.scss']
})
export class POGAdjustmentComponent implements OnInit, OnDestroy {

  constructor(private _common: CommonService,
    private _utility: UtilitiesService,
    private _filter: FilterBarService ) {
    this._common.setCurrentPage('PlanOGram Adjustment');
    this._common.operationGuideSubject.next('Operations Guide - One Vend - PlanOGram Adjustment.pdf');
  }

  filterBarParams = ['buttons', 'accounts'];

  // const defaultValues: {isParIncrease: false, isParDecrease: false, parChangePercentage: 35};
  loading = false;
  showDetails = false;
  isNonSeedOperation = false;
  errorMessage = '';
  method: any = 1;
  effectiveDate: Date;
  parChangePercentage = 35;
  parMinimumValue = 5;
  machinePOGInfos: POGAdjustmentMachineInfo[] = null;
  applyResultMessages: Message[] = new Array();

  ngOnInit() {
    this.isNonSeedOperation = !this._common.operation.isOnSeed;
    this._filter.IntializeAccounts();
    this._getAccountInfo();

    const now = new Date();
    this.effectiveDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
  }

  ngOnDestroy() {
    this._common.operationGuideSubject.next(null);
  }

  _getAccountInfo() {
    this.loading = true;
    this.loading = false;
  }

  getFilteredAccountIDs() {
    const accountIDList = new Array();
    for (let i = 0; i < this._filter.accounts.value.length; i++) {
      const acct: AccountLineItem = this._filter.accounts.value[i];
      if (acct.selected) {
        accountIDList.push(acct.id);
      }
    }

    const returnValue = (accountIDList.length === 0) ?  '' : accountIDList.join(',');
    return returnValue;
  }

  getMessageAlertClass(message: Message) {
    let alertClass = 'alert ';
    switch (message.levelCode) {
      case 'INFO':
        alertClass += 'alert-info';
        break;
      case 'WARN':
        alertClass += 'alert-warning';
        break;
      case 'ERROR':
        alertClass += 'alert-danger';
        break;
    }

    return alertClass;
  }

  getMachinePOGAlertClass(machinePOG: POGAdjustmentMachineInfo) {
    let alertClass = '';

    if (machinePOG.messages !== null && machinePOG.messages !== undefined) {
      let errorCount = 0;
      let infoCount = 0;
      let warnCount = 0;

      for (let i = 0; i < machinePOG.messages.length; i++) {
        const message = machinePOG.messages[i];
        switch (message.levelCode) {
          case 'INFO':
            infoCount++;
            break;
          case 'WARN':
            warnCount++;
            break;
          case 'ERROR':
            errorCount++;
            break;
        }
      }

      if (errorCount > 0) {
        alertClass = 'alert alert-danger';
      } else {
        if (warnCount > 0) {
          alertClass = 'alert alert-warning';
        } else {
          if (infoCount > 0) {
            alertClass = 'alert alert-info';
          }
        }
      }
    }

    return alertClass;
  }

  getSelectedMachineIDs() {
    const machineIDList = new Array();
    for (let i = 0; i < this.machinePOGInfos.length; i++) {
      const machinePOG = this.machinePOGInfos[i];
      if (machinePOG.selectedDisplay.input.valueOf) {
        machineIDList.push(machinePOG.machineID);
      }
    }

    const returnValue = (machineIDList.length === 0) ? '' : machineIDList.join(',');
    return returnValue;
  }

  resetSearch() {
    console.log('resetSearch');
  }

  resetMachinePOGs() {
    console.log('resetMachinePOGs');
    this.showDetails = false;
  }

  applyMachinePOGs() {
    this.errorMessage = '';
    this.applyResultMessages = [];
    const machineIDList = this.getSelectedMachineIDs();

    if (typeof this.effectiveDate === 'string') {
      this.effectiveDate = new Date(this.effectiveDate);
    }

    const effectiveDateOnly = new Date(this.effectiveDate.getFullYear(),
                                       this.effectiveDate.getMonth(),
                                       this.effectiveDate.getDate(), 0, 0, 0, 0);

    console.log(`applyMachinePOGs: method?${this.method} percentage:${this.parChangePercentage} ` +
                 `effective date:${effectiveDateOnly} minimum par:${this.parMinimumValue}`);
    console.log(`machineIDList: ${machineIDList}`);

    const machinePOGInfos: POGAdjustmentMachineInfo[] = [];
    const methodCode = this.method === 0 ? 'INCREASE_PAR' : 'DECREASE_PAR';

    for (let i = 0; i < this.machinePOGInfos.length; i++) {
      const machinePOG = this.machinePOGInfos[i];
      if (machinePOG.selectedDisplay.input) {
        machinePOGInfos.push(machinePOG);
      }
    }

    this.loading = true;
    this._utility.PostPOGAdjustmentMachinePOGs(machinePOGInfos,
                                               this._common.operation.operationNo, methodCode,
                                               this.parChangePercentage, this.parMinimumValue, effectiveDateOnly)
    .subscribe((data: PlanOGramAdjustmentResponse) => {
      this.errorMessage = data.errorMessage;
      for (let i = 0; i < data.machinePOGs.length; i++) {
        const newMachinePOG = data.machinePOGs[i];
        const findMachinePOG = this.machinePOGInfos.find(x => x.machineID === newMachinePOG.machineID);
        if (findMachinePOG === null) {
          // findMachinePOG.errorMessage = newMachinePOG.errorMessage;
        } else {
          findMachinePOG.canGeneratePlanOGramInd = newMachinePOG.canGeneratePlanOGramInd;
          findMachinePOG.selectedDisplay.input = newMachinePOG.canGeneratePlanOGramInd;
          findMachinePOG.errorMessage = newMachinePOG.errorMessage;
          findMachinePOG.messages = newMachinePOG.messages;
          findMachinePOG.latestPlanOGramDateTime = newMachinePOG.newEffectiveDate;
        }

        newMachinePOG.messages.forEach(msg => {
          this.applyResultMessages.push(msg);
        });
   }
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.errorMessage = `Error: ${error.statusText}: ${error.message}`;
      this.loading = false;
    });
  }

  onApplyFilters(event: any) {
    this.loading = true;
    this.applyResultMessages = [];
    const accountIDs = this.getFilteredAccountIDs();
    this._utility.GetPOGAdjustmentMachinePOGInfosForAccountIDs(this._common.operation.operationNo, accountIDs)
      .subscribe((data: POGAdjustmentMachineInfo[]) => {
        data.forEach(x => {x.selectedDisplay = new Switch('', false, false); });
        this.machinePOGInfos = data;
        this.showDetails = true;
        this.loading = false;
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
  }

  onRefresh(event: any) {
    console.log('onRefresh');
  }

  onResetFilters(event: any) {
  }
}
