import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { SecurityService } from '../security/security.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UnAuthorizationReason } from "../auth-claims.model";


@Injectable()
export class RoleGuardService implements CanActivate {

    constructor(public _security: SecurityService, public _router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (this._security.isLoggedIn()) {
          const expectedRole = route.data.expectedRole;
          const isRoleExist = this.hasRole(expectedRole);

          if (!isRoleExist) {
            this._security.redirectToUnauthorized(this._security.getUnauthorizedReasonFromToken() || UnAuthorizationReason.FunctionPermission);
            return;
          }

          return true;
        }

        return false;
    }

    hasRole(expectedRole: string): boolean {
        const token = localStorage.getItem('access_token');

        const helper = new JwtHelperService();
        const tokenPayload = helper.decodeToken(token);

        return tokenPayload && tokenPayload.role && tokenPayload.role.indexOf(expectedRole) > -1 && !tokenPayload.unauthorizedReason;
    }

    // Checks to see if user has at least one of the passed roles
  hasOneOfRoles(requiredRoles: string[]): boolean {
    return requiredRoles.some(role => this.hasRole(role));
  }

    // Checks to see if user has all of the passed roles
  hasAllRoles(requiredRoles: string[]): boolean {
    return requiredRoles.every(role => this.hasRole(role));
  }
}
