import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => { };

export const DATE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerComponent),
  multi: true
};

@Component({
  selector: 'app-date-picker',
  providers: [DATE_VALUE_ACCESSOR],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})

export class DatePickerComponent implements ControlValueAccessor {

  @Input()
  disabled = false;

  @Input()
  placeholder = '';

  @Input()
  makeRed = false;

  @Input()
  showTimeStamp = false;

  selectedDate: any;
  minDate = {year: 2015, month: 1, day: 1};

  toggle(element) {
    if (!this.disabled) {
      element.toggle();
    }
  }

  onChange = (date?: Date) => { };
  onTouched = () => { };

  writeValue(value: Date) {
    this.selectedDate = value;
  }

  registerOnChange(fn: (date: Date) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Write change back to parent
  onDateChange(value: Date) {
    this.onChange(value);
  }

  // Write change back to parent
  onDateSelect(value: any) {
    this.onChange(new Date(value.year, value.month - 1, value.day));
  }
}
