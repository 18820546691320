import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ISubscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import * as moment from 'moment';

import { CommonService } from '../../core/common-service/common.service';
import { SecurityService } from '../../core/security/security.service';
import { SmartServiceService } from '../smart-service-service/smart-service.service';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';
import { SmartServiceStatusListItem, OperationLineItem, SmartServiceStatusRequest } from '../smart-service-service/smart-service.model';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

@Component({
  selector: 'app-operation-status',
  templateUrl: './operation-status.component.html',
  styleUrls: ['./operation-status.component.scss']
})
export class OperationStatusComponent implements OnInit, OnDestroy {

  operations: any[];

  private operationStatusSubscription: ISubscription;
  private operationSubscription: ISubscription;
  private refreshSubscription: ISubscription;
  filterPosition = 'in';
  isMobile: boolean;

  params = ['refresh', 'autoRefresh', 'buttons', 'date', 'operations'];

  constructor(private _common: CommonService,
    private _security: SecurityService,
    private _smart: SmartServiceService,
    private _filter: FilterBarService,
    private _router: Router,
    private _deviceService: DeviceDetectorService) {
      this._common.setCurrentPage('Smart Service Operation Status');
      this._common.operationGuideSubject.next('Operations Guide - One Vend - SmartServiceStatus.pdf');
  }

  ngOnInit() {
    this._common.columnSortAsc = true;
    this._common.columnToSort = '';
    this.operationStatusSubscription = this._smart.smartServiceStatusSubject.subscribe((data: SmartServiceStatusListItem[]) => {
      this.operations = data;
    });
    this.operationSubscription = this._smart.operationSubject.subscribe((ops: OperationLineItem[]) => {
      for (let i = 0; i < ops.length; i++) {
        ops[i].selected = true;
      }
      this._filter.operations.next(ops);
    });
    const intervalSubscription = this._filter.refreshInterval.subscribe((data) => {
      this.onAutoRefresh(this._filter.autoRefresh);
    });

    this.isMobile = this._deviceService.isMobile();
    if (this.isMobile) {
      this.filterPosition = 'out';
    }

    this._smart.Initialize();
    this._filter.dateLabel = 'FISCAL DATE*';
    const d: Date = moment(moment().toDate().setHours(0,0,0,1)).toDate();
    const t = moment(moment().toDate().setHours(0,0,0,1)).add(1, 'days').toDate();
    this._filter.date.next(t);
    this._filter.tempDate = t;
    this._filter.refreshedOn = moment().toDate();
    this._filter.dateError = '';
    this._filter.operationError = '';
  }

  ngOnDestroy() {
    this.operationStatusSubscription.unsubscribe();
    this.operationSubscription.unsubscribe();
    this._common.operationGuideSubject.next(null);
  }

  isRefreshing() {
    return this._smart.refreshing;
  }

  onApplyFilters(apply) {
    this._smart.refreshing = true;
    let isError = false;
    if (typeof this._filter.tempDate !== 'object' || this._filter.tempDate == null) {
      this._filter.dateError = 'Fiscal Date is Required';
      isError = true;
    }

    const operations = new Array();
    for (let i = 0; i < this._filter.operations.value.length; i++) {
      if (this._filter.operations.value[i].selected) {
        operations.push(this._filter.operations.value[i].id);
      }
    }

    if (operations.length === 0) {
      this._filter.operationError = 'Must select at least one operation.';
      isError = true;
    }

    if (isError) {
      this._smart.refreshing = false;
      return;
    }

    this._filter.operationError = '';
    const request = new SmartServiceStatusRequest();
    request.availableOperationIDs = this._security.availableOperations.map(item => item.operationID);
    if (this._filter.tempDate !== this._filter.date.value) {
      this._filter.date.next(this._filter.tempDate);
    }
    request.businessDate = this._filter.date.value;

    request.operationIDs = operations;
    request.routeIDs = new Array();
    this._smart.Search(request);
    this._filter.refreshedOn = moment().toDate();
  }

  onAutoRefresh(autoRefresh) {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (autoRefresh) {
      const interval = Number(this._filter.refreshInterval.value) * 60 * 1000;
      this.refreshSubscription = Observable.interval(interval).subscribe((x) => {
        this.onRefresh(true);
      });
    }
  }

  onRefresh(refresh) {
    this.onApplyFilters(refresh);
  }

  onResetFilters(apply) {
    this.ngOnInit();
  }

  selectOperation(event, operation) {
    for (let i = 0; i < this._filter.operations.value.length; i++) {
      const selected = this._filter.operations.value[i].operationID === operation.operationID;
      this._filter.operations.value[i].selected = selected;
    }
    const serviceDate = this._common.stringifyDate(this._filter.date.value);
    this._router.navigate(['/smartservice/route-status/' + serviceDate + '/' + operation.operationID]);
  }

  sort(sortAsc, columnName, isString) {
    this._smart.Sort(columnName, sortAsc, isString);
  }
}
