import { Component, OnInit } from '@angular/core';
import { RPCTrackStatusFilterItemsResponse, RpcTrackStatusSearchRequest } from '../../../remote-price-change/remote-price-change-service/remote-price-change.model';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';
import { ISubscription } from 'rxjs-compat/Subscription';

@Component({
  selector: 'app-rpc-status-filters',
  templateUrl: './rpc-status-filters.component.html',
  styleUrls: ['./rpc-status-filters.component.scss']
})
export class RpcStatusFiltersComponent implements OnInit {
  updatedMachineModelsDropdown: string[];
  vendorCodesDropdown: string[];
  upgradeStepsDropdown: string[];
  dropdownItemSubscription: ISubscription;

  public filterItem: RpcTrackStatusSearchRequest;

  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this.updatedMachineModelsDropdown = new Array();
    this.vendorCodesDropdown = new Array();
    this.upgradeStepsDropdown = new Array();
    this.filterItem = new RpcTrackStatusSearchRequest();

    this.dropdownItemSubscription = this._filter.GetRPCTrackStatusFilterData().subscribe((data: RPCTrackStatusFilterItemsResponse) => {
      this.fillDropdowns(data);
    });

    this._filter.resetFilters.subscribe(data => {
      this.performReset();
    });
  }

  private fillDropdowns(data: RPCTrackStatusFilterItemsResponse) {
    data.updatedMachineModels.forEach(x => {
      this.updatedMachineModelsDropdown.push(x.name);
    });

    data.vendors.forEach(y => {
      this.vendorCodesDropdown.push(y.vendorCode);
    });

    data.upgradeSteps.forEach(z => {
      this.upgradeStepsDropdown.push(z.description);
    });
  }

  changeUpdatedMachineModel(selection) {
    this.filterItem.updatedMachineModel = selection === "" ? null : selection;
    this._filter.updateRpcStatusFilter(this.filterItem);
  }

  changeVendorCode(selection) {
    this.filterItem.vendorCode = selection === "" ? null : selection;
    this._filter.updateRpcStatusFilter(this.filterItem);
  }

  changeUpgradeSteps(selection) {
    this.filterItem.upgradeSteps = selection === "" ? null : selection;
    this._filter.updateRpcStatusFilter(this.filterItem);
  }

  changeUpgradeNeededCheckbox() {
    this.filterItem.upgradeNeeded = new Array();
    var yesCheckbox = <HTMLInputElement> document.getElementById("upgradeNeeded_yes");
    if (yesCheckbox.checked) {
      this.filterItem.upgradeNeeded.push('Yes');
    }
    var noCheckbox = <HTMLInputElement> document.getElementById("upgradeNeeded_no");
    if (noCheckbox.checked) {
      this.filterItem.upgradeNeeded.push('No');
    }
    var tbdCheckbox = <HTMLInputElement> document.getElementById("upgradeNeeded_tbd");
    if (tbdCheckbox.checked) {
      this.filterItem.upgradeNeeded.push('TBD');
    }
    this._filter.updateRpcStatusFilter(this.filterItem);
  }

  changeUpgradeCompleteCheckbox() {
    this.filterItem.upgradeComplete = new Array();
    var yesCheckbox = <HTMLInputElement> document.getElementById("upgradeComplete_yes");
    if (yesCheckbox.checked) {
      this.filterItem.upgradeComplete.push('Yes');
    }
    var noCheckbox = <HTMLInputElement> document.getElementById("upgradeComplete_no");
    if (noCheckbox.checked) {
      this.filterItem.upgradeComplete.push('No');
    }
    var naCheckbox = <HTMLInputElement> document.getElementById("upgradeComplete_na");
    if (naCheckbox.checked) {
      this.filterItem.upgradeComplete.push('N/A');
    }
    this._filter.updateRpcStatusFilter(this.filterItem);
  }

  changeTestingStatusCheckbox() {
    this.filterItem.testingStatus = new Array();
    var passCheckbox = <HTMLInputElement> document.getElementById("testingStatus_pass");
    if (passCheckbox.checked) {
      this.filterItem.testingStatus.push('Pass');
    }
    var failCheckbox = <HTMLInputElement> document.getElementById("testingStatus_fail");
    if (failCheckbox.checked) {
      this.filterItem.testingStatus.push('Fail');
    }
    var inProcessCheckbox = <HTMLInputElement> document.getElementById("testingStatus_inProcess");
    if (inProcessCheckbox.checked) {
      this.filterItem.testingStatus.push('In_Process');
    }
    var naCheckbox = <HTMLInputElement> document.getElementById("testingStatus_na");
    if (naCheckbox.checked) {
      this.filterItem.testingStatus.push('N/A');
    }
    this._filter.updateRpcStatusFilter(this.filterItem);
  }

  changeTestingCompleteCheckbox() {
    this.filterItem.testingComplete = new Array();
    var yesCheckbox = <HTMLInputElement> document.getElementById("testingComplete_yes");
    if (yesCheckbox.checked) {
      this.filterItem.testingComplete.push('Yes');
    }
    var noCheckbox = <HTMLInputElement> document.getElementById("testingComplete_no");
    if (noCheckbox.checked) {
      this.filterItem.testingComplete.push('No');
    }
    this._filter.updateRpcStatusFilter(this.filterItem);
  }

  showUpdatedMachineModelError() {

  }

  showVendorCodeError() {

  }

  showUpgradeStepsError() {

  }

  performReset() {
    const umdropdown = document.getElementById("updatedModelDropdown") as HTMLSelectElement;
    umdropdown.selectedIndex = 0;
    this.filterItem.updatedMachineModel = null;
    const vcdropdown = document.getElementById("vendorCodeDropdown") as HTMLSelectElement;
    vcdropdown.selectedIndex = 0;
    this.filterItem.vendorCode = null;
    const usdropdown = document.getElementById("upgradeStepsDropdown") as HTMLSelectElement;
    usdropdown.selectedIndex = 0;
    this.filterItem.upgradeSteps = null;
    const unYesCheckbox = <HTMLInputElement> document.getElementById("upgradeNeeded_yes");
    unYesCheckbox.checked = false;
    const unNoCheckbox = <HTMLInputElement> document.getElementById("upgradeNeeded_no");
    unNoCheckbox.checked = false;
    const unTbdCheckbox = <HTMLInputElement> document.getElementById("upgradeNeeded_tbd");
    unTbdCheckbox.checked = false;
    const ucYesCheckbox = <HTMLInputElement> document.getElementById("upgradeComplete_yes");
    ucYesCheckbox.checked = false;
    const ucNoCheckbox = <HTMLInputElement> document.getElementById("upgradeComplete_no");
    ucNoCheckbox.checked = false;
    const ucNaCheckbox = <HTMLInputElement> document.getElementById("upgradeComplete_na");
    ucNaCheckbox.checked = false;
    const tsPassCheckbox = <HTMLInputElement> document.getElementById("testingStatus_pass");
    tsPassCheckbox.checked = false;
    const tsFailCheckbox = <HTMLInputElement> document.getElementById("testingStatus_fail");
    tsFailCheckbox.checked = false;
    const tsInProcessCheckbox = <HTMLInputElement> document.getElementById("testingStatus_inProcess");
    tsInProcessCheckbox.checked = false;
    const tsNaCheckbox = <HTMLInputElement> document.getElementById("testingStatus_na");
    tsNaCheckbox.checked = false;
    const tcYesCheckbox = <HTMLInputElement> document.getElementById("testingComplete_yes");
    tcYesCheckbox.checked = false;
    const tcNoCheckbox = <HTMLInputElement> document.getElementById("testingComplete_no");
    tcNoCheckbox.checked = false;
    this.filterItem.upgradeNeeded = new Array();
    this.filterItem.upgradeComplete = new Array();
    this.filterItem.testingStatus = new Array();
    this.filterItem.testingComplete = new Array();
  }

}
