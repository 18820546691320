import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/common-service/common.service';
import { SecurityService } from '../../core/security/security.service';
import { ReportsService } from '../reports-service/reports.service';
import {
  Report, ReportSubmitRequest,
  ReportSubmitRequestParm, Report_Param_CSM_Response,
  Report_Param_Route_Response, SKUTolerance, DEXFreq,
  Report_Param_DateRange_Response, Report_Param_DateRange_Responses,
  Report_Param_Route_Responses,
  Report_Param_CSM_Responses,
  Report_Param_Account_Response,
  Report_Param_Period_Responses, Report_Param_Period_Response,
  Report_Param_OperationOrState_Responses, Report_Param_Operation, Report_Param_State, Report_Param_Account_Responses, Parameter, 
  Report_Param_Quarter_Response, Report_Param_Quarter_Responses
} from '../reports-service/reports.model';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Switch } from '../../shared/switch/switch.model';
import { UnAuthorizationReason } from "../../core/auth-claims.model";

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  report: Report;
  parameterValues: ReportSubmitRequestParm[] = new Array();

  isloading = true;
  submitted = false;

  accounts: Report_Param_Account_Response[];
  csms: Report_Param_CSM_Response[];
  routes: Report_Param_Route_Response[];
  periods: Report_Param_Period_Response[];
  quarters: Report_Param_Quarter_Response[];
  operations: Report_Param_Operation[];
  selectedOperations: Report_Param_Operation[];
  states: Report_Param_State[];
  skuTolerances: SKUTolerance[] = new Array<SKUTolerance>();
  skuTolerance: SKUTolerance;
  vendorDEXFreqs: DEXFreq[] = new Array<DEXFreq>();
  vendorDEXFreq: DEXFreq;
  dateRanges: Report_Param_DateRange_Response[];
  fromDate: Date;
  toDate: Date;
  singlePeriodYear: Number;
  singlePeriodPeriod: Number;
  singleState: Report_Param_State;
  singleOperation: Report_Param_Operation;
  singleDate: Date;
  singleDateLabel: string;
  fourColumnParameters: Parameter[] = [];
  threeColumnParameters: Parameter[] = [];

  error = false;
  isReportParamDateRangeError = false;

  operation: string;

  constructor(private _common: CommonService,
    private _security: SecurityService,
    private _reports: ReportsService,
    private _route: ActivatedRoute,
    private _router: Router) {
    this._common.setCurrentPage('Create Report');
  }

  separateParameters() {
    this.threeColumnParameters = [];
    this.fourColumnParameters = [];
    for (let i = 0; i < this.report.parameters.length; i++) {
      const parameter = this.report.parameters[i];
      if (parameter.component  === 'ReportParamAccount') {
        this.fourColumnParameters.push(parameter);
      } else {
        this.threeColumnParameters.push(parameter);
      }
    }
  }

  classForParameter(param: Parameter) {
    const findFourColumnParameter = this.fourColumnParameters.find(x => x.component === param.component);

    if (findFourColumnParameter === undefined) {
      const classes = {
        'col-md-3': 'col-md-3',
        'col-sm-6': 'col-sm-6',
        'col-12': 'col-12'
      };
      return classes;
    } else {
      const classes = {
        'col-md-4': 'col-md-4',
        'col-sm-8': 'col-sm-8',
        'col-12': 'col-12'
      };
      return classes;
    }
  }

  ngOnInit() {
    this.isloading = true;
    const reportTypeId = this._route.snapshot.params['reportTypeID'];
    this._reports.GetForReport(reportTypeId).subscribe((data: Report) => {
      this.report = data;
      this.separateParameters();
      this._common.setCurrentPage('Create Report - ' + data.name);
        this.operation = this._common.operation.name + ' (' + this._common.operation.operationNo + ')';

        for (let i = 0; i < this.report.parameters.length; i++) {

          const parameter = this.report.parameters[i];

          switch (parameter.component) {

            case 'ReportParamAccount':
              this._reports.ParameterAccount(this._common.operation.operationID)
                .subscribe((accountsData: Report_Param_Account_Responses) => {
                  const accountsSorted = accountsData.reportParamAccountResponses;
                  accountsSorted.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                  });
                  this.accounts = accountsSorted;
                  this.accounts.forEach(x => x.selected = new Switch(x.name + ' - ' + x.accountNumber, false, true));
              });
              break;

            case 'ReportParamCSM':
              this._reports.ParameterCSM(this._common.operation.operationID).subscribe((csmData: Report_Param_CSM_Responses) => {
                this.csms = csmData.reportParamCSMResponses;
                this.csms.forEach(x => x.selected = new Switch(x.csmNumber + ' - ' + x.name, false, true));
              });
              break;

            case 'ReportParamRoute':
              this._reports.ParameterRoute(this._common.operation.operationID).subscribe((routeData: Report_Param_Route_Responses) => {
                this.routes = routeData.reportParamRouteResponses;
                this.routes.forEach(x => x.selected = new Switch(x.routeNumber, false, true));
              });
              break;

            case 'ReportParamDateRange':
              this._reports.ParameterDateRange(this._common.operation.operationID)
                      .subscribe((dateRangeData: Report_Param_DateRange_Responses) => {
                this.dateRanges = dateRangeData.reportParamDateRangeResponses;
                this.fromDate = new Date();
                this.toDate = new Date();
              });
              break;

            case 'ReportParamPeriod':
              this._reports.ParameterPeriod().subscribe((periodData: Report_Param_Period_Responses) => {
                this.periods = periodData.reportParamPeriodResponses;
                this.periods.forEach(x => x.selected = new Switch(x.fiscalDisplay, false, true));
              });
              break;

            case 'ReportParamQuarter':
              this._reports.ParameterQuarter().subscribe((quarterData: Report_Param_Quarter_Responses) => {
                this.quarters = quarterData.reportParamQuarterResponses;
                this.quarters.forEach(x => x.selected = new Switch(x.fiscalQuarterDisplay, false, true));
              });
              break;

            case 'ReportParamPeriodEntry':
              // No data to retrieve for this parameter type.  Calculating it, however.
              const nowDateTime: Date = new Date(Date.now());
              const month: Number = nowDateTime.getMonth();
              const year: Number = nowDateTime.getFullYear();
              console.log('month', month, 'year', year);
              if (month >= new Number(10)) {
                this.singlePeriodYear = year.valueOf() + 1;
                this.singlePeriodPeriod = month.valueOf() - 8; // month is base 0
              } else {
                this.singlePeriodYear = year;
                this.singlePeriodPeriod = month.valueOf() + 3; // month is base 0
              }
              break;

            case 'ReportParamSingleDate':
              this.singleDate = new Date();
              this.singleDateLabel = parameter.name;
              break;

            case 'ReportParamOperationOrState':
              console.log('ReportParamOperationOrState subscribed...');
              this._reports.ParameterOperationOrState().subscribe((operationOrStateData: Report_Param_OperationOrState_Responses) => {
                this.operations = operationOrStateData.operations;
                this.operations.forEach(x => x.selected = new Switch(`${x.name}-${x.operationNo}`, false, false));
                // const emptyOperation: Report_Param_Operation = {} as Report_Param_Operation;
                // emptyOperation.name = 'Select operation'; emptyOperation.operationID = 0; emptyOperation.operationNo = '';
                // this.operations.splice(0, 0, emptyOperation);

                this.operations.sort((a, b) => {
                  return a.name.localeCompare(b.name);
                });

                this.states     = operationOrStateData.states;
                const emptyState: Report_Param_State = {} as Report_Param_State;
                emptyState.name = 'Select state'; emptyState.code = '';
                this.states.splice(0, 0, emptyState);
                this.singleState = emptyState;
                this.selectedOperations = [];

                console.log('ReportParamOperationOrState done:', this.operations.length, this.states.length);
              });
              break;

            case 'ReportParamSKUTolerance':
              this.skuTolerances.push(new SKUTolerance(1, '1'));
              this.skuTolerances.push(new SKUTolerance(2, '2'));
              this.skuTolerances.push(new SKUTolerance(3, '3'));
              this.skuTolerances.push(new SKUTolerance(4, '4'));
              this.skuTolerances.push(new SKUTolerance(5, '5'));
              break;

            case 'ReportParamVendorDEXFreq':
              this.vendorDEXFreqs.push(new DEXFreq(2, '2'));
              this.vendorDEXFreqs.push(new DEXFreq(4, '4'));
              break;
          }
        }

        this.isloading = false;
    }, error => {
      if (error.status === 404) {
        this._security.redirectToUnauthorized(UnAuthorizationReason.FunctionPermission);
        return;
      }
    });
  }

  onSetDEXFreq(dex) {
    this.vendorDEXFreq = dex;
  }

  onSetSkuTolerance(sku) {
    this.skuTolerance = sku;
  }

  onFromDate(date) {
    this.fromDate = date;
  }

  onToDate(date) {
    this.toDate = date;
  }

  onReportParamDateRangeError(isError: boolean) {
    this.isReportParamDateRangeError = isError;
  }

  onSinglePeriodYear(periodYear: Number) {
    console.log('create.component.ts: onSinglePeriodYear:' + periodYear.toString());
    this.singlePeriodYear = periodYear;
  }

  onSinglePeriodPeriod(periodPeriod: Number) {
    console.log('create.component.ts: onSinglePeriodPeriod:' + periodPeriod.toString());
    this.singlePeriodPeriod = periodPeriod;
  }

  onSingleState(state: Report_Param_State) {
    console.log('create.component.ts:onSingleState:', state);
    this.singleState = state;
  }

  onSingleOperation(operation: Report_Param_Operation) {
    console.log('create.component.ts:onSingleOperation:', operation);
    this.singleOperation = operation;
  }

  onOperations(operations: Report_Param_Operation[]) {
    console.log('create.component.ts:onOperations', operations);
    this.selectedOperations = operations;
  }

  onSingleDate(date) {
    this.singleDate = date;
  }

  submitReport() {
    this.error = false;
    this.submitted = true;
    const reportRequest = new ReportSubmitRequest();
    reportRequest.costCenterID = Number(this._common.getOperation().operationNo);
    reportRequest.operationID = this._common.getOperation().operationID;
    reportRequest.reportTypeID = this.report.reportTypeID;
    reportRequest.userID = this._security.getUserID();
    reportRequest.format = 'Excel';
    this.parameterValues.push(new ReportSubmitRequestParm('AS_COSTCENTER', this._common.getOperation().operationNo));
    this.parameterValues.push(new ReportSubmitRequestParm('AS_USERNAME', this._security.getUsername()));
    this.parameterValues.push(new ReportSubmitRequestParm('ODBC', this.report.odbc));

    for (let i = 0; i < this.report.parameters.length; i++) {
      const dbName = this.report.parameters[i].dbName;
      let values = '';

      switch (this.report.parameters[i].component) {

        case 'ReportParamAccount':
          const selectedAccounts = this.accounts.filter((a) => {
            return a.selected.input === true;
          });

          if (selectedAccounts.length !== this.accounts.length) {
            values = selectedAccounts.map( (a) => {
              if (dbName.indexOf('NUMBER') > -1) {
                return a.accountNumber;
              } else {
                return a.accountid.toString();
              }
            }).join();
          }
          if (selectedAccounts.length === 0) {
            console.log('Must select at least one Account');
            this.error = true;
          } else {
            this.parameterValues.push(new ReportSubmitRequestParm(dbName, values));
          }
          break;

        case 'ReportParamCSM':
          const selectedCSMS = this.csms.filter(function (obj) {
            return obj.selected.input === true;
          });

          if (selectedCSMS.length !== this.csms.length) {
            values = selectedCSMS.map(function (e) {
              /* TODO: work around for report using CSNNumber rather than CMSID.  Reports need to change to be consistent. */
              if (dbName.indexOf('NUMBER') > -1) {
                return e.csmNumber;
              } else {
                return e.csmid.toString();
              }
            }).join();
          }
          if (selectedCSMS.length === 0) {
            console.log('Must select at least one CSM');
            this.error = true;
          } else {
            this.parameterValues.push(new ReportSubmitRequestParm(dbName, values));
          }
          break;

        case 'ReportParamRoute':
          const selectedRoutes = this.routes.filter(function (obj) {
            return obj.selected.input === true;
          });
          if (selectedRoutes.length !== this.routes.length) {
            values = selectedRoutes.map(function (e) {
              /* TODO: work around for report using RouteNumber rather than RouteID.  Reports need to change to be consistent. */
              if (dbName.indexOf('NUMBER') > -1) {
                return e.routeNumber;
              } else {
                return e.routeID.toString();
              }
            }).join();
          }
          if (selectedRoutes.length === 0) {
            console.log('Must select at least one route');
            this.error = true;
          } else {
            this.parameterValues.push(new ReportSubmitRequestParm(dbName, values));
          }
          break;

        case 'ReportParamDateRange':
          this.error = this.isReportParamDateRangeError;
          /***
          if (!moment(this.fromDate).isValid() || this.fromDate == null) {
            console.log('From cannot be blank');
            this.error = true;
          }
          if (!moment(this.toDate).isValid() || this.toDate == null) {
            console.log('To cannot be blank');
            this.error = true;
          }
          if (moment(this.fromDate).toDate() > moment(this.toDate).toDate()) {
            console.log('From cannot be greater than to');
            this.error = true;
          }
          ***/

          const dbNames = dbName.split(',');
          this.parameterValues.push(new ReportSubmitRequestParm(dbNames[0], this.fromDate));
          this.parameterValues.push(new ReportSubmitRequestParm(dbNames[1], this.toDate));
          break;

        case 'ReportParamPeriod':
          const selectedPeriods = this.periods.filter(function (obj) {
            return obj.selected.input === true;
          });

          if (selectedPeriods.length === 0) {
            console.log('Must select at least one Period');
            this.error = true;
          } else {
            values = selectedPeriods.map(x => x.fiscalPeriodID).toString();
            this.parameterValues.push(new ReportSubmitRequestParm(dbName, values));
          }
          break;

        case 'ReportParamQuarter':
          const selectedQuarters = this.quarters.filter(function (obj) {
            return obj.selected.input === true;
          });

          if (selectedQuarters.length === 0) {
            console.log('Must select at least one Quarter');
            this.error = true;
          } else {
            values = selectedQuarters.map(x => x.fiscalQuarterValue).toString();
            this.parameterValues.push(new ReportSubmitRequestParm(dbName, values));
          }
          break;

          case 'ReportParamPeriodEntry':
            if (this.singlePeriodYear) {
              this.parameterValues.push(
                new ReportSubmitRequestParm(dbName, this.singlePeriodYear.toString() + '-' +
                                                    this.singlePeriodPeriod.toString()));
            }
            break;

        case 'ReportParamSingleDate':
          if (this.singleDate) {
            this.parameterValues.push(
              new ReportSubmitRequestParm(dbName, moment(this.singleDate).format('MM/DD/YYYY'))
            );
          }
          break;

        case 'ReportParamSKUTolerance':
          if (this.skuTolerance == null || this.skuTolerance === undefined) {
            console.log('Select Sku Tolerance');
            this.error = true;
          } else {
            this.parameterValues.push(new ReportSubmitRequestParm(dbName, this.skuTolerance.id.toString()));
          }
          break;

        case 'ReportParamVendorDEXFreq':
          if (this.vendorDEXFreq == null || this.vendorDEXFreq === undefined) {
            console.log('Select Vendor Dex');
            this.error = true;
          } else {
            this.parameterValues.push(new ReportSubmitRequestParm(dbName, this.vendorDEXFreq.id.toString()));
          }
          break;

        case 'ReportParamOperationOrState':
          if (this.singleState !== null && this.singleState.code !== '') {
            this.parameterValues.push(new ReportSubmitRequestParm(dbName, 'StateCode:' + this.singleState.code));
          } else if (this.selectedOperations !== null && this.selectedOperations.length > 0) {
            let operationIDs = '';
            this.selectedOperations.forEach(x => operationIDs = operationIDs + ',' + `${x.operationID}`);
            this.parameterValues.push(new ReportSubmitRequestParm(dbName, 'OperationID:' + operationIDs.substring(1)));
          } else {
            console.log('ReportParamOperationOrState has no valid value');
            this.error = true;
          }
          break;

        default:
          console.log(`Parameter component '${this.report.parameters[i].component}' is not implemented`);
          break;
      }
    }

    console.log('Parameter Values:', this.parameterValues);

    reportRequest.reportSubmitRequestParms = this.parameterValues;

    if (this.error) {
      console.log('Please fix parameter error');
      return;
    }

    console.log('Submitting report');
    this._reports.SubmitReportRequest(reportRequest).subscribe(data => {
      console.log('Report submitted, navigating');
      this._router.navigate(['reports/view']);
    }, (error) => {
      console.log(error);
      console.log(error.message);
    });
  }
}
