import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../core/common-service/common.service';
import { SupportService } from '../support-service/support.service';
import { OperationCloseStatusResult, OperationClosingStatus, WarehouseReconciliationStatus } from '../support-service/support.model';
import { Switch } from '../../shared/switch/switch.model';

@Component({
  selector: 'app-operation-closing-status',
  templateUrl: './operation-closing-status.component.html',
  styleUrls: ['./operation-closing-status.component.scss']
})
export class OperationClosingStatusComponent implements OnInit {

  allOperationClosingStatus: OperationClosingStatus[];
  openOperationStatuses: OperationClosingStatus[];
  closedOperationStatuses: OperationClosingStatus[];
  warehouseReconcileStatuses: WarehouseReconciliationStatus[];
  //messageHeaderStatuses: MessageHeaderStatus[];

  generating: boolean = false;
  loading: boolean = true;
  responseMessage: string;
  errorMessage: string;
  openOperationsCountString: string
  closedOperationsCountString: string

  openOperationSortAsc: boolean = true;
  openOperationSortColumnName: string = "";
  closedOperationSortAsc: boolean = true;
  closedOperationSortColumnName: string = "";
  warehouseReconciliationSortAsc: boolean = true;
  warehouseReconciliationSortColumnName: string = "";

  switchCurrentWeek: Switch = new Switch('Current', false, true);
  switchPreviousWeek: Switch = new Switch('Previous', false, false);

  constructor(private _common: CommonService,
    private _support: SupportService) {
      this._common.setCurrentPage('Operation Closing Status');
    }

  ngOnInit() {
    this.loading = false;
}

  UpdateDisplay() {
    this.generating = true;
    this.errorMessage = null;
    this.responseMessage = null;
    let weekSelectionCode = this.switchCurrentWeek.input ? "CURRENT_WEEK" : "PREVIOUS_WEEK";
    this._support.GetOperationClosingStatus(weekSelectionCode).subscribe((result: OperationCloseStatusResult) => {
      this.generating = false;

      // Load Open and Closed Operation statuses
      this.openOperationStatuses = [];
      this.closedOperationStatuses = [];
      this.allOperationClosingStatus = result.operationClosingStatuses;
        this.allOperationClosingStatus.forEach(status => {
          switch (status.stateOfClosing) {
            case "OPEN":
              this.openOperationStatuses.push(status);
              break;
            case "CLOSED":
              this.closedOperationStatuses.push(status);
          }
        });

        // Load Warehouse Reconciliation statuses
        this.warehouseReconcileStatuses = [];
        result.warehouseReconciliationStatuses.forEach(status => {
          this.warehouseReconcileStatuses.push(status);
        });

        this.openOperationsCountString = this.openOperationStatuses.length.toString();
        this.closedOperationsCountString = this.closedOperationStatuses.length.toString();

        // Sort using the previously used sort columns
        if (this.openOperationSortColumnName != "") {
          this.sortOpenOperationStatuses(this.openOperationSortAsc, this.openOperationSortColumnName, false);
        }
        if (this.closedOperationSortColumnName != "") {
          this.sortClosedOperationStatuses(this.closedOperationSortAsc, this.closedOperationSortColumnName, false);
        }
        if (this.warehouseReconciliationSortColumnName != "") {
          this.sortWarehouseReconciliationStatuses(this.warehouseReconciliationSortAsc, this.warehouseReconciliationSortColumnName, false);
        }

      }, (error) => {
      this.generating = false;
      this.errorMessage = "Failed to get closing status information. " + error.message;
      this.openOperationsCountString = null;
      this.closedOperationsCountString = null;
    });
  }

  toggleSelectedWeek(event: Switch) {

    let switchSelected = !event.input; // opposite of the value that it was changed to

    switch (event.label) {
      case 'Current':
        this.switchPreviousWeek.input = !switchSelected;
        break;
      case 'Previous':
        this.switchCurrentWeek.input = !switchSelected;
        break;
      }
    console.log('toggleSelectedWeek')
    //this.accounts.forEach(x => x.selected.input = !event.input);
    //this.noneSelected(this.accounts);
    //this.accountsChanged.emit(this.accounts);
  }

  isRefreshing() {
    return false;
  }

  saveOpenOperationSort(sortAsc, columnName) {
    this.openOperationSortAsc = sortAsc;
    this.openOperationSortColumnName = columnName;
  }

  saveClosedOperationSort(sortAsc, columnName) {
    this.closedOperationSortAsc = sortAsc;
    this.closedOperationSortColumnName = columnName;
  }

  saveWarehouseReconciliationSort(sortAsc, columnName) {
    this.warehouseReconciliationSortAsc = sortAsc;
    this.warehouseReconciliationSortColumnName = columnName;
  }

  sortOpenOperationStatuses(sortAsc, columnName, boolValue) {
    this.saveOpenOperationSort(sortAsc, columnName);
    switch (columnName) {
      case 'sapImportDate':
      case 'sapCloseDateTime':
      case 'lastValidationDateTime':
        this.openOperationStatuses.sort(this._common.dynamicNullableSort(sortAsc, columnName));
      default:
        this.openOperationStatuses.sort(this._common.dynamicSort(sortAsc, columnName));
    }
}

  sortClosedOperationStatuses(sortAsc, columnName, boolValue) {
    this.saveClosedOperationSort(sortAsc, columnName);
    switch (columnName) {
      case 'closedDateTime':
      case 'sapClosedDate':
      case 'sapExportDate':
        this.closedOperationStatuses.sort(this._common.dynamicNullableSort(sortAsc, columnName));
      default:
        this.closedOperationStatuses.sort(this._common.dynamicSort(sortAsc, columnName));
    }
  }

  sortWarehouseReconciliationStatuses(sortAsc, columnName, boolValue) {
    this.saveWarehouseReconciliationSort(sortAsc, columnName);
    switch (columnName) {
      case 'sentToSAPDateTime':
      case 'importFromSAPDateTime':
      case 'sapExportDate':
      case 'meReportDateTime':
      case 'pomeStatus':
      case 'warehouseCloseDateTime':
        this.warehouseReconcileStatuses.sort(this._common.dynamicNullableSort(sortAsc, columnName));
        default:
        this.warehouseReconcileStatuses.sort(this._common.dynamicSort(sortAsc, columnName));
      }
  }

  selectOperationStatus(event, operationStatus) {
  }

}
