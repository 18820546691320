export interface DeviceModifyInfoReponse {
  deviceModifyRecord: DeviceModifyInfo;
  statusList: DeviceStatus[];
  disposalReasonList: DeviceStatus[];
  vendorList: VendorInfo[];
  deviceModelList: DeviceModelInfo[];
}

export interface DeviceStatus {
  iVendLookupID: number;
  description: string;
}

export interface VendorInfo {
  viVendorID: number;
  vendorCode: string;
  name: string;
}

export interface DeviceModelInfo {
  viDeviceModelID: number;
  viDeviceManufacturerID: number;
  vendorCode: string;
  manufacturerName: string;
  deviceModel: string;
}

export interface DeviceModifyInfo {
  viDeviceID: number;
  viDeviceModelID: number;
  deviceSerialNo: string;
  initialCurrentStartDate: Date | null;
  initialCurrentEndDate: Date | string | null;
  currentStartDate: Date | null;
  currentEndDate: Date | string | null;
  totalNotMapped: number;
  latestNotMappedDateTime: Date | string | null;
  suggestedStartDateTime: Date | null;
  latestTransactionMapped: Date | null;
  earliestNotMappedDateTime: Date | null;
  mostRecentDeviceStartDateTime: Date | null;
  lastFullFinalServiceDateTime: Date | null;
  vendorCode: string;
  deviceStatus: string;
  reason: string;
  deviceNo: string;
  twoTierDeviceIndicator: Date | null;
  lastVendorDexDateTimeIndicator: Date | null;
  acquiringOperation: number;
  acquiringOperationName: string;
  costCenterAcquireDate: Date | null;
  costCenterLatest: number | null;
  costCenterNameLatest: string;
  costCenterCurrent: number | null;
  costCenterNameCurrent: string;
  operationGoLiveDate: Date | null;
  accountCurrent: string;
  locationCurrent: string;
  machineCurrent: number;
  routeNumberCurrent: number | null;
  latestTransactionCurrent: Date | string | null;
  lastFullFinalServiceDateTimeCurrent: Date | null;
  deviceStartDateCurrent: Date | string | null;
  deviceEndDateCurrent: Date | null;
  deviceNotesCurrent: string;
  costCenterPrior: number | null;
  costCenterNamePrior: string;
  accountPrior: string;
  locationPrior: string;
  machinePrior: number | null;
  routeNumberPrior: number | null;
  latestTransactionPrior: Date | string | null;
  lastFullFinalServiceDateTimePrior: Date | null;
  deviceStartDatePrior: Date | string | null;
  deviceEndDatePrior: Date | null;
  deviceNotesPrior: string;
  disposalReason: number | null;
  routeMachineDescCurrent: string;
  routeMachineDescPrior: string;
  viSalesTxnInd: boolean;
  viDeviceEventInd: boolean;
  modifiedByUser: string;
  modifiedDateTime: Date | string | null;
}

export class DeviceAssignValidation {
  deviceCostCenterId: number;
  deviceCostCenterName: string;
  systemOfRecordDate: Date | null;
}

export class DeviceAssignInfo {
  installDate: Date | null;
  newInstallDate: string | Date;
  deviceRecommendedInstallDate: Date | null;
  lastInstallDateNotWarehouse: Date | null;
  deviceSerialNo: string;
  deviceNo: string;
  deviceCostCenterId: number;
  deviceCostCenterName: string;
  deviceLastTransactionAsCollections: Date | null;
  deviceEarliestTransactionAsNonCollections: Date | null;
  deviceTotalAmountNotMapped: number;
  deviceNotes: string;
  deviceStatus: string;
  deviceAccount: string;
  deviceLocation: string;
  deviceInstalled: Date | null;
  deviceEndDate: Date | null;
  deviceMachineNumber: string;
  deviceMachineDispenseType: string;
  deviceMachineLastFullServiceDate: Date | null;
  targetMachineNumber: string;
  targetMachineCostCenterId: number;
  targetMachineCostCenterName: string;
  targetMachineStatus: string;
  targetMachineRevenueGeneratingInd: number;
  targetMachineFloorStatus: string;
  targetMachineAccount: string;
  targetMachineLocation: string;
  targetMachineDispenseType: string;
  targetMachineServicedByType: string;
  targetMachineLastFullServiceDate: Date | null;
  deviceError: number;
  machineError: number;
  deviceMachineId: number;
  targetMachineId: number;
  systemOfRecordDate: Date | null;
}

export class DeviceAssignPersist {
  deviceSerialNo: string;
  newStartDate: Date | string | null;
  machineId: number;
  userId: number;
  notes: string;
  recomendedStartDate: Date | string | null;
  costCenter: number;
}

export class ModifyDevice {
  deviceSerialNo: string;
  newStartDate: Date | string | null;
  newEndDate: Date | string | null;
  deviceStatus: number | null;
  disposalReason: number | null;
  userID: number;
  deviceModifyRecord: DeviceModifyInfo;
  proceed: boolean;
}

export class ValidateDates {
  deviceModifyRecord: DeviceModifyInfo;
  newStartDate: Date | null;
  newEndDate: Date | null;
}

export interface ValidateResult {
  passedValidation: boolean;
  confirmationNeeded: boolean;
  message: string;
}

export interface Operation {
  operationID: number;
  costCenterID: number;
  costCenter: string;
}

export class CostCenterInventoryDevice {
  currentCostCenterId: number;
  viDeviceID: number;
  device: string;
  selected: boolean;
  hidden: boolean;
}

export class TransferDeviceRecord {
  viDeviceID: number;
  currentCostCenterId: number;
  currentDeviceStatusLookupId: number;
  userID: number;
}

export interface TransferDeviceResponse {
  viDeviceIDSucess: number[];
  viDeviceIDFail: number[];
}

export class NewDevice {
  vendorCode: string;
  deviceNumber: string;
  model: string;
  dateReceived: Date;
  costCenter: number;
  userId: number;
}

export class CashlessDeviceInventoryItem {
  viDeviceId: number;
  currentCostCenterId: number;
  vendorCode: string;
  deviceNo: string;
  inventoryStatus: string;
  addedToInventory: Date;
  name: string;
  daysInInventory: number;
  lastVerificationDateTime: Date | null;
  lastVerifiedByUserId: number | null;
  lastVerifiedByName: string;
  daysSinceLastVerification: number;
  selected: boolean;
}

export class CashlessDeviceInventoryItemUpdate {
  viDeviceIdList: string;
  userId: number;
}

export class CashlessDeviceInventoryExcelExportItem {
  CostCenter: number;
  DeviceNo: string;
  VendorCode: string;
  InventoryStatus: string;
  AddedToInventoryOn: string;
  ModifiedBy: string;
  DaysInInventory: number;
  LastVerifiedOn: string;
  LastVerifiedBy: string;
  DaysSinceVerification: number;
}