import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { AuthClaims, AuthToken, OrgAccess, UnAuthorizationReason } from '../auth-claims.model';
import { CommonService } from '../common-service/common.service';
import { AppsettingsProvider } from '../appsettings.provider';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Operation } from '../operation/operation.model';

@Injectable()
export class SecurityService {
  authClaims: AuthClaims;
  token: string;
  availableOperations: Operation[] = [];
  operations: BehaviorSubject<Operation[]> = new BehaviorSubject<Operation[]>([]);
  operation: BehaviorSubject<Operation> = new BehaviorSubject<Operation>(null);
  appSettings: any;
  lastOperation: string;

  constructor(private _http: HttpClient,
    private _common: CommonService,
    private _router: Router,
    private appSettingsProvider: AppsettingsProvider) {
    this.appSettings = this.appSettingsProvider.getSettings();
  }

  getUserID() {
    return this.authClaims.dataObject.userID;
  }

  getUsername() {
    return this.authClaims.dataObject.userName;
  }

  login(code: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('omsCode', code);
    return this._http.get<AuthToken>(this.appSettings.urlLocations.securityServiceURL + 'login', {params})
      .map((response: AuthToken) => {
        if (response &&
          response.oneFoundationData &&
          response.oneFoundationData.dataObject &&
          response.oneFoundationData.dataObject.authenticated) {
          this.authClaims = response.oneFoundationData;
          localStorage.setItem('access_token', response.jwtToken);
          localStorage.setItem('refresh_token', response.jwtRefreshToken);
          localStorage.setItem('username', response.oneFoundationData.dataObject.userName);
          this.availableOperations = this.getAvailableOperations(response.oneFoundationData.dataObject.orgAccess);

          this.operations.next(this.availableOperations);

          localStorage.setItem('authClaims', JSON.stringify(this.authClaims));
          localStorage.setItem('availableOperations', JSON.stringify(this.availableOperations));

          this.setOperationDuringLogin();

          return response;
        } else {
          this.redirectToUnauthorized(UnAuthorizationReason.ServiceError);
          return;
        }
      });
  }

  getAvailableOperations(orgAccess: OrgAccess) {
    const availableOperations = [];
    const operationsFromToken = this.getOperationsFromToken();

    for (let i = 0; i < orgAccess.companyAccessList.length; i++) {
      for (let op = 0; op < orgAccess.companyAccessList[i].operationAccessList.length; op++) {
        const isOperationFromToken = operationsFromToken &&
          operationsFromToken.includes(orgAccess.companyAccessList[i].operationAccessList[op].operationNo);
        if (orgAccess.companyAccessList[i].operationAccessList[op].allow && isOperationFromToken) {
          availableOperations.push(orgAccess.companyAccessList[i].operationAccessList[op]);
        }
      }
    }
    return availableOperations;
  }

  isLoggedIn() {
    return !!localStorage.getItem('access_token');
  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  logOff() {
    const token = localStorage.getItem('refresh_token');
    this.removeLogonData();

    if (!token) {
      return of({status: '500', message: 'Token not found.'});
    }

    const helper = new JwtHelperService();
    const tokenPayload = helper.decodeToken(token);
    return this.omsLogOff(tokenPayload.omsToken);
  }

  removeLogonData() {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('username');
    localStorage.removeItem('authClaims');
    localStorage.removeItem('availableOperations');
  }

  omsLogOff(omsToken: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'AccessToken': omsToken,
    });
    headers.set('Content-Type', 'application/json').set('AccessToken', omsToken);
    return this._http.post(this.appSettings.urlLocations.securityServiceURL + 'logoff', {}, {headers});
  }

  setOperationDuringLogin() {
    if (this.availableOperations.length > 0) {
      this.lastOperation = localStorage.getItem('operationNo');
      if (this.lastOperation) {
        const operation = this.availableOperations.find(org => org.operationNo === this.lastOperation);
        this.operation.next(operation);
        if (operation) {
          this._common.setOperation(operation);
        } else {
          this._common.setOperation(this.availableOperations[0]);
        }
      } else {
        this._common.setOperation(this.availableOperations[0]);
      }
    } else {
      if (!this.getUnauthorizedReasonFromToken()) {
        this.cleanupAndNavigateToHome();
      }
    }
  }

  refreshState() {
    if (this.getUnauthorizedReasonFromToken()) {
      return;
    }

    const authClaims = localStorage.getItem('authClaims');
    this.authClaims = authClaims ? JSON.parse(authClaims) : '';

    const operationsFromLocalStorage = localStorage.getItem('availableOperations');
    const operations = operationsFromLocalStorage ? JSON.parse(operationsFromLocalStorage) : [];

    if (operations.length > 0) {
      this.availableOperations = this.filterOperations(operations);
      if (this.availableOperations && this.availableOperations.length > 0) {
        this.operations.next(this.availableOperations);
        this.setOperationDuringLogin();
      } else {
        this.cleanupAndNavigateToHome();
      }
    } else {
      this.cleanupAndNavigateToHome();
    }
  }

  cleanupAndNavigateToHome() {
    this.removeLogonData();
    this._router.navigate(['home']);
  }

  refreshToken() {
    let refreshToken = localStorage.getItem('refresh_token');
    refreshToken = '\"' + refreshToken + '\"';
    return this._http.post(this.appSettings.urlLocations.securityServiceURL +
      'refreshToken', refreshToken, { 'headers': {'Content-Type': 'application/json'}});
  }

  redirectToUnauthorized(reason: string) {
    this._router.navigate(['unauthorized'], { queryParams: { reason: reason }});
  }

  getUnauthorizedReasonFromToken(): string {
    const token = this.getAccessToken();
    const helper = new JwtHelperService();
    const tokenPayload = helper.decodeToken(token);

    return tokenPayload ? tokenPayload.unauthorizedReason : '';
  }

  getOperationsFromToken(): string[] {
    const token = this.getAccessToken();
    const helper = new JwtHelperService();
    const tokenPayload = helper.decodeToken(token);

    return tokenPayload && tokenPayload.operations ? JSON.parse(tokenPayload.operations) : [];
  }

  filterOperations(operations: Operation[]): Operation[] {
    const operationsFromToken = this.getOperationsFromToken();
    if (!operationsFromToken.length) {
      return null;
    }

    return operations.filter(x => operationsFromToken.includes(x.operationNo));
  }

  updateOperationsOnTokenRefresh(orgAccess: OrgAccess) {
    this.availableOperations = this.getAvailableOperations(orgAccess);
    localStorage.setItem('availableOperations', JSON.stringify(this.availableOperations));
    this.operations.next(this.availableOperations);
    this.setOperationDuringLogin();
  }
}
