import { Component, OnInit, OnDestroy } from '@angular/core';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';
import { ISubscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-viewed',
  templateUrl: './viewed.component.html',
  styleUrls: ['./viewed.component.scss']
})
export class ViewedComponent implements OnInit, OnDestroy {

  viewed: any[];
  viewedSubscription: ISubscription;

  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this.viewedSubscription = this._filter.viewed.subscribe(data => {
      this.viewed = data;
    });
  }

  ngOnDestroy() {
    this.viewedSubscription.unsubscribe();
  }

}
