import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../core/common-service/common.service';
import { DeviceManagementService } from '../../device/device-service/device-management.service';
import { SupportService } from '../support-service/support.service';
import { Operation } from '../../device/device-service/device-management.model';
import { WarehousePhysicalInventoryOperationRequest,
         WarehousePhysicalInventoryOperationResult,
         WarehouseItem,
         PhysicalInventoryCountItem,
         OperationError} from '../support-service/support.model';

@Component({
  selector: 'app-generate-zeros',
  templateUrl: './generate-zeros.component.html',
  styleUrls: ['./generate-zeros.component.scss']
})
export class GenerateZerosComponent implements OnInit {

  allOperations: Operation[];
  filteredOperations: Operation[];
  warehouses: WarehouseItem[];
  inventoryDates: PhysicalInventoryCountItem[];
  selectedWarehouse: WarehouseItem;
  selectedOperation: Operation;
  selectedInventoryDate: PhysicalInventoryCountItem;
  generateZerosErrors: OperationError[];
  notYetGenerated: boolean;
  generating: boolean;

  @ViewChild('operationFilter') operationFilter;

  constructor(private _common: CommonService,
    private _device: DeviceManagementService,
    private _support: SupportService) {
      this._common.setCurrentPage('Generate Zeros');
    }

  ngOnInit() {
    this.inventoryDates = [];
    this.warehouses = [];
    this.allOperations = [];
    this.filteredOperations = [];
    this.generateZerosErrors = [];
    this.notYetGenerated = true;
    this.generating = false;

    this._device.GetOperations().subscribe((ops: Operation[]) => {
      this.allOperations = ops;
      this.filteredOperations = ops;
      this.selectedOperation = ops[0];
      this.warehouses = [];

    });

  }

  selectOperation(operation: Operation) {
    this.selectedOperation = operation;
    this.selectedWarehouse = null;
    this.selectedInventoryDate = null;
    this.notYetGenerated = true;

    this._support.GetWarehouses(operation.costCenterID).subscribe((whs: WarehouseItem[]) => {
      this.warehouses = whs;
      this.selectedWarehouse = whs[0];
      this.inventoryDates = [];
      this.selectWarehouse(this.selectedWarehouse);
    });

  }

  selectWarehouse(warehouse: WarehouseItem) {
    this.selectedWarehouse = warehouse;
    this.selectedInventoryDate = null;
    this.notYetGenerated = true;

    this._support.GetPhysicalInventoryCountDates(warehouse.costCenterID, warehouse.warehouseID)
    .subscribe((pics: PhysicalInventoryCountItem[]) => {
      this.inventoryDates = pics;
      this.selectedInventoryDate = pics[0];
      this.selectInventoryDate(this.selectedInventoryDate);
    });
  }

  selectInventoryDate(inventoryDate: PhysicalInventoryCountItem) {
    this.selectedInventoryDate = inventoryDate;
    this.notYetGenerated = true;
  }

  GenerateZeros() {
    this.notYetGenerated = true;
    const warehousePIRequest: WarehousePhysicalInventoryOperationRequest = {
      costCenterID: this.selectedOperation.costCenterID,
      warehouseID: this.selectedWarehouse.warehouseID,
      physicalInventoryID: this.selectedInventoryDate.physicalInventoryID,
      physicalInventoryGuid: this.selectedInventoryDate.physicalInventoryGUID
    };

    this.generating = true;
    this.generateZerosErrors = [];
    this._support.GenerateZeroes(warehousePIRequest).subscribe((result: WarehousePhysicalInventoryOperationResult) => {
      this.generateZerosErrors = result.errors;
      this.notYetGenerated = false;
      this.generating = false;
    });
  }

  filterOperations() {
    const filterText = this.operationFilter.nativeElement.value;
    this.filteredOperations =
      this.allOperations.filter(item => item.costCenter.toLowerCase().includes(filterText.toLowerCase()));
  }

}
