import { Component, OnInit } from '@angular/core';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';
import { ISubscription } from 'rxjs-compat/Subscription';

@Component({
  selector: 'app-photos-filter',
  templateUrl: './photos-filter.component.html',
  styleUrls: ['./photos-filter.component.scss']
})
export class PhotosFilterComponent implements OnInit {
  resetPhoto:boolean;
  public photosOnly: any;
  photoSubscription: ISubscription;
  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this.resetPhoto = true;
  }
  
  ngDoCheck() {
    this.resetPhoto = this._filter.photosOnly.value;
  }
  
  photosCheck(event)
  {
    this.photosOnly = (event.target.checked);
    this._filter.photosOnly.next(this.photosOnly); 
  }
}
