import { Switch } from '../../shared/switch/switch.model';

export interface ReportGroupWrapper {
  reportGroupList: ReportGroup[];
}

export interface ReportGroup {
  name: string;
  reports: Report[];
}

export interface Report {
  name: string;
  description: string;
  reportTypeID: number;
  displayInd: boolean;
  applicationFunctionCode: string;
  parameters: Parameter[];
  odbc: string;
  excelOptionInd: boolean;
  pdfOptionInd: boolean;
}

export interface Parameter {
  type: string;
  name: string;
  dbName: string;
  dataType: string;
  column: number;
  row: number;
  colspan: number;
  selectCode: boolean;
  defaultToAll: boolean;
  data: any;
  validations: string;
  validationTextDisplay: string;
  dayRange: number;
  component: string;
}

export class GridRequest {
  pageNumber: number;
  recordsPerPage: number;
  sortColumn: string;
  sortDirection: string;
  filters: FilterRequest[];
  parms: string[];
}

export interface FilterRequest {
  filterName: string;
  filterItemID: string;
  filterItemName: string;
}

export class FilterDataRequest {
  operationID: number;
  filterRequests: FilterRequest[];
}

export class FilterRequest {
  filterName: string;
  filterItemID: string;
  filterItemName: string;
}

export interface Filter {
  name: string;
  data: FilterItem[];
}

export interface FilterItem {
  selected: boolean;
  id: string;
  name: string;
  total: number;
}

export interface FilterResponse {
  totalCount: number;
  filters: Filter[];
}

export interface ReportSearchResponse {
  reportSearchLineItems: ReportSearchLineItem[];
}

export interface ReportSearchLineItem {
  reportName: string;
  submitted: string;
  submittedDateLine1: string;
  submittedDateLine2: string;
  submittedDateLine3: string;
  userName: string;
  status: string;
  viewed: string;
  type: string;
  pages: number;
  reportExecutionID: number;
  executionReferenceName: string;
  downloadNotAvailable: boolean;
}

export class ReportSubmitRequest {
  reportTypeID: number;
  userID: number;
  operationID: number;
  costCenterID: number;
  reportSubmitRequestParms: ReportSubmitRequestParm[];
  format: string;
}

export class ReportSubmitRequestParm {
  dbName: string;
  value: string;
  constructor(dbName, value) {
    this.dbName = dbName;
    this.value = value;
  }
}

export interface FindSelect_User_Responses {
  findSelectUserResponses: FindSelect_User_Response[];
}

export interface FindSelect_User_Response {
  userID: number;
  name: string;
  userName: string;
}

export interface FindSelect_User_Request {
  keywordText: string;
  operationID: number;
}

export interface Report_Param_CSM_Responses {
  reportParamCSMResponses: Report_Param_CSM_Response[];
}

export interface Report_Param_CSM_Response {
  csmid: number;
  csmNumber: string;
  name: string;
  routeCount: number;
  selected: Switch;
}

export interface Report_Param_DateRange_Responses {
  reportParamDateRangeResponses: Report_Param_DateRange_Response[];
}

export interface Report_Param_DateRange_Response {
  id: number;
  targetCloseDate: Date | string;
  weekEndDateString: string;
  weekEndDateStringDescription: string;
  weekStartDateString: string;
  weekStartDateStringDescription: string;
  status: string;
}

export interface Report_Param_Route_Responses {
  reportParamRouteResponses: Report_Param_Route_Response[];
}

export interface Report_Param_Route_Response {
  routeID: number;
  routeNumber: string;
  name: string;
  csm: string;
  selected: Switch;
}

export interface Report_Param_Period_Responses {
  reportParamPeriodResponses: Report_Param_Period_Response[];
}

export interface Report_Param_Period_Response {
  fiscalPeriodID: number;
  fiscalDisplay: string;
  selected: Switch;
}

export interface Report_Param_Quarter_Responses {
  reportParamQuarterResponses: Report_Param_Quarter_Response[];
}

export interface Report_Param_Quarter_Response {
  fiscalYear: number;
  fiscalQuarter: number;
  fiscalQuarterDisplay: string;
  fiscalQuarterValue: string;
  selected: Switch;
}

// --------------------------------------------------------------------

export interface Report_Param_State {
  code: string;
  name: string;
  selected: Switch;
}

export interface Report_Param_Operation {
  operationID: number;
  operationNo: string;
  name: string;
  selected: Switch;
}

export interface Report_Param_OperationOrState_Responses {
  states: Report_Param_State[];
  operations: Report_Param_Operation[];
}

export class SKUTolerance {
  id: number;
  name: string;
  constructor(id, name) {
      this.id = id;
      this.name = name;
  }
}

export class DEXFreq {
  id: number;
  name: string;
  constructor(id, name) {
      this.id = id;
      this.name = name;
  }
}

export interface Report_Param_Account_Response {
  accountid: number;
  accountNumber: string;
  name: string;
  selected: Switch;
}

export interface Report_Param_Account_Responses {
  reportParamAccountResponses: Report_Param_Account_Response[];
}


