import { Component, OnInit } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart } from '@angular/router';
import { CommonService } from './core/common-service/common.service';
import { SecurityService } from './core/security/security.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'OneVend';
  lastOperation = null;
  isShowOperationWarning = false;

  constructor(
    private _common: CommonService,
    private _securityService: SecurityService,
    private _router: Router) {
    _router.events.subscribe((event: RouterEvent) => {
      this.navigationListener(event);
    });
  }

  closeAlert() {
    this.isShowOperationWarning = false;
  }

  ngOnInit() {
    if (this._securityService.isLoggedIn()) {
      this._securityService.refreshState();
      this._securityService.operation.subscribe(data => {
        this.isShowOperationWarning = this._securityService.availableOperations && this._securityService.availableOperations.length > 0 && !data;
        this.lastOperation = this._securityService.lastOperation;
        if (this.isShowOperationWarning) {
          setTimeout(() => this.isShowOperationWarning = false, 10000);
        }
      });
    }
  }

  getNavigationPaneState() {
    return this._common.getNavigationPane();
  }

  navigationListener(event) {
    if (event instanceof NavigationStart) {
      this._common.setNavigationPane('in');
    }
  }
}
