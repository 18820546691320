import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Report_Param_Account_Response } from '../../reports-service/reports.model';
import { Switch } from '../../../shared/switch/switch.model';


@Component({
  selector: 'app-rpt-account',
  templateUrl: './rpt-account.component.html',
  styleUrls: ['./rpt-account.component.scss']
})
export class RptAccountComponent implements OnInit {

  @Input()
  accounts: Report_Param_Account_Response[];

  @Output()
  accountsChanged = new EventEmitter();

  accountDisplay: string;
  selectAll = new Switch('Select All', false, true);
  errorMessage = '';

  constructor() { }

  ngOnInit() {
  }


  toggleSelectAllAccounts(event: Switch) {
    this.accounts.forEach(x => x.selected.input = !event.input);
    this.noneSelected(this.accounts);
    this.accountsChanged.emit(this.accounts);
  }

  accountOnClick(event: Switch, account: Report_Param_Account_Response) {
    const tempArray = this.accounts.filter(x => x.accountid !== account.accountid);
    // Uses bool directly from event to avoid 2 way binding delay
    const eventBool = !event.input;
    this.selectAll.input = tempArray.every(x => x.selected.input === true) &&  eventBool === true;
    this.noneSelected(tempArray, eventBool);
    this.accountsChanged.emit(this.accounts);
  }

  // Pass extra bool to deal with 2 way binding delay
  noneSelected(inputArray, retrievedBool = null) {
    this.errorMessage = inputArray.every(x => x.selected.input === false) && (retrievedBool == null || retrievedBool === false)
    ? 'Account is Required' : '';
  }
}
