import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../common-service/common.service';
import {SecurityService} from '../security/security.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentPage = 'Home';

  constructor(
      private _common: CommonService,
      private _securityService: SecurityService
  ) {
  }

  ngOnInit() {
  }

  getCurrentPage() {
    return this._common.getCurrentPage();
  }

  toggleNavigationPane() {
    this._common.toggleNavigationPane();
  }

  checkLogin() {
    return this._securityService.isLoggedIn();
  }

  isUnauthorizedReason() {
    return !!this._securityService.getUnauthorizedReasonFromToken();
  }
}
