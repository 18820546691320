import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../core/common-service/common.service';
import { OperationsService } from '../../operation-service/operations.service';
import { FiscalCloseConfigs, FiscalCloseValidations, CashlessTransactionTypes } from '../../operation-service/fiscal-closing.model';

@Component({
  selector: 'app-closing-configs',
  templateUrl: './closing-configs.component.html',
  styleUrls: ['./closing-configs.component.scss']
})
export class ClosingConfigsComponent implements OnInit {

  validationSprocs: FiscalCloseValidations[];
  cashlessTransactions: CashlessTransactionTypes[];
  displayedConfig: string;

  constructor(
    private _common: CommonService,
    private _operations: OperationsService) {
      this._common.setCurrentPage('Fiscal Close Configs');
    }

  ngOnInit() {
    this.validationSprocs = [];
    this.cashlessTransactions = [];
    this.displayedConfig = 'closing';
    this._getConfigs();
  }

  _getConfigs() {
    this._operations.GetFiscalClosingConfigs().subscribe((data) => {
      this.validationSprocs = data.validationSprocs;
      this.cashlessTransactions = data.transactionTypes;

      // Label validation sprocs with iteration index
      for (let i = 0; i < this.validationSprocs.length; i++) {
        this.validationSprocs[i].index = i + 1;
      }

    });
  }

}
