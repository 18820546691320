import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Report_Param_Operation, Report_Param_State } from '../../reports-service/reports.model';
import { Switch } from '../../../shared/switch/switch.model';

@Component({
  selector: 'app-rpt-operation-or-state',
  templateUrl: './rpt-operation-or-state.component.html',
  styleUrls: ['./rpt-operation-or-state.component.scss']
})
export class RptOperationOrStateComponent implements OnInit {

  @Input()
  states: Report_Param_State[];

  @Input()
  operations: Report_Param_Operation[];

  @Output()
  stateChanged = new EventEmitter<Report_Param_State>();

  @Output()
  operationsChanged = new EventEmitter<Report_Param_Operation[]>();

  selectedState: Report_Param_State;
  selectedStateCode: string;

  stateDisplay: string;
  errorMessage = '';

  unselectAllOperations() {
    this.operations.forEach(x => x.selected.input = false);
    this.operationsChanged.emit(null);

    const tempArray = this.operations.filter(x => x.selected.input);
    this.noneSelected(tempArray, null);
  }

  unselectState() {
    this.selectedState = this.states[0];
    this.selectedStateCode = this.selectedState.code;
    this.stateChanged.emit(null);

    const tempArray = this.operations.filter(x => x.selected.input);
    this.noneSelected(tempArray, null);
  }

  updateSelectedState(stateCode: string) {
    console.log('updateSelectedState:', stateCode);

    this.unselectAllOperations();

    this.selectedState = this.states.find(x => x.code === stateCode);
    this.stateChanged.emit(this.selectedState);

    const tempArray = this.operations.filter(x => x.selected.input);
    this.noneSelected(tempArray, null);
  }

  operationOnClick(event: Switch, operation: Report_Param_Operation) {
    const tempArray = this.operations.filter(x => x.operationID !== operation.operationID && x.selected.input);

    // Uses bool directly from event to avoid 2 way binding delay.  Add the evented one if it is switched to on.
    const eventBool = !event.input;
    console.log('operationOnClick:Operation is switched?', operation, eventBool);
    if (eventBool) {
      tempArray.push(operation);
    }

    if (tempArray.length > 0) {
      this.unselectState();
    }

    this.noneSelected(tempArray, eventBool);
    this.operationsChanged.emit(tempArray);
  }

  constructor() { }

  ngOnInit() {
    this.selectedStateCode = '';
  }

   // Pass extra bool to deal with 2 way binding delay
  noneSelected(inputArray, retrievedBool = null) {
    console.log('state is selected?', this.selectedStateCode !== '');

    this.errorMessage =
      (
        inputArray.every(x => x.selected.input === false) &&
        (retrievedBool == null || retrievedBool === false)
      ) && this.selectedStateCode === ''
    ? 'Operation or State is Required' : '';
  }
}
