import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuardService } from '../core/auth-guard/auth-guard.service';
import { SupportService } from './support-service/support.service';
import { GenerateZerosComponent } from './generate-zeros/generate-zeros.component';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SapExportComponent } from './sap-export/sap-export.component';
import { RoleGuardService } from '../core/role-guard/role-guard.service';
import { SapExportService } from './sap-export-service/sap-export.service';
import { OperationClosingStatusComponent} from './operation-closing-status/operation-closing-status.component';
import { MdmInstallBaseShareComponent } from './mdm-install-base-share/mdm-install-base-share.component';

const routes: Routes = [
  { path: 'support/generate-zeros',
    component: GenerateZerosComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'WH_IVEND_GENERATE_ZEROES'} },
  { path: 'support/sap-export',
    component: SapExportComponent, canActivate: [AuthGuardService] },
  { path: 'support/operation-closing-status',
    component: OperationClosingStatusComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'TECHNICAL_SUPPORT_MENU_ACCESS'} },
  { path: 'support/mdm-install-base-share',
    component: MdmInstallBaseShareComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'MDM_INSTALL_BASE_SHARE'} }
];

@NgModule({
  declarations: [
    GenerateZerosComponent,
    SapExportComponent,
    OperationClosingStatusComponent,
    MdmInstallBaseShareComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CoreModule,
    SharedModule,
    NgbModule,
  ],
  providers: [SupportService,
    SapExportService]
})
export class SupportModule { }
