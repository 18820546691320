import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

import { NoServiceReviewComponent } from './no-service-review/no-service-review.component';
import { AuthGuardService } from '../core/auth-guard/auth-guard.service';
import { NoServiceReviewService } from './no-service-review-service/no-service-review.service';

const routes: Routes = [
  { path: 'routes/no-service-review', component: NoServiceReviewComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule.forChild(routes),
    CoreModule,
    SharedModule
  ],
  declarations: [
    NoServiceReviewComponent
  ],
  providers: [
    NoServiceReviewService
  ]
})
export class RoutesModule { }
