import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppsettingsProvider } from '../../core/appsettings.provider';
import { SecurityService } from '../../core/security/security.service';
import {
  FiscalCloseSummary, FiscalCloseDetail,
  FiscalCloseValidationResult, FiscalCloseValidationStatus,
   FiscalCloseConfigs } from './fiscal-closing.model';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  appSettings: any;

  constructor(private _http: HttpClient,
    private _security: SecurityService,
    private appSettingsProvider: AppsettingsProvider) {
    this.appSettings = this.appSettingsProvider.getSettings();
  }

  
  RequestFiscalWeekClose(operationNo: string, operationID: number, fiscalCalendarID: number, onOneWarehouse: boolean, userID: number) {
    return this._http.post<FiscalCloseValidationResult>(this.appSettings.urlLocations.fiscalClosingURL + 'close', 
                                                       { CostCenterID: operationNo, OperationID: operationID, FiscalCalendarID: fiscalCalendarID, OnOneWarehouse:onOneWarehouse, UserId: userID});
  }
  
  GetFiscalClosingSummary(operationNo: number) {
    return this._http.get<FiscalCloseSummary[]>(this.appSettings.urlLocations.fiscalClosingURL + 'summary/' + operationNo);
  }

  GetFiscalClosingDetail(operationNo: number, fiscalCalendarID: Number) {
    return this._http.get<FiscalCloseDetail>(this.appSettings.urlLocations.fiscalClosingURL + 'detail/' + operationNo + "/" + fiscalCalendarID);
  }

  GetValidationStatus(operationNo: number, fiscalCalendarID: number) {
    return this._http.get<FiscalCloseValidationStatus>(this.appSettings.urlLocations.fiscalClosingURL + 'status/' + operationNo + '/' + fiscalCalendarID);
  }

  RunCashless(operationNo: number, operationID: number, fiscalCalendarID: Number, onOneWarehouse: boolean, userID: number) {
    return this._http.get<FiscalCloseValidationResult>(this.appSettings.urlLocations.fiscalClosingURL + 'cashless/' + operationNo + "/" + operationID + "/" + fiscalCalendarID + "/" + onOneWarehouse + "/" + userID);
  }

  ValidateFiscalWeek(operationNo: number, operationID: number, fiscalCalendarID: number, onOneWarehouse: boolean, userID: number) {
    return this._http.get<FiscalCloseValidationResult>(this.appSettings.urlLocations.fiscalClosingURL + 'validate/' + operationNo + "/" + operationID + "/" + fiscalCalendarID + "/" + onOneWarehouse + "/" + userID);
  }

  GetFiscalClosingConfigs() {
    return this._http.get<FiscalCloseConfigs>(this.appSettings.urlLocations.fiscalClosingURL + 'configs');
  }
}
