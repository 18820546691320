import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { WarehouseConsolidationComponent } from './warehouse-consolidation/warehouse-consolidation.component';
import { AuthGuardService } from '../core/auth-guard/auth-guard.service';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { UtilitiesService } from './utilities-service/utilities.service';

import { RoleGuardService } from '../core/role-guard/role-guard.service';
import { POGAdjustmentComponent } from './pog-adjustment/pog-adjustment.component';
import { CatPOGConvComponent } from './cat-pog-conv/cat-pog-conv.component';

const routes: Routes = [
  { path: 'utilities/warehouse-consolidation',
    component: WarehouseConsolidationComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    data: {expectedRole: 'CONSOLIDATE_WAREHOUSE_INVENTORY_ACCESS'} },
    { path: 'utilities/pog-adjustment',
    component: POGAdjustmentComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    data: {expectedRole: 'POG_BULK_ADJUSTMENT'} },
    { path: 'utilities/cat-pog-conv',
    component: CatPOGConvComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    data: {expectedRole: 'CAT_POG_CONVERSION'} }];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CoreModule,
    SharedModule,
    NgbModule
  ],
  declarations: [
    WarehouseConsolidationComponent,
    POGAdjustmentComponent,
    CatPOGConvComponent
  ],
  providers: [UtilitiesService, CurrencyPipe]
})
export class UtilitiesModule { }
