export class SmartServiceStatusRequest {
  businessDate: Date | string;
  availableOperationIDs: number[];
  operationIDs: number[];
  routeIDs: number[];
}

export interface SmartServiceStatusListItem {
  businessDate: Date | string;
  costCenterID: number;
  operationID: number;
  operationName: string;
  routeID: number | null;
  routeNumber: number | null;
  routeName: string;
  seedOrders: number;
  ordersCreated: number;
  oW_ReleasedOrderCount: number;
  oW_LS_PIPOrderCount: number;
  oW_FulfilledOrderCount: number;
  oW_CompleteOrderCount: number;
  onRoute: number;
  deliveredOrderCount: number;
  orderComplete: number;
  allOrdersCompleted: boolean;
  errorInd: boolean;
  warehouseName: string;
}

export interface OperationLineItem
{
  displayName: string;
  id: number;
  number: number;
  selected: boolean;
  userID: number;
}

export interface AccountLineItem {
  displayName: string;
  id: number;
  number: number;
  majorAccountID: number
  selected: boolean;
}

export interface AccountLocation
{
  id: number;
  AccountNameAndNumber: string;
  LocationNameAndNumber: string;
  selected: boolean;
}


export interface RouteLineItem
{
  displayName: string;
  id: number;
  number: number;
  selected: boolean;
  operationID: number;
}

export interface SmartServiceStatusInitializeResponse
{
  operationLineItems: OperationLineItem[];
  routeLineItems: RouteLineItem[];
  smartServiceStatusListItems: SmartServiceStatusListItem[];
}

export interface SmartServiceStatusDetailsResponse
{
  smartServiceStatusDetailsListItems: SmartServiceStatusDetailsListItem[];
}

export interface SmartServiceStatusDetailsListItem
{
  businessDate: Date | string;
  costCenterID: number;
  operationID: number;
  operationName: string;
  routeID: number | null;
  routeNumber: number | null;
  routeName: string;
  accountName: string;
  locationName: string;
  machineID: number;
  machineNumber: string;
  orderNumber: string;
  errorInd: boolean;
  errorSource: string;
  errorText: string;
}
