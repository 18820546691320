import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Report_Param_Quarter_Response } from '../../reports-service/reports.model';
import { Switch } from '../../../shared/switch/switch.model';

@Component({
  selector: 'app-rpt-quarter',
  templateUrl: './rpt-quarter.component.html',
  styleUrls: ['./rpt-quarter.component.scss']
})
export class RptQuarterComponent implements OnInit {

  @Input()
  quarters: Report_Param_Quarter_Response[];

  @Output()
  quarterSelected = new EventEmitter();

  selectAll = new Switch('Select All', false, true);
  errorMessage = '';

  constructor() { }

  ngOnInit() {
  }

  flipAllSwitches(event: Switch) {
    this.quarters.forEach(x => x.selected.input = !event.input);
    this.noneSelected(this.quarters);
    this.quarterSelected.emit(this.quarters);
  }

  quarterOnClick(event: Switch, quarter: Report_Param_Quarter_Response) {
    const tempArray = this.quarters.filter(x => x.fiscalQuarterValue !== quarter.fiscalQuarterValue);
    // Uses bool directly from event to avoid 2 way binding delay
    const eventBool = !event.input;
    this.selectAll.input = tempArray.every(x => x.selected.input === true) &&  eventBool === true;
    this.noneSelected(tempArray, eventBool);
    this.quarterSelected.emit(this.quarters);
  }

  // Pass extra bool to deal with 2 way binding delay
  noneSelected(inputArray, retrievedBool = null) {
    this.errorMessage = inputArray.every(x => x.selected.input === false) && (retrievedBool == null || retrievedBool === false)
    ? 'Fiscal Quarter is Required' : '';
  }
}
