import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CommonService } from '../common-service/common.service';
import { SecurityService } from '../security/security.service';
import { RoleGuardService } from '../role-guard/role-guard.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('out', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('in', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class NavigationComponent implements OnInit {

  currentYear = (new Date()).getFullYear();

  constructor(
    private _common: CommonService,
    private _security: SecurityService,
    private _roleGuard: RoleGuardService,
    private _elementRef: ElementRef,
    private _router: Router
  ) { }

  ngOnInit() {
  }

  isLoggedIn() {
    return this._security.isLoggedIn();
  }

  getNavigationPaneState() {
    return this._common.getNavigationPane();
  }

  setNavigationPaneState(navState: string) {
    this._common.setNavigationPane(navState);
  }

  showNavigationPane() {
    return this._common.showNavigationPane();
  }

  canNavigate(requiredRoles: string[]) {
    return this._roleGuard.hasOneOfRoles(requiredRoles);
  }

  canNavigateAll(requiredRoles: string[]) {
    return this._roleGuard.hasAllRoles(requiredRoles);
  }

  signOut() {
    this._common.setNavigationPane('in');
    this._security.logOff().subscribe(data => {
      this._router.navigate(['logout'], {queryParams: {status: data['status'], message: data['message']}});
    });
  }

  isUnauthorizedReason() {
    return !!this._security.getUnauthorizedReasonFromToken();
  }

  @HostListener('document:click', ['$event.target']) onclick(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && targetElement.id !== 'navbar-burger') {
      if (this._common.showNavigationPane()) {
        this._common.toggleNavigationPane();
      }
    }
  }
}
