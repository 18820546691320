import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SKUTolerance } from '../../reports-service/reports.model';

@Component({
  selector: 'app-rpt-sku-tolerance',
  templateUrl: './rpt-sku-tolerance.component.html',
  styleUrls: ['./rpt-sku-tolerance.component.scss']
})
export class RptSkuToleranceComponent implements OnInit {

  @Input()
  skuTolerances: SKUTolerance[];

  @Input()
  submitted: boolean = false;

  @Output()
  onSetSkuTolerance = new EventEmitter<SKUTolerance>();

  displaySKU = "Select";

  constructor() { }

  ngOnInit() {

  }

  selectSKU(sku: SKUTolerance) {
    this.onSetSkuTolerance.emit(sku);
    this.displaySKU = sku.name;
  }

  getError() {
    if (this.submitted && this.displaySKU == "Select")
      return "This field is required.";
    else
      return "";
  }

}
