import { Component, OnInit, OnDestroy } from '@angular/core';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';
import { ISubscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-report-name',
  templateUrl: './report-name.component.html',
  styleUrls: ['./report-name.component.scss']
})
export class ReportNameComponent implements OnInit, OnDestroy {

  reportName: any[];
  reportNameSubscription: ISubscription;

  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this.reportNameSubscription = this._filter.reportName.subscribe(data => {
      this.reportName = data;
    });
  }

  ngOnDestroy() {
    this.reportNameSubscription.unsubscribe();
  }

}
