import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/map';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ModifyDevice, DeviceModifyInfoReponse, ValidateDates,
  Operation, CostCenterInventoryDevice, TransferDeviceRecord,
  DeviceAssignInfo, NewDevice, DeviceAssignPersist,
  DeviceAssignValidation, 
  CashlessDeviceInventoryItem,
  CashlessDeviceInventoryItemUpdate} from './device-management.model';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { SecurityService } from '../../core/security/security.service';
import { Subject } from 'rxjs';

@Injectable()
export class DeviceManagementService {
  appSettings: any;
  deviceInfo: BehaviorSubject<DeviceModifyInfoReponse> = new BehaviorSubject<DeviceModifyInfoReponse>(null);
  serviceError: BehaviorSubject<string> = new BehaviorSubject<string>('');

  cashlessDeviceInventoryItems: CashlessDeviceInventoryItem[];
  cashlessDeviceInventoryItemSubject: Subject<CashlessDeviceInventoryItem[]> = new Subject<CashlessDeviceInventoryItem[]>();

  constructor(private _http: HttpClient,
    private _security: SecurityService,
    private appSettingsProvider: AppsettingsProvider) {
    this.appSettings = this.appSettingsProvider.getSettings();
  }

  AddNewDevice(newDevice: NewDevice) {
    return this._http.post(this.appSettings.urlLocations.deviceManagementURL + 'addDevice', newDevice);
  }

  AssignDevice(device: DeviceAssignPersist) {
    return this._http.post(this.appSettings.urlLocations.deviceManagementURL + 'assign', device);
  }

  GetAssignInfo(deviceNumber: string, machineNumber: number, costCenter: number) {
    return this._http.get<DeviceAssignInfo>(this.appSettings.urlLocations.deviceManagementURL +
      'assign/' + deviceNumber + '/' + machineNumber + '/' + costCenter);
  }

  GetAssignCostCenter(deviceNumber: string) {
    return this._http.get<DeviceAssignValidation>(this.appSettings.urlLocations.deviceManagementURL +
      'assign/' + deviceNumber);
  }

  GetDeviceInfo(deviceNumber: string) {
    return this._http.get<DeviceModifyInfoReponse>(this.appSettings.urlLocations.deviceManagementURL + 'modify/' + deviceNumber)
      .subscribe((data: DeviceModifyInfoReponse) => {
        this.deviceInfo.next(data);
      }, (error: HttpErrorResponse) => {
        if (error.status === 418) {
          this.serviceError.next('Device is not a known device.');
        } else {
          this.serviceError.next('An error has occured.');
          console.log('Error:', error);
          console.log('Error message', error.message);
          console.log('Status text', error.statusText);
        }
      });
  }

  GetCostCenterDevices(costCenter: number) {
    return this._http.get<CostCenterInventoryDevice[]>(this.appSettings.urlLocations.deviceManagementURL +
      'transfer/costcenterinventorydevices?costCenter=' + costCenter);
  }

  GetModels(vendor: string) {
    return this._http.get<string[]>(this.appSettings.urlLocations.deviceManagementURL + 'models/' + vendor);
  }

  GetOperations() {
    return this._http.get<Operation[]>(this.appSettings.urlLocations.deviceManagementURL + 'operations/');
  }

  GetVendors() {
    return this._http.get<string[]>(this.appSettings.urlLocations.deviceManagementURL + 'vendors/');
  }

  ModifyDevice(updateData: ModifyDevice) {
    return this._http.put(this.appSettings.urlLocations.deviceManagementURL + 'modify/', updateData);
  }

  TransferDevices(devices: TransferDeviceRecord[]) {
    return this._http.post(this.appSettings.urlLocations.deviceManagementURL + 'transfer', devices);
  }

  ValidateStartDate(validateDates: ValidateDates) {
    return this._http.post(this.appSettings.urlLocations.deviceManagementURL + 'validate/startdate', validateDates);
  }

  GetCashlessDeviceInventoryItems(costCenter: number) {
    return this._http.get<CashlessDeviceInventoryItem[]>(this.appSettings.urlLocations.deviceManagementURL + 'CashlessDeviceInventory?costCenter=' + costCenter);
  }

  UpdateCashlessDeviceInventoryItem(item:CashlessDeviceInventoryItemUpdate) {
    return this._http.put(this.appSettings.urlLocations.deviceManagementURL + 'cashlessdeviceinventoryupdate', item);
  }  
}
