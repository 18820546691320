import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MachineServiceReviewComponent } from './machine-service-review/machine-service-review.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../core/auth-guard/auth-guard.service';
import { RoleGuardService } from '../core/role-guard/role-guard.service';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ImageZoomModule } from 'angular2-image-zoom';
import { MachineServiceService } from './machine-service-service/machine-service.service';
import { MachineServiceDetailsComponent } from './machine-service-details/machine-service-details.component';

const routes: Routes = [
  { path: 'machine-service/machine-service-review',
    component: MachineServiceReviewComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'MACHINE_PHOTOS_SCREEN_ACCESS'} },
  { path: 'machine-service/machine-service-details/:machineSvcID',
    component: MachineServiceDetailsComponent, canActivate: [AuthGuardService, RoleGuardService], data: {expectedRole: 'MACHINE_PHOTOS_SCREEN_ACCESS'} }
];


@NgModule({
  declarations: [MachineServiceReviewComponent, MachineServiceDetailsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CoreModule,
    SharedModule,
    NgbModule,
    ImageZoomModule,
    NgxImageZoomModule.forRoot(),
  ],
  providers: [MachineServiceService]
})
export class MachineServiceModule { }
