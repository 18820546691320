import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { SmartServiceStatusRequest, SmartServiceStatusListItem,
  OperationLineItem, RouteLineItem, SmartServiceStatusInitializeResponse,
  SmartServiceStatusDetailsResponse, SmartServiceStatusDetailsListItem } from './smart-service.model';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { SecurityService } from '../../core/security/security.service';

import * as moment from 'moment';

@Injectable()
export class SmartServiceService {
  refreshing: boolean;
  routeList: RouteLineItem[];
  routeSubject: Subject<RouteLineItem[]> = new Subject<RouteLineItem[]>();
  operationList: OperationLineItem[];
  operationSubject: Subject<OperationLineItem[]> = new Subject<OperationLineItem[]>();
  smartServiceStatusList: SmartServiceStatusListItem[];
  smartServiceStatusDetailsList: SmartServiceStatusDetailsListItem[];
  ssCount: number;
  ssDetailsCount: number;
  private pageNum: number;
  smartServiceStatusSubject: Subject<SmartServiceStatusListItem[]> = new Subject<SmartServiceStatusListItem[]>();
  smartServiceStatusDetailsSubject: Subject<SmartServiceStatusDetailsListItem[]> = new Subject<SmartServiceStatusDetailsListItem[]>();
  appSettings: any;

  constructor(private _http: HttpClient,
    private _security: SecurityService,
    private appSettingsProvider: AppsettingsProvider) {
    this.appSettings = this.appSettingsProvider.getSettings();
  }

  Initialize() {
    let request = new SmartServiceStatusRequest();

    request.availableOperationIDs = this._security.availableOperations.map(item => item.operationID);
    request.businessDate = moment().add(1, 'days').toDate();
    request.operationIDs = new Array();
    request.routeIDs = new Array();

    this._http.post<SmartServiceStatusInitializeResponse>(this.appSettings.urlLocations.smartServiceURL + 'initialize', request)
      .subscribe((data: SmartServiceStatusInitializeResponse) => {
        this.routeList = data.routeLineItems;
        this.operationList = data.operationLineItems;
        this.operationSubject.next(data.operationLineItems);
        this.routeSubject.next(data.routeLineItems);

        request = new SmartServiceStatusRequest();
        request.availableOperationIDs = this._security.availableOperations.map(item => item.operationID);
        request.businessDate = moment().add(1, 'days').toDate();
        request.operationIDs = data.operationLineItems.map(item => item.id);
        request.routeIDs = new Array();

        this._http.post<SmartServiceStatusInitializeResponse>(this.appSettings.urlLocations.smartServiceURL + 'search', request)
          .subscribe((smartSvcData: SmartServiceStatusInitializeResponse) => {
            this.smartServiceStatusList = smartSvcData.smartServiceStatusListItems.slice();
            this.ssCount = smartSvcData.smartServiceStatusListItems.length;
            this.smartServiceStatusSubject.next(smartSvcData.smartServiceStatusListItems.slice());
          });
      });
  }

  InitializeRoutes(serviceDate, operation) {
    let request = new SmartServiceStatusRequest();

    request.availableOperationIDs = this._security.availableOperations.map(item => item.operationID);
    request.businessDate = moment().add(1, 'days').toDate();
    request.operationIDs = [Number(operation)];
    request.routeIDs = new Array();
    this._http.post<SmartServiceStatusInitializeResponse>(this.appSettings.urlLocations.smartServiceURL + 'initialize', request)
      .subscribe((data: SmartServiceStatusInitializeResponse) => {
        this.routeList = data.routeLineItems;
        this.operationList = data.operationLineItems;
        this.operationSubject.next(data.operationLineItems);

        const availableRoutes = new Array();
        const routes = data.routeLineItems;
        for (let i = 0; i < routes.length; i++) {
          routes[i].selected = (routes[i].operationID === Number(operation));
          if (routes[i].selected) {
            availableRoutes.push(routes[i]);
          }
        }
        this.routeSubject.next(availableRoutes);

        request = new SmartServiceStatusRequest();
        request.availableOperationIDs = this._security.availableOperations.map(item => item.operationID);
        request.businessDate = new Date(serviceDate);
        request.operationIDs = [Number(operation)];
        request.routeIDs = availableRoutes.map(item => item.id);

        this._http.post<SmartServiceStatusInitializeResponse>(this.appSettings.urlLocations.smartServiceURL + 'search', request)
          .subscribe((smartSvcStatusData: SmartServiceStatusInitializeResponse) => {
            this.smartServiceStatusList = smartSvcStatusData.smartServiceStatusListItems.slice();
            this.ssCount = smartSvcStatusData.smartServiceStatusListItems.length;
            this.smartServiceStatusSubject.next(smartSvcStatusData.smartServiceStatusListItems.slice());
          });
      });
  }

  Search(searchRequest: SmartServiceStatusRequest) {

    if (searchRequest.routeIDs.length > 0) {
      if (searchRequest.operationIDs.length > 1) {
        const newRoutes = new Array<RouteLineItem>();
        for (let i = 0; i < this.routeList.length; i++) {
          if (searchRequest.operationIDs.indexOf(
              this.routeList[i].operationID) > -1) { // The operation was selected and its routes need to be passed and shown
            searchRequest.routeIDs.push(this.routeList[i].id);
            this.routeList[i].selected = true;
            newRoutes.push(this.routeList[i]);
          }
        }
        this.routeSubject.next(newRoutes);
      }
    }

    this._http.post<SmartServiceStatusInitializeResponse>(this.appSettings.urlLocations.smartServiceURL + 'search', searchRequest)
      .subscribe((data: SmartServiceStatusInitializeResponse) => {
        this.smartServiceStatusList = data.smartServiceStatusListItems.slice();
        this.ssCount = data.smartServiceStatusListItems.length;
        this.smartServiceStatusSubject.next(data.smartServiceStatusListItems.slice());
        this.refreshing = false;
      }, (error) => {
        this.refreshing = false;
      });
  }

  GetDetails(businessDate: string, operationId: number, routeId: number) {
    let Params = new HttpParams();

    // Begin assigning parameters
    Params = Params.append('businessdate', businessDate);
    Params = Params.append('operationid', operationId.toString());
    Params = Params.append('routeid', routeId.toString());

    this._http.get<SmartServiceStatusDetailsResponse>(this.appSettings.urlLocations.smartServiceURL + 'details', { params: Params })
      .subscribe((data: SmartServiceStatusDetailsResponse) => {
        this.smartServiceStatusDetailsList = data.smartServiceStatusDetailsListItems.slice();
        this.ssDetailsCount = data.smartServiceStatusDetailsListItems.length;
        this.smartServiceStatusDetailsSubject.next(data.smartServiceStatusDetailsListItems.slice());
        this.refreshing = false;
      },
    (error) => {
      this.refreshing = false;
    });
  }

  GetDashboard(businessDate: string, operationId: number){
    let Params = new HttpParams();

    // Begin assigning parameters
    Params = Params.append('businessdate', businessDate);
    Params = Params.append('operationid', operationId.toString());

    return this._http.get<any>(this.appSettings.urlLocations.smartServiceURL + 'dashboard', { params: Params });
  }

  SmartServicePageNum(pageNum: number) {
    this.pageNum = pageNum;
    let start: number;
    let end: number;

    start = (pageNum - 1) * this.appSettings.tableRowLength;
    end = start + this.appSettings.tableRowLength;
    if (end > this.ssCount) { end = this.ssCount; }

    this.smartServiceStatusSubject.next(this.smartServiceStatusList.slice(start, end));
  }

  SmartServiceDetailsPageNum(pageNum: number) {
    this.pageNum = pageNum;
    let start: number;
    let end: number;

    start = (pageNum - 1) * this.appSettings.tableRowLength;
    end = start + this.appSettings.tableRowLength;
    if (end > this.ssDetailsCount) { end = this.ssDetailsCount; }

    this.smartServiceStatusDetailsSubject.next(this.smartServiceStatusDetailsList.slice(start, end));
  }

  Sort(field: string, ascSort: boolean, stringSort: boolean) {
    if (stringSort) {
      if (ascSort) {
        this.smartServiceStatusList.sort((a: any, b: any) => a[field].localeCompare(b[field]));
      } else {
        this.smartServiceStatusList.sort((a: any, b: any) => b[field].localeCompare(a[field]));
      }
    } else {
      if (ascSort) {
        this.smartServiceStatusList.sort((a: any, b: any) => a[field] - b[field]);
      } else {
        this.smartServiceStatusList.sort((a: any, b: any) => b[field] - a[field]);
      }
    }

    this.smartServiceStatusSubject.next(this.smartServiceStatusList.slice());
  }

  SortDetails(field: string, ascSort: boolean, stringSort: boolean) {
    if (stringSort) {
      if (ascSort) {
        this.smartServiceStatusDetailsList.sort((a: any, b: any) => a[field].localeCompare(b[field]));
      } else {
        this.smartServiceStatusDetailsList.sort((a: any, b: any) => b[field].localeCompare(a[field]));
      }
    } else {
      if (ascSort) {
        this.smartServiceStatusDetailsList.sort((a: any, b: any) => a[field] - b[field]);
      } else {
        this.smartServiceStatusDetailsList.sort((a: any, b: any) => b[field] - a[field]);
      }
    }

    this.smartServiceStatusDetailsSubject.next(this.smartServiceStatusDetailsList.slice());
  }
}
