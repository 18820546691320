import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/common-service/common.service';
import { RemotePriceChangeService } from '../remote-price-change-service/remote-price-change.service';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';
import { iVendLookup, RPCTrackStatusExcelItem, RPCTrackStatusItem, RPCTrackStatusMetadata, RPCTrackStatusResponse, RPCTrackStatusSaveResponse, RpcTrackStatusSearchRequest 
  } from '../remote-price-change-service/remote-price-change.model';
import { SecurityService } from '../../core/security/security.service';
import { ISubscription } from 'rxjs-compat/Subscription';
import { ExcelService } from '../../core/excel-service/excel.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-rpc-status',
  templateUrl: './rpc-status.component.html',
  styleUrls: ['./rpc-status.component.scss']
})
export class RpcStatusComponent implements OnInit {
  isRefreshingPage: boolean;
  pageSize: number = 1000;
  pageNumber: number = 1;
  majorAccountId: number;
  accLocList: number[];
  isProcessing: boolean = false;
  responseMessage: string;
  errorMessage: string;
  tableIsDirty: boolean;
  userID: number;
  params = ['accounts', 'rpcStatus', 'buttons'];
  filterPosition = 'in';
  view = 'list';
  isMobile = false;
  enableSaveButton: boolean;
  totalNumberOfItems: number;
  
  metadata: RPCTrackStatusMetadata;
  rpcStatusItems: RPCTrackStatusItem[] = new Array();
  ivendLookups: iVendLookup[] = new Array();
  rpcTrackStatusItemSubject: ISubscription;
  rpcTrackStatusFilterSubject: ISubscription;
  rpcStatusFilterRequest: RpcTrackStatusSearchRequest = new RpcTrackStatusSearchRequest();
  
  constructor(private _common: CommonService,
    private _rpcService: RemotePriceChangeService,
    private _filterBarService: FilterBarService,
    private _security: SecurityService,
    private _excel: ExcelService,) 
  {
    this._common.setCurrentPage('RPC Tracking Status');            
  }

  ngOnInit() {
    this.tableIsDirty = false;
    this.enableSaveButton = true;
    this.rpcStatusItems = new Array();
    this.setUpSubjects();
  }

  private setUpSubjects() {
    this.isRefreshingPage = true;
    this.userID = this._security.getUserID();
    this._filterBarService.selectedMajorAcct.subscribe(data => {
      this.majorAccountId = data;
    });
    // make a stripped-down call to API just to get the lookups
    this.rpcTrackStatusItemSubject = this._rpcService.GetRPCTrackStatusItems(this.dummyRequestObjectForLookups()).subscribe(
      (data: RPCTrackStatusResponse) => {
        console.log("SEARCH REVIEW Result::",data);
        this.ivendLookups = data.iVendLookups;
        this.setItemsFieldsFromLookups(this.rpcStatusItems, this.ivendLookups);
        this.isRefreshingPage = false;
      }),
      (error)=> {
        this.isRefreshingPage = false;
      };
    // updates to filter bar show up here
    this.rpcTrackStatusFilterSubject= this._filterBarService.rpcStatusFilterUpdate.subscribe((data: RpcTrackStatusSearchRequest) => {
      this.rpcStatusFilterRequest = data;  
    });

    this._filterBarService.resetFilters.subscribe(() => {
      this.pageNumber = 1;
    });
  }

  private dummyRequestObjectForLookups() {
    var result = new RpcTrackStatusSearchRequest();
    result.costCenterID = 5141;
    result.pageNumber = 1;
    result.pageSize = 1;
    result.sortColumn = 'locationID';
    result.sortDirection = 'asc';
    return result;
  }

  private setItemsFieldsFromLookups(items: RPCTrackStatusItem[], iVendLookups: iVendLookup[]) {
    // Set field values from lookups
    for (let index = 0; index <  items.length; index++) {
      const item = items[index];
      this.setItemFieldsFromLookups(item, iVendLookups);
    }
  }

  private setItemFieldsFromLookups(item: RPCTrackStatusItem, iVendLookups: iVendLookup[]) {
    var upgradeStepsLookup = iVendLookups.find(x=>x.iVendLookupID===item.upgradeStepsLookupID);
    item.upgradeSteps = upgradeStepsLookup === undefined ? `Upgrade step ID ${item.upgradeStepsLookupID} does not exist` : upgradeStepsLookup.description;

    var testingStatusLookup = iVendLookups.find(x=>x.typeName==='RPCTrackStatusTestingStatus' && x.iVendLookupID===item.testingStatus);
    item.testingStatusDisplay = testingStatusLookup === undefined ? `Testing status TypeName=RPCTrackStatusTestingStatus and ID ${item.testingStatus} not found` : testingStatusLookup.enumerationValue;

    var upgradeNeededLookup = iVendLookups.find(x=>x.typeName==='RPCTrackStatusUpgradeNeeded' && x.iVendLookupID==item.upgradeNeeded);
    item.upgradeNeededDisplay = upgradeNeededLookup === undefined ? `Upgrade needed status ID ${item.upgradeNeeded} not found` : upgradeNeededLookup.enumerationValue;

    var upgradeCompleteLookup = iVendLookups.find(x=>x.typeName=='RPCTrackStatusUpgradeComplete' && x.iVendLookupID==item.upgradeComplete);
    item.upgradeCompleteDisplay = upgradeCompleteLookup === undefined ? `Testing status ID ${item.upgradeComplete} not found` : upgradeCompleteLookup.enumerationValue;

    var testingCompleteLookup = iVendLookups.find(x=>x.typeName=='RPCTrackStatusTestingComplete' && x.iVendLookupID==item.testingComplete);
    item.testingCompleteDisplay = testingCompleteLookup === undefined ? `Testing complete status ID ${item.testingComplete} not found` : testingCompleteLookup.enumerationValue;
  }

  onApplyFilters(resetPage: boolean) {   
    if (resetPage) {
      this.pageNumber = 1;
    }
    this.responseMessage = "";
    this.errorMessage = "";
    this.populateNumberFieldsFromLookups();
    this.rpcStatusFilterRequest.costCenterID = Number(this._common.operation.operationNo);
    this.rpcStatusFilterRequest.pageNumber = this.pageNumber;
    this.rpcStatusFilterRequest.pageSize = this.pageSize;
    this.rpcStatusFilterRequest.majorAccountId = this.majorAccountId;
    this.accLocList = this.getAccLocationArray();
    this.rpcStatusFilterRequest.accountLocationList = this.accLocList;
    this.isRefreshingPage = true;
    console.log(`Search filter: ${JSON.stringify(this.rpcStatusFilterRequest)}`);
    this.rpcTrackStatusItemSubject = this._rpcService.GetRPCTrackStatusItems(this.rpcStatusFilterRequest).subscribe(
      (data: RPCTrackStatusResponse) => {
        console.log("SEARCH REVIEW Result::",data);
        this.metadata = data.metadata;
        this.rpcStatusItems = data.items;
        this.ivendLookups = data.iVendLookups;
        this.setItemsFieldsFromLookups(this.rpcStatusItems, this.ivendLookups);
        this.totalNumberOfItems = this.metadata.totalItemCount;
        this.isRefreshingPage = false;
        if (data.metadata.totalItemCount === 0) {
          this.responseMessage = `There are no results found`;
        }
      }),
      (error)=> {
        this.errorMessage = `An error has occurred.  Please try again.  Message=${error}`;
        this.isRefreshingPage = false;
      };
  }

  private populateNumberFieldsFromLookups() {
    if (this.rpcStatusFilterRequest.upgradeSteps !== undefined && this.rpcStatusFilterRequest.upgradeSteps !== null) {
      var usLookup = this.ivendLookups.find(lookup => lookup.description === this.rpcStatusFilterRequest.upgradeSteps);
      this.rpcStatusFilterRequest.upgradeStepsLookupID = usLookup.iVendLookupID;
    } else {
      this.rpcStatusFilterRequest.upgradeStepsLookupID = null;
    }
    
    this.rpcStatusFilterRequest.upgradeNeededLookupIDs = new Array();
    this.rpcStatusFilterRequest.upgradeNeeded.forEach(x => {
      var unLookup = this.ivendLookups.find(lookup => lookup.typeName === 'RPCTrackStatusUpgradeNeeded' && lookup.enumerationValue === x);
      this.rpcStatusFilterRequest.upgradeNeededLookupIDs.push(unLookup.iVendLookupID);
    });
    this.rpcStatusFilterRequest.upgradeCompleteLookupIDs = new Array();
    this.rpcStatusFilterRequest.upgradeComplete.forEach(x => {
      var ucLookup = this.ivendLookups.find(lookup => lookup.typeName === 'RPCTrackStatusUpgradeComplete' && lookup.enumerationValue === x);
      this.rpcStatusFilterRequest.upgradeCompleteLookupIDs.push(ucLookup.iVendLookupID);
    });
    this.rpcStatusFilterRequest.testingStatusLookupIDs = new Array();
    this.rpcStatusFilterRequest.testingStatus.forEach(x => {
      var tsLookup = this.ivendLookups.find(lookup => lookup.typeName === 'RPCTrackStatusTestingStatus' && lookup.enumerationValue === x);
      this.rpcStatusFilterRequest.testingStatusLookupIDs.push(tsLookup.iVendLookupID);
    });
    this.rpcStatusFilterRequest.testingCompleteLookupIDs = new Array();
    this.rpcStatusFilterRequest.testingComplete.forEach(x => {
      var tcLookup = this.ivendLookups.find(lookup => lookup.typeName === 'RPCTrackStatusTestingComplete' && lookup.enumerationValue === x);
      this.rpcStatusFilterRequest.testingCompleteLookupIDs.push(tcLookup.iVendLookupID);
    });
  }

  private getAccLocationArray() { 
    this.accLocList = new Array();
    if (this._filterBarService.accountLocationList.value != null) {
      for (let i = 0; i < this._filterBarService.accountLocationList.value.length; i++) {
        if(this._filterBarService.accountLocationList.value[i].selected) {
          this.accLocList.push(this._filterBarService.accountLocationList.value[i].id);
        }
      }
    }
   return this.accLocList;
 }

  private sortOpenOperationStatuses(event:any, sortColumn:string) {
    this.rpcStatusFilterRequest.sortColumn = sortColumn;
    this.rpcStatusFilterRequest.sortDirection = event ? "ASC":"DESC";
    this.onApplyFilters(false);
  }

  private getExcelTransformedItems(data: RPCTrackStatusResponse):RPCTrackStatusExcelItem[] {
    let excelItems: RPCTrackStatusExcelItem[] = new Array();

    for (let index = 0; index <  data.items.length; index++) {
      const item = data.items[index];
      const excelItem:RPCTrackStatusExcelItem = new RPCTrackStatusExcelItem();
      excelItem.Customer            = item.customer;
      excelItem.AccountLocation     = item.accountLocation;
      excelItem.MachineNumber       = item.machineNumber;
      excelItem.MachineSerialNumber = item.machineSerialNumber;
      excelItem.DEXSerialNumber     = item.dexSerialNumber;
      excelItem.UpdatedMachineModel = item.updatedMachineModel;
      excelItem.VendorCode          = item.vendorCode;
      excelItem.DeviceNo            = item.deviceNo;
      excelItem.UpgradeSteps        = item.upgradeSteps;
      excelItem.PartNumber          = item.partNumber;
      excelItem.UpgradeNeeded       = item.upgradeNeededDisplay;
      excelItem.UpgradeComplete     = item.upgradeCompleteDisplay;
      excelItem.TestingStatus       = item.testingStatusDisplay;
      excelItem.TestingComplete     = item.testingCompleteDisplay;

      excelItems.push(excelItem);
    }

    return excelItems;
  }

  private exportToExcel() {
    this.responseMessage = "";
    this.errorMessage = "";
    this.isProcessing = true;
    const allItemsFilteredRequest = {...this.rpcStatusFilterRequest};
    allItemsFilteredRequest.pageSize = 100000;
    allItemsFilteredRequest.pageNumber = 1;
    console.log(`Export to Excel filter: ${JSON.stringify(allItemsFilteredRequest)}`);
    
    this.rpcTrackStatusItemSubject = this._rpcService.GetRPCTrackStatusItems(allItemsFilteredRequest).subscribe(
      (data: RPCTrackStatusResponse) => {
        console.log("Excel Export Result::",data);
        this.setItemsFieldsFromLookups(data.items, data.iVendLookups);
        let excelItems = this.getExcelTransformedItems(data);

        const dateFormat = "YYYYMMDD";
        let _now: Moment = moment(new Date());
        const fileName = 'RPC Tracking Status' + '_' + _now.format(dateFormat);
          this._excel.exportAsExcelFile(excelItems, fileName);
        this.isProcessing = false;
      }),
      (error => {
        console.log("Excel Export fail");
        this.errorMessage = `An error has occurred.  Please try again.  Message=${error}`;
        this.isProcessing = false;
      });
  } // end exportToExcel

  markAsDirty(rpcTrackStatusID: number) {
    let rowToMark = this.rpcStatusItems.find(x => x.rpcTrackStatusID === rpcTrackStatusID);
    rowToMark.dirty = true;
    rowToMark.invalidUpdate = this.determineIfUpdateInvalid(rowToMark);
    this.enableSaveButton = !this.rpcStatusItems.some(x => x.invalidUpdate);
    this.tableIsDirty = true;
  }

  private determineIfUpdateInvalid(item: RPCTrackStatusItem) {
    var un_no : string = this.ivendLookups.find(x => x.typeName ==='RPCTrackStatusUpgradeNeeded' && x.enumerationValue === 'No').iVendLookupID.toString();
    var un_tbd : string = this.ivendLookups.find(x => x.typeName ==='RPCTrackStatusUpgradeNeeded' && x.enumerationValue === 'TBD').iVendLookupID.toString();
    var uc_no : string = this.ivendLookups.find(x => x.typeName ==='RPCTrackStatusUpgradeComplete' && x.enumerationValue === 'No').iVendLookupID.toString();
    var uc_yes : string = this.ivendLookups.find(x => x.typeName ==='RPCTrackStatusUpgradeComplete' && x.enumerationValue === 'Yes').iVendLookupID.toString();

    if (item.upgradeNeeded.toString() === un_no || item.upgradeNeeded.toString() === un_tbd) {
      if (item.upgradeComplete.toString() === uc_no || item.upgradeComplete.toString() === uc_yes)
        return true;
    }
      
    return false;
  }

  private saveButton() {
    const items:RPCTrackStatusItem[] = new Array();
    for (let i = 0; i < this.rpcStatusItems.length; i++) {
      const item = this.rpcStatusItems[i];
      if (item.dirty) {
        items.push(item);
      }
    }

    if (items.length > 0) {
      this.isProcessing = true;
      this._rpcService.PutRPCTrackStatusItems(items, this._security.getUserID()).subscribe(
      (data:RPCTrackStatusSaveResponse) => {
        if (data.exceptions.length == 0) {
          for(let i=0; i< data.items.length; i++) {
            const item = data.items[i];
            if (item.exceptions.length == 0) {
              // Since there are no exceptions returned for this item, un-mark the item as dirty
              const rpcItemIndex = this.rpcStatusItems.findIndex(x=>x.rpcTrackStatusID == item.rpcTrackStatusID);
              item.dirty = false;
              this.setItemFieldsFromLookups(item, this.ivendLookups);
              if (rpcItemIndex >= 0) {
                this.rpcStatusItems[rpcItemIndex]=item;
              }
            }
          }
          this.tableIsDirty = false;
        } else {
          // Show the exceptions on page
        }
        console.log('Save completed');
      }),
      (error)=>{
        console.log(`Failure to put items ${error}`);
      };
    }

    this.isProcessing = false;
  }

  pageChanged() {
    window.scrollTo(0, 0);
    this.onApplyFilters(false);
  }

  onChangeView(view) {
    this.view = view;
    if (this.isMobile) {
      this.filterPosition = 'out';
    } else {
      this.filterPosition = view === 'grid' ? 'out' : 'in';
    }
  }
}
