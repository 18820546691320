import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ISubscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import * as moment from 'moment';

import { CommonService } from '../../core/common-service/common.service';
import { SecurityService } from '../../core/security/security.service';
import { SmartServiceService } from '../smart-service-service/smart-service.service';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';
import { SmartServiceStatusListItem,
  OperationLineItem,
  RouteLineItem,
  SmartServiceStatusRequest
} from '../smart-service-service/smart-service.model';

@Component({
  selector: 'app-route-status',
  templateUrl: './route-status.component.html',
  styleUrls: ['./route-status.component.scss']
})
export class RouteStatusComponent implements OnInit, OnDestroy {

  routes: any[];

  private routeStatusSubscription: ISubscription;
  private operationSubscription: ISubscription;
  private routeSubscription: ISubscription;
  private refreshSubscription: ISubscription;
  private intervalSubscription: ISubscription;
  params = ['refresh', 'autoRefresh', 'buttons', 'date', 'operations', 'routes'];

  constructor(private _common: CommonService,
    private _security: SecurityService,
    private _smart: SmartServiceService,
    private _filter: FilterBarService,
    private _route: ActivatedRoute,
    private _router: Router) {
    this._common.setCurrentPage('Smart Service Route Status');
    this._common.operationGuideSubject.next('Operations Guide - One Vend - SmartServiceStatus.pdf');
  }

  ngOnInit() {
    this._common.columnSortAsc = true;
    this._common.columnToSort = '';
    this.routeStatusSubscription = this._smart.smartServiceStatusSubject.subscribe((data: SmartServiceStatusListItem[]) => {
      this.routes = data;
    });
    this.operationSubscription = this._smart.operationSubject.subscribe((ops: OperationLineItem[]) => {
      const selectedOperation = this._route.snapshot.params['operationID'];
      for (var i = 0; i < ops.length; i++) {
        ops[i].selected = (ops[i].id == Number(selectedOperation));
      }
      this._filter.operations.next(ops);
    });
    this.routeSubscription = this._smart.routeSubject.subscribe((routes: RouteLineItem[]) => {
      this._filter.routes.next(routes);
    });
    this.intervalSubscription = this._filter.refreshInterval.subscribe((data) => {
      this.onAutoRefresh(this._filter.autoRefresh);
    });
    this._filter.dateLabel = 'FISCAL DATE*';
    this._filter.dateError = '';
    this._filter.operationError = '';
    const selectedOperation = this._route.snapshot.params['operationID'];
    this._filter.multipleOperations = false;
    const serviceDate = this._route.snapshot.params['serviceDate'];
    this._filter.date.next(new Date(serviceDate));
    this._filter.tempDate = this._filter.date.value;
    this._smart.InitializeRoutes(serviceDate, selectedOperation);
    this._filter.refreshedOn = moment().toDate();
  }

  ngOnDestroy() {
    this.routeStatusSubscription.unsubscribe();
    this.operationSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  isRefreshing() {
    return this._smart.refreshing;
  }

  onApplyFilters(apply) {
    this._smart.refreshing = true;
    let isError = false;
    if (typeof this._filter.tempDate !== 'object' || this._filter.tempDate == null) {
      this._filter.dateError = 'Fiscal Date is Required';
      isError = true;
    }
    const request = new SmartServiceStatusRequest();
    request.availableOperationIDs = this._security.availableOperations.map(item => item.operationID);
    if (this._filter.tempDate !== this._filter.date.value) {
      this._filter.date.next(this._filter.tempDate);
    }
    request.businessDate = this._filter.date.value;
    const operations = new Array();
    for (let i = 0; i < this._filter.operations.value.length; i++) {
      if (this._filter.operations.value[i].selected) {
        operations.push(this._filter.operations.value[i].id);
      }
    }

    if (operations.length === 0) {
      this._filter.operationError = 'Must select at least one operation.';
      isError = true;
    }

    if (isError) {
      this._smart.refreshing = false;
      return;
    }

    const multipleOperations = (operations.length > 1);
    this._filter.multipleOperations = multipleOperations;

    request.operationIDs = operations;
    const routes = new Array();
    for (let i = 0; i < this._filter.routes.value.length; i++) {
      if (this._filter.routes.value[i].selected) {
        routes.push(this._filter.routes.value[i].id);
      }
    }
    request.routeIDs = routes;
    this._smart.Search(request);
    this._filter.refreshedOn = moment().toDate();
  }

  onAutoRefresh(autoRefresh) {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (autoRefresh) {
      const interval = Number(this._filter.refreshInterval.value) * 60 * 1000;
      this.refreshSubscription = Observable.interval(interval).subscribe((x) => {
        this.onRefresh(true);
      });
    }
  }

  multipleOperations() {
    return this._filter.multipleOperations;
  }

  onRefresh(refresh) {
    this.onApplyFilters(refresh);
  }

  onResetFilters(apply) {
    this.ngOnInit();
  }

  selectRoute(event, route) {
    const serviceDate = this._common.stringifyDate(this._filter.date.value);
    this._router.navigate(['/smartservice/details/' +
      serviceDate + '/' +
      route.operationID + '/' +
      route.routeID + '/' +
      route.warehouseName]);
  }

  sort(sortAsc, columnName, isString) {
    this._smart.Sort(columnName, sortAsc, isString);
  }

}
