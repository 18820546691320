import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-dropdown',
  templateUrl: './basic-dropdown.component.html',
  styleUrls: ['./basic-dropdown.component.scss']
})
export class BasicDropdownComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
