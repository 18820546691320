import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../../core/common-service/common.service';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { WarehouseItem,
        PhysicalInventoryCountItem,
         WarehousePhysicalInventoryOperationRequest,
         WarehousePhysicalInventoryOperationResult,
         OperationClosingStatus,
         OperationCloseStatusResult,
         MdmInstallBaseShareRequest,
         MdmInstallBaseShareResponse} from './support.model';

@Injectable()

export class SupportService {
  appSettings: any;

  constructor(private _http: HttpClient,
    private _common: CommonService,
    private appSettingsProvider: AppsettingsProvider) {
      this.appSettings = this.appSettingsProvider.getSettings();
    }

  GetWarehouses(costCenterID: Number) {
    return this._http.get<WarehouseItem[]>(this.appSettings.urlLocations.warehouseURL
      + 'getWarehouses/' + costCenterID);
  }

  GetPhysicalInventoryCountDates(costCenterID: Number, warehouseID: Number) {
    return this._http.get<PhysicalInventoryCountItem[]>(this.appSettings.urlLocations.warehouseURL
      + 'getPICountDates/' + costCenterID + '/' + warehouseID);
  }

  GenerateZeroes(warehousePIOperationRequest: WarehousePhysicalInventoryOperationRequest) {
    return this._http.post<WarehousePhysicalInventoryOperationResult>(this.appSettings.urlLocations.warehouseURL
      + 'physicalinventory/generatezeroes/', warehousePIOperationRequest);
  }

  GetOperationClosingStatus(weekCode:string) {
    return this._http.get<OperationCloseStatusResult>(this.appSettings.urlLocations.fiscalClosingURL +
      'operationclosestatus/' + weekCode);
  }

  RequestMdmInstallBaseShare(request: MdmInstallBaseShareRequest) {
    return this._http.post<MdmInstallBaseShareResponse>(this.appSettings.urlLocations.machineServiceURL + 'mdm/installbaseshare/', request);
  }

}
