import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

import { OperationStatusComponent } from './operation-status/operation-status.component';
import { RouteStatusComponent } from './route-status/route-status.component';
import { DetailsComponent } from './details/details.component';
import { SmartServiceService } from './smart-service-service/smart-service.service';
import { AuthGuardService } from '../core/auth-guard/auth-guard.service';

const routes: Routes = [
  { path: 'smartservice/operation-status',
    component: OperationStatusComponent, canActivate: [AuthGuardService]  },
  { path: 'smartservice/route-status/:serviceDate/:operationID',
    component: RouteStatusComponent, canActivate: [AuthGuardService]  },
  { path: 'smartservice/details/:serviceDate/:operationID/:routeID/:warehouse',
    component: DetailsComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    SharedModule
  ],
  declarations: [OperationStatusComponent, DetailsComponent, RouteStatusComponent],
  providers: [ SmartServiceService]
})
export class SmartServiceModule { }
