import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { OperationComponent } from './operation/operation.component';
import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { IconsComponent } from './icons/icons.component';
import { CommonService } from './common-service/common.service';
import { AuthGuardService } from './auth-guard/auth-guard.service';
import { RoleGuardService } from './role-guard/role-guard.service';
import { SecurityService } from './security/security.service';
import { AlertsComponent } from './icons/alerts/alerts.component';
import { OperationsGuideComponent } from './icons/operations-guide/operations-guide.component';
import { ExcelService } from './excel-service/excel.service';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    NgbModule
  ],
  declarations: [
    HeaderComponent,
    OperationComponent,
    NavigationComponent,
    IconsComponent,
    AlertsComponent,
    OperationsGuideComponent
  ],
  providers: [
    CommonService,
    AuthGuardService,
    RoleGuardService,
    SecurityService,
    ExcelService
  ],
  exports: [
    HeaderComponent,
    NavigationComponent
  ]
})
export class CoreModule { }
