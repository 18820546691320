import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ISubscription } from 'rxjs/Subscription';

import { CommonService } from '../../../core/common-service/common.service';
import { SecurityService } from '../../../core/security/security.service';
import { ReportsService } from '../../reports-service/reports.service';
import { Switch } from '../../../shared/switch/switch.model';
import * as moment from 'moment';

@Component({
  selector: 'app-rpt-single-date',
  templateUrl: './rpt-single-date.component.html',
  styleUrls: ['./rpt-single-date.component.scss']
})
export class RptSingleDateComponent implements OnInit {

  @Input()
  singleDate: Date;
  @Output()
  onSingleDate = new EventEmitter<Date>();

  @Input()
  singleDateLabel: string;

  error: boolean;
  errorMessage = '';

  opeationSubscription: ISubscription;

  constructor(private _common: CommonService,
    private _security: SecurityService,
    private _reports: ReportsService) { }

  ngOnInit() {
    this.singleDate = new Date();
  }

  showErrorMessage() {
    return this.errorMessage;
  }

  toggleDatePickers(element, other) {
    element.toggle();
    other.close();
  }

  update(date) {
    this.errorMessage = '';

    const result = moment(date); // If not valid date, will return NaN
    if (!result.isValid()) {
      this.errorMessage = 'This field is required.';
      return;
    }

      this.onSingleDate.emit(date);
      this.singleDate = date;
  }
}
