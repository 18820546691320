import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CSMLineItem } from '../../../routes/no-service-review-service/no-service-review.model';
import { ISubscription } from 'rxjs/Subscription';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';

@Component({
  selector: 'app-csm',
  templateUrl: './csm.component.html',
  styleUrls: ['./csm.component.scss']
})
export class CsmComponent implements OnInit, OnDestroy {

  csm: string = "";
  csms: any[];
  csmSubscription: ISubscription;
  csmsSubscription: ISubscription;

  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this.csmsSubscription = this._filter.csms.subscribe((data: any[]) => {
      this.csms = data;
    });

    this.csmSubscription = this._filter.csm.subscribe((data: any) => {
      this.csm = data.displayName;
    });
  }

  ngOnDestroy() {
    this.csmsSubscription.unsubscribe();
  }

  selectCSM(csm: any) {
    this._filter.csm.next(csm);

    if (this._filter.currentPage === "no-service-review") {
      this._filter.routes.next(this._filter.nonFilteredRoutes.filter((item) => item.csmid == csm.id));
    }
  }
}
