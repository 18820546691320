
export interface WarehouseItem {
    warehouseID: number;
    costCenterID: number;
    description: string;
}

export interface PhysicalInventoryCountItem {
    inventoryCountDate: Date | string;
    physicalInventoryID: number;
    physicalInventoryGUID: string;
}

export interface OperationError {
    description: string;
}

export interface WarehousePhysicalInventoryOperationRequest {
    costCenterID: number;
    warehouseID: number;
    physicalInventoryID: number;
    physicalInventoryGuid: string;
}

export interface WarehousePhysicalInventoryOperationResult {
    errors: OperationError[];
}

export interface OperationCloseStatusResult {
  operationClosingStatuses : OperationClosingStatus[];
  warehouseReconciliationStatuses : WarehouseReconciliationStatus[];
}

export interface OperationClosingStatus {
  name: string,
  operationNo: number,
  divisionName: string,
  timezone: string,
  targetCloseDate: Date,
  stateOfClosing: string,
  stateOfClosingMessage: string,
  lastValidationDateTime: Date,
  oneWarehouseStatus: string,
  sappomeStatus: string,
  closedDateTime: Date,
  sapImportDate: Date,
  sapExportDate: Date,
  sapClosedDate: Date,
  processTimeInMinutes: number
}

export interface WarehouseReconciliationStatus {
  operationName: string,
  operationNo: number,
  sentToSAPDateTime: Date,
  meReportDateTime: Date,
  importFromSAPDateTime: Date,
  warehouseCloseDateTime: Date,
  pomeStatus: string,
  status: string
}

export class MdmInstallBaseShareRequest {
  oldMachineNumber: number;
  newMachineNumber: number;  
}

export class MdmInstallBaseShareResponse {
  messages: Array<string>;
  errors: Array<string>;
}
