import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { CommonService } from '../../core/common-service/common.service';
import { SmartServiceService } from '../smart-service-service/smart-service.service';
import { ActivatedRoute } from '@angular/router';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';
import { ISubscription } from 'rxjs/Subscription';
import { SmartServiceStatusDetailsListItem } from '../smart-service-service/smart-service.model';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  details: any[];

  params = ['displayItems', 'refresh'];
  displayItems = new Array<string>();
  private detailSubscription: ISubscription;

  constructor(private _common: CommonService,
    private _smart: SmartServiceService,
    private _filter: FilterBarService,
    private _route: ActivatedRoute) {
    this._common.setCurrentPage('Smart Service Details');
    this._common.operationGuideSubject.next('Operations Guide - One Vend - SmartServiceStatus.pdf');
  }

  ngOnInit() {
    this._common.columnSortAsc = true;
    this._common.columnToSort = '';
    const serviceDate = this._route.snapshot.params['serviceDate'];
    this.detailSubscription = this._smart.smartServiceStatusDetailsSubject.subscribe((data: SmartServiceStatusDetailsListItem[]) => {
      this.details = data;
      const operation = this.details[0].operationName;
      const warehouse = this._route.snapshot.params['warehouse'];
      const route = this.details[0].routeName;
      this.displayItems = new Array<string>();
      this.displayItems.push('Operation: ' + operation);
      this.displayItems.push('Warehouse: ' + warehouse);
      this.displayItems.push('Route: ' + route);
      this.displayItems.push('Fiscal Date: ' + serviceDate.replace(/-/g, '/'));
    });
    const selectedOperation = this._route.snapshot.params['operationID'];
    const selectedRoute = this._route.snapshot.params['routeID'];

    this._filter.date.next(new Date(serviceDate));
    this._filter.tempDate = this._filter.date.value;

    this._smart.GetDetails(serviceDate, selectedOperation, selectedRoute);
    const d: Date = moment().toDate();
    this._filter.refreshedOn = d;
  }

  isRefreshing() {
    return this._smart.refreshing;
  }

  onRefresh(refresh) {
    this._smart.refreshing = true;
    const selectedOperation = this._route.snapshot.params['operationID'];
    const selectedRoute = this._route.snapshot.params['routeID'];
    const serviceDate = this._route.snapshot.params['serviceDate'];

    this._smart.GetDetails(serviceDate, selectedOperation, selectedRoute);

    const d: Date = moment().toDate();
    this._filter.refreshedOn = d;
  }

  sort(sortAsc, columnName, isString) {
    this._smart.SortDetails(columnName, sortAsc, isString);
  }
}
