import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';

import { CommonService } from '../../core/common-service/common.service';
import { SecurityService } from '../../core/security/security.service';
import { ReportsService } from '../reports-service/reports.service';
import { ReportSearchLineItem, ReportSearchResponse, GridRequest,
  FilterDataRequest, FilterResponse, Filter, FilterRequest } from '../reports-service/reports.model';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  appsettings;
  params = ['refresh', 'buttons'];
  reports: ReportSearchLineItem[];
  filters: Filter[];

  sortColumn = 'reportName';
  sortDirection = 'DESC';

  constructor(private _common: CommonService,
    private _security: SecurityService,
    private _filter: FilterBarService,
    private _reports: ReportsService,
    private _appsettings: AppsettingsProvider) {
    this._common.setCurrentPage('View Reports');
  }

  ngOnInit() {
    this._filter.dateRangeLabel = 'SUBMITTED DATE*';
    const d = moment().toDate();
    const request = this.buildRequest();

    const fromDate = this.buildFilter('FromDate', moment(d).add(-2, 'days').toDate().toDateString());
    request.filters.push(fromDate);
    this._filter.dateFromError = '';
    this._filter.dateFrom.next(moment(d).add(-2, 'days').toDate());

    const toDate = this.buildFilter('ToDate', d.toDateString());
    request.filters.push(toDate);
    this._filter.dateToError = '';
    this._filter.dateTo.next(d);

    const filterRequest: FilterDataRequest = new FilterDataRequest();
    filterRequest.operationID = this._common.getOperation().operationID;
    filterRequest.filterRequests = new Array();
    return this._reports.GetFiltersForView(filterRequest).subscribe((data: FilterResponse) => {
      this.filters = data.filters;
      this.filters.forEach((filter) => {
        const name = filter.name.charAt(0).toLowerCase() + filter.name.substr(1);
        this.params.push(name);
        switch (name) {
          //case 'status': {
          //  this.selectAll(filter.data);
          //  this._filter.status.next(filter.data);
          //  break;
          //}
          case 'viewed': {
            this.selectAll(filter.data);
            this._filter.viewed.next(filter.data);
            break;
          }
          case 'reportName': {
            this.selectAll(filter.data);
            this._filter.reportName.next(filter.data);
            break;
          }
        }

      });

      // Reports
      this._filter.reportName.value.forEach((obj) => {
        this.buildApplyFilter(obj, 'ReportName', request);
      });
      // Status
      //this._filter.status.value.forEach((obj) => {
        //this.buildApplyFilter(obj, 'Status', request);
      //});
      // Viewed
      this._filter.viewed.value.forEach((obj) => {
        this.buildApplyFilter(obj, 'Viewed', request);
      });

      this.getReports(request);

      this._filter.refreshedOn = d;
    });
  }


  buildApplyFilter(obj, filterName, request: GridRequest) {
    if (obj.selected) {
      const filter = new FilterRequest();
      filter.filterName = filterName;
      filter.filterItemID = obj.id.toString();
      request.filters.push(filter);
    }
  }

  buildFilter(filterName: string, id: string) {
    const filter = new FilterRequest();
    filter.filterName = filterName;
    filter.filterItemID = id;
    return filter;
  }

  buildRequest() {
    const request: GridRequest = new GridRequest();
    this.appsettings = this._appsettings.getSettings();
    request.filters = new Array();

    const userFilter = this.buildFilter('User', this._security.getUserID().toString());
    request.filters.push(userFilter);

    request.parms = new Array();
    request.parms.push(this._common.operation.operationID.toString());
    request.pageNumber = 1;
    request.recordsPerPage = 1000;
    request.sortColumn = this.sortColumn;
    request.sortDirection = this.sortDirection;
    return request;
  }

  getReport(reportExecutionID, reportName) {
    this._reports.GetReport(reportExecutionID, this._security.getUserID()).subscribe(data => {
      const blob = new Blob([data], { type: 'application/vnd.ms-excel' });

      FileSaver.saveAs(blob, reportName + '.xlsx');
    });
  }

  getReports(request: GridRequest) {
    this._reports.GetReportsForView(request).subscribe((data: ReportSearchResponse) => {
      this.reports = data.reportSearchLineItems;
    });
  }

  onApplyFilters(apply) {
    const request = this.buildRequest();

    if (this._filter.dateFromError !== '' || this._filter.dateToError !== '') {
      return;
    }
    const fromDate = this.buildFilter('FromDate', this._filter.dateFrom.value.toDateString());
    request.filters.push(fromDate);

    const toDate = this.buildFilter('ToDate', this._filter.dateTo.value.toDateString());
    request.filters.push(toDate);

    if (this._filter.dateFrom.value > this._filter.dateTo.value) {
      return;
    }

    // Reports
    this._filter.reportName.value.forEach(function (obj) {
      buildApplyFilter(obj, 'ReportName', request);
    });
    // Status
    //this._filter.status.value.forEach(function (obj) {
     // buildApplyFilter(obj, 'Status', request);
    //});
    // Viewed
    this._filter.viewed.value.forEach(function (obj) {
      buildApplyFilter(obj, 'Viewed', request);
    });

    this.getReports(request);

    this._filter.refreshedOn = moment().toDate();

    function buildApplyFilter(obj, filterName, req: GridRequest) {
      if (obj.selected) {
        const filter = new FilterRequest();
        filter.filterName = filterName;
        filter.filterItemID = obj.id.toString();
        request.filters.push(filter);
      }
    }
  }

  onResetFilters(apply) {
    this.ngOnInit();
  }

  onRefresh(refresh) {
    this.onApplyFilters(true);
  }

  selectAll(col) {
    col.forEach(function (f) {
      f.selected = true;
    });
  }

  sort(sortAsc, columnName) {
    this.sortColumn = columnName;
    this.sortDirection = sortAsc ? 'ASC' : 'DESC';
    this.onApplyFilters(true);
  }
}
