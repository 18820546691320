import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { Observable } from 'rxjs';

@Injectable()
export class FilesService {

  appSettings: any;

  constructor(private _http: HttpClient,
    private _appSettings: AppsettingsProvider) {
    this.appSettings = this._appSettings.getSettings();
  }

  // No longer used as we link directly to S3 bucket in AWS
  getOperationGuide(guide: string): Observable<Blob> {
    return this._http.get(this.appSettings.urlLocations.filesURL + 'OperationGuide/' + guide, {responseType: 'blob'});
  }

}
