import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ISubscription } from 'rxjs/Subscription';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';
import { RouteLineItem } from '../../../smart-service/smart-service-service/smart-service.model';

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.scss']
})
export class RouteComponent implements OnInit, OnDestroy {
  @Input() public routeData;                         
  routes: any[];
  selectAll: boolean;
  routeSubscription: ISubscription;

  constructor(private _filter: FilterBarService) {}

  ngOnInit() {
    this.populateRoutes();
    this._filter.resetFilters.subscribe(data => {
      this.selectAll = true;
      this.updateAllBoxes();
    });
  }
  ngDoCheck()
  {     
    if(this.routeData == false)
    {
      this.selectAll= false;
      this.updateAllBoxes();   
    } 
  }
  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this._filter.routes.next(null);
  }

  populateRoutes() {
    if(this.routeData == true) {
      this.routeSubscription = this._filter.routes.subscribe((data: any[]) => {
        this.routes = data;
        if (!this._filter.dontPreCheckAllRoutes) {      
          // Sets all routes to be checked from the start
          this.selectAll = true;
          this.updateAllBoxes();
        } else {
          this.checkForSelectAll();
        }
      });
    }
  }

  updateAllBoxes() {
    const checked = this.selectAll;
    if (this.routes != null) {
      this.routes.forEach(function (value: RouteLineItem) {
      value.selected = checked;
      });
    }
  }

  checkForSelectAll() {
    this.selectAll = this.routes.every(this.areAllBoxesChecked) ? true : false;
  }  

  private areAllBoxesChecked(element: RouteLineItem, index, array) {
    return (element.selected === true);
  }
}
