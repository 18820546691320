import { throwError as observableThrowError, Observable, BehaviorSubject, of } from 'rxjs';
import { take, filter, catchError, switchMap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { SecurityService } from './security.service';
import { Router } from '@angular/router';
import { UnAuthorizationReason } from '../auth-claims.model';

@Injectable()
export class RefreshInterceptorService implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private injector: Injector,
        private _router: Router
    ) {
    }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        return next.handle(req).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
                            return this.handle401Error(req, next);
                        default:
                            return observableThrowError(error);
                    }
                } else {
                    return observableThrowError(error);
                }
            }));
    }

    handle400Error(error) {
        const _security = this.injector.get(SecurityService);
        if (error && error.status === 400 && error.error && error.error.message.toLowerCase() == 'invalid token') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            _security.removeLogonData();
        }

        return observableThrowError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const _security = this.injector.get(SecurityService);

            return _security.refreshToken().pipe(
                switchMap((data: any) => {
                    localStorage.setItem('access_token', data.jwtToken);

                    const unauthorizedReason = _security.getUnauthorizedReasonFromToken();
                    if (unauthorizedReason) {
                      _security.redirectToUnauthorized(unauthorizedReason);
                      return of(new HttpResponse({status: 200, body: 'Unauthorized'}));
                    }

                    localStorage.setItem('refresh_token', data.jwtRefreshToken);
                    localStorage.setItem('authClaims', JSON.stringify(data.oneFoundationData));

                    _security.updateOperationsOnTokenRefresh(data.oneFoundationData.dataObject.orgAccess);

                    this.isRefreshingToken = false;
                    return next.handle(this.addToken(req, data.jwtToken));
                }),
                catchError((error) => {
                    console.log(error);
                    this.isRefreshingToken = false;
                    _security.removeLogonData();
                    this._router.navigate(['home']);
                    return observableThrowError(error);
                })
            );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token));
                }));
        }
    }
}
