import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnAuthorizationReason } from '../core/auth-claims.model';
import { CommonService } from '../core/common-service/common.service';
import { SecurityService } from "../core/security/security.service";

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  issueMessage: string;
  isShowNote = false;

  constructor(
    private _common: CommonService,
    private _activatedRoute: ActivatedRoute,
    private _security: SecurityService,
    private _router: Router
  ) {
    this._common.setCurrentPage('Unauthorized');
  }

  ngOnInit() {
    if (!this._security.isLoggedIn()) {
      this._router.navigate(['home']);
      return;
    }

    this._activatedRoute.queryParams.subscribe(params => {
      const reason = params['reason'] || '';

      switch (reason.toLowerCase()) {
        case UnAuthorizationReason.FunctionPermission: {
          this.issueMessage = 'You are not authorized to view this page.';
          this.isShowNote = true;
          break;
        }
        case UnAuthorizationReason.ApplicationAccess: {
          this.issueMessage = 'You are not authorized to use this application.';
          this.isShowNote = false;
          break;
        }
        case UnAuthorizationReason.CompanyAccess:
        case UnAuthorizationReason.OperationAccess: {
          this.issueMessage = 'You have not been granted access to any operations. Please contact support.';
          this.isShowNote = false;
          break;
        }
        case UnAuthorizationReason.UserInactive: {
          this.issueMessage = 'Your account is no longer active. Please contact support.';
          this.isShowNote = false;
          break;
        }
        case UnAuthorizationReason.ServiceError:
        case UnAuthorizationReason.InvalidOmsUser: {
          this.issueMessage = 'Unexpected service error, please try to login again. If this issue persists, please contact support.';
          this.isShowNote = true;
          break;
        }
        default: {
          this.issueMessage = 'Unknown authorization issue.';
          this.isShowNote = true;
          break;
        }
      }
    });
  }

}
