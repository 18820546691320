import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Report_Param_Route_Response } from '../../reports-service/reports.model';
import { Switch } from '../../../shared/switch/switch.model';

@Component({
  selector: 'app-rpt-route',
  templateUrl: './rpt-route.component.html',
  styleUrls: ['./rpt-route.component.scss']
})
export class RptRouteComponent implements OnInit {

  @Input()
  routes: Report_Param_Route_Response[];

  @Output()
  routesChanged = new EventEmitter();

  selectAll = new Switch('Select All', false, true);
  errorMessage = '';

  constructor() { }

  ngOnInit() {
  }

  toggleSelectAllRoutes(event: Switch) {
    this.routes.forEach(x => x.selected.input = !event.input);
    this.noneSelected(this.routes);
    this.routesChanged.emit(this.routes);
  }

  routeOnClick(event: Switch, route: Report_Param_Route_Response) {
    const tempArray = this.routes.filter(x => x.routeID !== route.routeID);
    // Uses bool directly from event to avoid 2 way binding delay
    const eventBool = !event.input;
    this.selectAll.input = tempArray.every(x => x.selected.input === true) &&  eventBool === true;
    this.noneSelected(tempArray, eventBool);
    this.routesChanged.emit(this.routes);
  }

  // Pass extra bool to deal with 2 way binding delay
  noneSelected(inputArray, retrievedBool = null) {
    this.errorMessage = inputArray.every(x => x.selected.input === false) && (retrievedBool == null || retrievedBool === false)
    ? 'Route is Required' : '';
  }

}
